<template>
    <div>
        <zk-search-form-inline :form="form" @search="$emit('search', form)">
            <el-form-item :label="$t('logi.sn')">
                <el-input v-model="form.sn" clearable></el-input>
            </el-form-item>
            <el-form-item :label="$t('device.selectTime')">
                <el-date-picker
                        v-model="form.daterange"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="left">
                </el-date-picker>
            </el-form-item>
<!--            <el-form-item :label="$t('device.operationType')" prop="type">-->
<!--                <el-select v-model="form.type" clearable size="small">-->
<!--                    <el-option-->
<!--                            v-for="dict in operationTypeOptions"-->
<!--                            :key="dict.dictValue"-->
<!--                            :label="dict.dictLabel"-->
<!--                            :value="dict.dictValue"-->
<!--                    />-->
<!--                </el-select>-->
<!--            </el-form-item>-->
        </zk-search-form-inline>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
                operationTypeOptions: [],
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>
