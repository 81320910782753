import formatter from '@/components/formatter'

const data = {
    table: {
        columns: [
            {title: 'AP名称', field: 'device_name', width: '200'},
            {title: 'MAC 地址', field: 'mac', width: '200'},
            {title: 'AP在线', field: 'online', width: '100', formatter: formatter.onlineFormatter},
            {title: '最后通讯时间', field: 'last_seen', width: '200'},
            {title: '地址', field: 'address', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
