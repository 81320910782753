import formatter from '@/components/formatter'
const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: '200'},
            {title: '最后通讯时间', field: 'last_seen', width: '150'},
            {title: '信号', field: 'csq', width: '',formatter: formatter.defaultStr},
            {title: '电量', field: 'battery', width: '120',formatter: formatter.batteryFormatter},
            {title: '锁状态', field: 'state', width: '200',formatter: formatter.formatlockstate},
            {title: '碰撞', field: 'collision', width: '150',formatter: formatter.collisionFormatter},
            {title: '启停', field: 'start_stop', width: '150',formatter: formatter.startstopFormatter},
            {title: '横向倾斜', field: 'tilt_x_range', width: '150',formatter: formatter.tiltFormatter},
            {title: '纵向倾斜', field: 'tilt_y_range', width: '150',formatter: formatter.tiltFormatter},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
