const data = {
    formdata: [
        {

            "name": "name",
            "type": "input",
            "title": "角色名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "description",
            "type": "input",
            "title": "角色描述",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
    ],
    table: {
        columns: [
            {title: '角色名称', field: 'name', width: ''},
            // {title: '角色类型', field: 'roleType', width: ''},
            {title: '角色描述', field: 'description', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
