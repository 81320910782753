<template>
    <el-dialog :title="$t('transport.titleh')" :visible="visible" width="60%" @close="handleClose">
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.containerName')" prop="containerName">
                            <el-input
                                    autocomplete="off"
                                    v-model="form.containerName"
                                    :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.containerCode')" prop="containerCode">
                            <el-input
                                    autocomplete="off"
                                    v-model="form.containerCode"
                                    :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.length')" prop="length">
                            <div style="display: flex;border: 1px solid #DCDFE6" class="length">
                                <el-input
                                        @change="handleVolume"
                                        style="width: 25%"
                                        autocomplete="off"
                                        type="number"
                                        v-model="form.length"
                                        :placeholder="$t('transport.placeHolderLength')">
                                </el-input>
                                <div>
                                    <span>x</span>
                                </div>
                                <el-input
                                        style="width: 25%"
                                        type="number"
                                        autocomplete="off"
                                        @change="handleVolume"
                                        v-model="form.width"
                                        :placeholder="$t('transport.placeHolderWidth')">
                                </el-input>
                                <div>
                                    <span>x</span>
                                </div>
                                <el-input
                                        style="width: 50%"
                                        type="number"
                                        autocomplete="off"
                                        @change="handleVolume"
                                        v-model="form.height"
                                        :placeholder="$t('transport.placeHolderHeight')">
                                    <template slot="append">mm</template>
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.volume')" prop="volume">
                            <el-input
                                    autocomplete="off"
                                    type="number"
                                    v-model="form.volume"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template slot="append">cm<sup>3</sup></template>
                            </el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.innerLength')" >
                            <div style="display: flex;border: 1px solid #DCDFE6;" class="length">
                                <el-input
                                        autocomplete="off"
                                        type="number"
                                        style="width: 25%"
                                        @change="handleInneVolume"
                                        v-model="form.innerLength"
                                        :placeholder="$t('transport.placeHolderLength')">
                                </el-input>
                                <div>
                                    <span>x</span>
                                </div>
                                <el-input
                                        type="number"
                                        autocomplete="off"
                                        style="width: 25%"
                                        @change="handleInneVolume"
                                        v-model="form.innerWidth"
                                        :placeholder="$t('transport.placeHolderWidth')">
                                </el-input>
                                <div>
                                    <span>x</span>
                                </div>
                                <el-input
                                        type="number"
                                        autocomplete="off"
                                        style="width: 50%"
                                        @change="handleInneVolume"
                                        v-model="form.innerHeight"
                                        :placeholder="$t('transport.placeHolderHeight')">
                                    <template slot="append">mm</template>
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.innerVolume')" prop="innerVolume">
                            <el-input
                                    autocomplete="off"
                                    type="number"
                                    v-model="form.innerVolume"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template slot="append">cm<sup>3</sup></template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.netWeight')" prop="netWeight">
                            <el-input
                                    autocomplete="off"
                                    type="number"
                                    v-model="form.netWeight"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template slot="append">kg</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('transport.maxLoad')" prop="maxLoad">
                            <el-input
                                    autocomplete="off"
                                    type="number"
                                    v-model="form.maxLoad"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template slot="append">kg</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-row>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="handleClose">{{ $t('transport.cancel') }}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{ $t('transport.confirm') }}
                </el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import data from "../../data"
    export default {
        props: {
            form: {
                default: {}
            },
            visible:{
                default: false
            }
        },
        data() {
            return {
                loading: false,
                formData: data.formdata,
                rules: {
                    containerName: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    containerCode: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    length: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    width: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    height: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            handleVolume() {
                this.form.volume = (this.form.width * this.form.height * this.form.length) / 1000
            },
            handleInneVolume() {
                this.form.innerVolume = (this.form.innerWidth * this.form.innerHeight * this.form.innerLength) / 1000
            },
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                try {
                    var temp = JSON.stringify(this.form)
                    var form = JSON.parse(temp)
                    if (form.volume)
                        form.volume = form.volume * 1000
                    if (form.innerVolume)
                        form.innerVolume = form.innerVolume * 1000
                    const resp = await this.$http.post(`/transport/container/`, form)
                    if (resp.data.status != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success(this.$t('transport.success'))
                        this.$emit('update')
                        this.$emit('close')
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>
