const alarm_type_formatter = function (row, column) {
    let obj = row[column.property]
    if (obj == 11) return '低温报警'
    if ([4, 12].includes(obj)) return '高温报警'
    return '温度报警'
}
const data = {
    table: {
        columns: [
            {title: '报警类型', field: 'alarm_type', width: '', formatter: alarm_type_formatter},
            {title: '异常数据', field: 'data', width: ''},
            {title: '报警时间', field: 'create_time', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
