// 一次引入终身受用
/**
 * require.context()参数的意义
 * arg1.在哪里找
 * arg2.是否要找子集
 * arg3.以什么为规则来找
 */
const requireComponent = require.context('./', true, /\.vue$/)
const install = (Vue) => {
    // 如果组件被注册就返回,没有就注册
    if (install.installed) return
    install.installed = true
    requireComponent.keys().forEach(filename => {
        // filename 文件
        // 第i个组件
        const config = requireComponent(filename)
        // 组件名
        if (config.default.name) {
            const componentName = config.default.name
            // console.log(componentName, '================= 组件名') // 输出依次是Test LineChart PieChart
            // 循环注册组件
            Vue.component(componentName, config.default || config)
        }
    })
}

// 确保是正常环境
// 在做插件的情况下进行判断
// if (typeof window !== 'undefined' && window.Vue) {
//   install(window.Vue)
// }

export default {
    install
}
