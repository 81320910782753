<template>
    <el-dialog title="下载数据" :visible="visible" width="50%" @close="close">
        <el-form label-width="100px">
            <el-row class="search">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="AP SN" >
                            <span>{{form.sn_m}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('temperature.snS')" v-if="$store.getters.showSn!='0'">
                            <span>{{form.sn}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('temperature.xnum')">
                            <span>{{form.box_number}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="温度计别名" v-if="$store.getters.showSnPrefix!='0'">
                            <span>{{form.device_code}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('temperature.lastSeen')">
                            <span>{{form.daterange}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-row>
        </el-form>
        <div class="page-footer" style="text-align: center !important;">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[1000,2000,3000]"
                           :current-page="page" :total="total" :page-size="pageSize" background
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"
            />
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">下 载
                </el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            total: {
                default: 0
            },
        },
        data() {
            return {
                loading: false,
                page: 1,
                pageSize: 1000
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.page = e
            },
            handleSizeChange(e) {
                this.pageSize = e
                this.page = 1
            },
            async formSubmit() {
                this.$emit("close")
                this.$http.get(`devices/export/beacon/record/?page=${this.page}&pageSize=${this.pageSize}`, {
                    responseType: 'arraybuffer',
                    params: this.form
                }).then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data],{type:"application/vnd.ms-excel"}))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err)
                })
            },
            close() {
                this.$emit("close")
            },
        },
    };
</script>
<style scoped>
    /deep/ .el-dialog__body {
        padding: 0px;
    }

    /deep/ .el-dialog__header {
        border-bottom: 1px solid #C0C4CC;
    }

    /deep/ .el-form {
        margin-bottom: 20px;
        border-bottom: 1px solid #C0C4CC;
    }

    .search {
        margin: 0 20px;
    }

    /deep/ .el-form-item {
        margin-bottom: 0px;
    }
</style>
