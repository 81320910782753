<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item :label="$t('logi.sn')">
            <el-input v-model="form.sn" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('logi.lastSeen')">
            <el-date-picker v-model="form.daterange"
                            style="float: right; padding: 3px 10px"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            :range-separator="$t('logi.to')"
                            :start-placeholder="$t('logi.startDate')"
                            :end-placeholder="$t('logi.startDate')">
            </el-date-picker>
        </el-form-item>
        <!--            <el-form-item :label="$t('logi.collision')">-->
        <!--                <el-input v-model="form.collision"></el-input>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item :label="$t('logi.start')">-->
        <!--                <el-input v-model="form.start"></el-input>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item :label="$t('logi.tilt_x')">-->
        <!--                <el-input v-model="form.tilt_x"></el-input>-->
        <!--            </el-form-item>-->
        <!--            <el-form-item :label="$t('logi.tilt_y')">-->
        <!--                <el-input v-model="form.tilt_y"></el-input>-->
        <!--            </el-form-item>-->
    </zk-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>
