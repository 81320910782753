import formatter from '@/components/formatter'

const data = {
    table: {
        columns: [
            {title: 'ID', field: 'id', width: '60'},
            {title: '设备号', field: 'sn', width: ''},
            {title: '报警类型', field: 'alarm_type', width: '', formatter: formatter.formatAlarmType},
            {title: '异常数据', field: 'data', width: ''},
            {title: '报警时间', field: 'create_time', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
