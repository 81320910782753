<template>
    <div>
        <el-row>
            <el-row>
                <el-col :span="24">
                    <div id="dbmap" class="allmap"></div>
                    <el-row class="dataform" v-if="model1">
                        <div style=" padding: 10px;background-color: aliceblue;">
                            <span>{{$t('transport.electricFence')}}</span>
                        </div>
                        <el-form ref="dataform" :model="dataform" label-width="100px"
                                 style="padding-right: 10px;padding-top: 10px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.area')">
                                        <el-input v-model="dataform.area"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.name')">
                                        <el-input v-model="dataform.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.areaNumber')">
                                        <el-input v-model="dataform.areanumber"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.type')">
                                        <el-input v-model="dataform.type"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="12">
                                        <el-form-item :label="$t('transport.consignType')">
                                            <el-input v-model="dataform.consignType"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item :label="$t('transport.departmentId')">
                                            <treeselect
                                                    v-model="dataform.departmentId"
                                                    :options="depList"
                                                    style="width: 100%;"
                                                    :placeholder="$t('transport.depPlaceHolder')"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.shareMode')">
                                        <el-input v-model="dataform.shareMode"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="circle">
                                    <el-form-item :label="$t('transport.radius')">
                                        <el-input v-model="dataform.radius"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.longitude')">
                                        <el-input v-model="dataform.longitude"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.latitude')">
                                        <el-input v-model="dataform.latitude"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.address')">
                                        <el-input v-model="dataform.address"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.province')">
                                        <el-input v-model="dataform.province"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.cityId')">
                                        <el-input v-model="dataform.city"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="$t('transport.districtId')">
                                        <el-input v-model="dataform.district"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item :label="$t('transport.comment')">
                                        <el-input v-model="dataform.comment" type="textarea"
                                                  :rows="4"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item style=" padding: 10px;background-color: aliceblue;">
                                <el-button type="primary" @click="clear">{{$t('transport.redraw')}}</el-button>
                                <el-button type="primary" @click="formSubmit">{{$t('transport.confirm')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </el-row>
                    <!--                    </draggable>-->
                </el-col>
            </el-row>
            <el-row style="border-bottom: 1px solid #dcdfe6;">
                <div>
                    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd">
                        {{$t('transport.mapLabel')}}
                    </el-button>
                </div>
            </el-row>
            <el-row style="border-bottom: 1px solid #dcdfe6;" class="search">
                <div>
                    <el-input v-model="address" :placeholder="$t('transport.searchAddress')">
                        <el-button slot="append" icon="el-icon-search" @click="codeAddress"></el-button>
                    </el-input>
                </div>
            </el-row>
        </el-row>
        <el-dialog
                :visible.sync="model"
                width="30%"
                top="20%"
                :modal="false"
        >
            <div style="display: flex;align-items: center;justify-content: center">
                <div @click="draw('polygon')" style="padding-right: 40px;text-align: center">
                    <img src="@/assets/ju.png">
                    <div>
                        {{$t('transport.polygon')}}
                    </div>
                </div>
                <div @click="draw('rectangle')" style="padding-right: 40px;text-align: center">
                    <img src="@/assets/fang.png">
                    <div>
                        {{$t('transport.square')}}
                    </div>
                </div>
                <div @click="draw('circle')" style="text-align: center">
                    <img src="@/assets/circle.jpg">
                    <div>
                        {{$t('transport.circle')}}
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {loadBaiDuDrawMap} from "@/components/bmpgl_lib.js";

    export default {
        name: 'electric-fence-form',
        path: ['/main/transport/electric-fence/form', '/main/transport/electric-fence/form/:pk'],
        components: {
            Treeselect
        },
        data() {
            return {
                circle: false,
                address: '',
                dataform: {
                    district:'',
                    city:"",
                    province:''
                },
                map: null,
                actNav: "",
                model: false,
                model1: false,
                depList: [],
                dialogVisible: false,
                drawingManager: null,
                rules: {
                    customerName: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    customerCode: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            handleAdd() {
                this.clear()
                this.model = true
            },
            initMap() {
                loadBaiDuDrawMap().then((BMapGL) => {

                    this.map = new BMapGL.Map("dbmap", {
                        enableMapClick: false // 禁止底图点击事件
                    });
                    this.map.centerAndZoom(new BMapGL.Point(117.06, 36.67), 17);
                    this.map.enableScrollWheelZoom();
                    this.map.enableMapClick = false;
                })
            },
            codeAddress() {
                var that = this
                // eslint-disable-next-line no-undef
                var myGeo = new BMapGL.Geocoder();
                myGeo.getPoint(that.address, function (point) {
                    if (point) {
                        that.map.centerAndZoom(point, 14);
                        // eslint-disable-next-line no-undef
                        that.map.addOverlay(new BMapGL.Marker(point));
                    }
                }, "");
            },
            draw(type) {
                var that = this
                that.model = false
                if (type == "circle") {
                    that.circle = true
                } else {
                    that.circle = false
                }
                that.actNav = type
                const styleOptions = {
                    strokeColor: "#5E87DB", // 边线颜色
                    fillColor: "#5E87DB", // 填充颜色。当参数为空时，圆形没有填充颜色
                    strokeWeight: 2, // 边线宽度，以像素为单位
                    strokeOpacity: 2, // 边线透明度，取值范围0-1
                    fillOpacity: 0.2, // 填充透明度，取值范围0-1
                };
                const labelOptions = {
                    borderRadius: "2px",
                    background: "#FFFBCC",
                    border: "1px solid #E1E1E1",
                    color: "#703A04",
                    fontSize: "12px",
                    letterSpacing: "0",
                    padding: "5px",
                };
                // 实例化鼠标绘制工具
                // eslint-disable-next-line no-undef
                const drawingManager = new BMapGLLib.DrawingManager(that.map, {
                    isOpen: true,        // 是否开启绘制模式
                    enableCalculate: true, // 绘制是否进行测距测面
                    enableSorption: true, // 是否开启边界吸附功能
                    sorptiondistance: 20, // 边界吸附距离
                    rectangleOptions: styleOptions, // 矩形的样式
                    labelOptions: labelOptions,
                    circleOptions: styleOptions, //圆的样式
                    polylineOptions: styleOptions, //线的样式
                    polygonOptions: styleOptions, //多边形的样式
                });
                if (drawingManager.isOpen_ && drawingManager.getDrawingMode() === type) {
                    drawingManager.close();
                } else {
                    drawingManager.setDrawingMode(type);
                    drawingManager.open();
                }
                drawingManager.addEventListener('overlaycomplete', function (e) {
                    that.model1 = true
                    var options = e.overlay.points
                    var overlay = []
                    options.forEach(function (item) {
                        if (item.latLng) {
                            overlay.push([item.latLng.lng, item.latLng.lat])
                        }
                    })
                    that.getAreaCenter(overlay)
                    if (type == "circle") {
                        that.dataform.radius = e.overlay.getRadius();
                    }
                    that.dataform.area = e.calculate

                });
            },
            getAreaCenter(location) {
                var that = this
                var total = location.length;
                var X = 0,
                    Y = 0,
                    Z = 0;
                location.forEach(lnglat => {
                    var lng = (lnglat [0] * Math.PI) / 180;
                    var lat = (lnglat [1] * Math.PI) / 180;
                    var x, y, z;
                    x = Math.cos(lat) * Math.cos(lng);
                    y = Math.cos(lat) * Math.sin(lng);
                    z = Math.sin(lat);
                    X += x;
                    Y += y;
                    Z += z;
                });
                X = X / total;
                Y = Y / total;
                Z = Z / total;

                var Lng = Math.atan2(Y, X);
                var Hyp = Math.sqrt(X * X + Y * Y);
                var Lat = Math.atan2(Z, Hyp);
                // eslint-disable-next-line no-undef
                var marker = new BMapGL.Marker(new BMapGL.Point(parseFloat((Lng * 180) / Math.PI), parseFloat((Lat * 180) / Math.PI)));
                this.map.addOverlay(marker);
                this.dataform.longitude = ((Lng * 180) / Math.PI).toString()
                this.dataform.latitude = ((Lat * 180) / Math.PI).toString()
                // eslint-disable-next-line no-undef
                const gc = new BMapGL.Geocoder()
                // eslint-disable-next-line no-undef
                gc.getLocation(new BMapGL.Point(parseFloat(that.dataform.longitude), parseFloat(that.dataform.latitude)), function (rs) {
                    var addComp = rs.addressComponents
                    console.log(addComp)
                    // this.addr = addComp.province + ', ' + addComp.city + ', ' + addComp.district + ', ' + addComp.street + ', ' + addComp.streetNumber
                    that.dataform.province = addComp.province
                    that.dataform.city = addComp.city
                    that.dataform.district = addComp.district
                    that.dataform.address = rs.address
                })
            },
            clear() {
                this.map.clearOverlays();
            },
            async formSubmit() {
                var result = 0
                this.$refs['dataform'].validate((valid) => {
                    if (valid) {
                        result = 1
                    }
                })
                if (result) {
                    try {
                        // this.dataform.department = {"id": this.dataform.department }
                        const resp = await this.$http.post(`/transport/electricFence/`, this.dataform)
                        if (!resp.data) {
                            this.$message.error(resp.data.msg)
                            // this.dataform.department = this.dataform.department.id
                        } else {
                            this.$message.success(this.$t('transport.success'))
                            this.$router.push({
                                path: `/main/transport/electric-fence`,
                            })
                        }
                    } catch (e) {
                        // this.dataform.department = this.dataform.department.id
                        this.$message.error(e.message)
                    }

                }
            },
            async getData() {
                try {
                    let pk = this.$route.params.pk
                    if (pk) {
                        const resp = await this.$http.get(`/transport/electricFence/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.dataform = resp.data.data
                            this.model1 = true
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            treeChange(arr) {
                return arr.map(item => {
                    if (item.children && item.children.length > 0) {
                        this.treeChange(item.children)
                    } else {
                        delete item.children
                    }
                    return item
                })
            },
            async getDep() {
                try {
                    const resp = await this.$http.get(`/company/department/tree/`)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        const data = this.walk(resp.data.data.children, (data) => ({
                            ...data,
                            label: data.node.departmentName,
                            value: data.node.id,
                        }), 0);
                        this.depList = this.treeChange(data)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            walk(list, callback, deep = 0) {
                return list.map(it => {
                    const result = callback({...it}, deep);
                    if (it.children) {
                        result.children = this.walk(it.children, callback, deep + 1);
                    }
                    return result;
                });
            },
        },
        mounted() {
            this.getData();
            this.getDep()
            this.initMap();
        },
    }
</script>

<style lang="scss">
    /*.el-main{*/
    /*    padding: 0px !important;*/
    /*}*/
    // 隐藏版权信息
    .BMap_cpyCtrl {
        display: none;
    }

    // 隐藏版权图标
    .anchorBL img {
        display: none;
    }
    .el-main {
        padding: 10px;
    }
</style>
<style scoped>

    .search {
        border-bottom: 1px solid rgb(220, 223, 230);
        position: fixed;
        top: 75px;
        right: 180px;
        z-index: 1;
    }

    .dataform {
        background-color: white;
        position: fixed;
        z-index: 999;
        top: 130px;
        right: 40px;
        width: 500px;
        /*height: 500px;*/
        border-radius: 5px;
        border: 1px solid #d9dce3;
    }

    .allmap {
        width: 100%;
        height: 87vh;
        position: relative;
        z-index: 1;
    }

    /deep/ .el-card__body {
        padding: 0px;
        /*padding-right: 0px;*/
    }

    .change-map-type {
        position: absolute;
        right: 50px;
        bottom: 10px;
        z-index: 2;
    }

    ul li {
        list-style: none;
    }

    .el-button--primary.is-plain {
        color: black;
        background: #fff;
        position: fixed;
        border-color: #fff;
        top: 75px;
        right: 40px;
        z-index: 1;
        box-shadow: 0 2px 6px 0 rgb(9 9 10 50%);
    }

    .info {
        z-index: 999;
        width: auto;
        min-width: 22rem;
        padding: .75rem 1.25rem;
        margin-left: 1.25rem;
        position: fixed;
        top: 1rem;
        background-color: #fff;
        border-radius: .25rem;
        font-size: 14px;
        color: #666;
        box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
    }

    .drawing-panel {
        z-index: 999;
        /*position: fixed;*/
        /*bottom: 3.5rem;*/
        margin-left: 2.5rem;
        padding-left: 0;
        /*border-radius: 0.25rem;*/
        height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*top: 125px;*/
        /*box-shadow: 0 2px 6px 0 rgb(27 142 236 / 50%);*/
        /*right: 20px;*/
    }

    .bmap-btn {
        border-right: 1px solid #d2d2d2;
        float: left;
        width: 64px;
        height: 100%;
        background-image: url(http://api.map.baidu.com/library/DrawingManager/1.4/src/bg_drawing_tool.png);
        cursor: pointer;
    }

    .drawing-panel .bmap-marker {
        background-position: -65px 0;
    }

    .drawing-panel .bmap-polyline {
        background-position: -195px 0;
    }

    .drawing-panel .bmap-rectangle {
        background-position: -325px 0;
    }

    .drawing-panel .bmap-polygon {
        background-position: -260px 0;
    }

    .drawing-panel .bmap-circle {
        background-position: -130px 0;
    }

</style>

