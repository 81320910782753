<template>
    <div>
        <el-form ref="form" :model="form" :label-width="labelwidth" label-position="right">
            <el-form ref="form" :model="form"  label-width="80px">
                <el-divider content-position="left">绑定司机照片</el-divider>
                <el-form-item label="照片">
                    <zk-upload-image v-model="form.img" ></zk-upload-image>
                </el-form-item>
                <el-form-item label="开锁密码">
                    <el-input v-model.number="form.unLockPassword" :disabled="true" style="width: 200px;font-weight: bolder">
                        <el-button slot="append" icon="el-icon-refresh" ></el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="close">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import data from '../../data'
    export default {
        props: {
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {
                default: {}
            },
        },
        data() {
            return {
                formData: data.formdata,
                loading: false,
            }
        },
        methods: {
            formSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$emit('formSubmit', this.form)
                    }
                });
            },
            close(){
                this.$emit("close")
            },
        },
    };
</script>

<style scoped>

</style>
