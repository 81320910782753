import formatter from '@/components/formatter'

const data = {
    table: {
        columns: [
            // {title: 'AP', field: 'sn_m', width: ''},
            {title: '设备号', field: 'sn', width: '', show: localStorage.getItem("showSn")},
            {title: '别名', field: 'device_code', width: '', show: localStorage.getItem("showSnPrefix")},
            {title: '设备名称', field: 'device_name', width: ''},
            {title: '品类', field: 'category', width: '', formatter:formatter.defaultStr},
            {title: '最后采集时间', field: 'last_seen', width: '180'},
            {title: '电量', field: 'battery', width: '', formatter: formatter.batteryFormatter},
            {title: '温度', field: 'temperature', width: '', formatter: formatter.temperatureFormatter},
            // {title: '温度记录间隔', field: 'report_interval', width: ''},
            // {title: '最低温度', field: 'temperature_min', width: ''},
            // {title: '最高温度', field: 'temperature_max', width: ''},
            // {title: '温度校准', field: 'temperature_adjust', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
