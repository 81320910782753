import formatter from '@/components/formatter'

const data = {
    table: {
        columns: [
            {title: 'AP', field: 'sn_m', width: ''},
            {title: '温度计SN', field: 'sn', width: '', show: localStorage.getItem("showSn")},
            {title: '温度计别名', field: 'device_code', width: '', show: localStorage.getItem("showSnPrefix")},
            {title: '采集时间', field: 'last_seen', width: ''},
            {title: '电量', field: 'battery', width: '', formatter: formatter.batteryFormatter},
            {title: '温度', field: 'temperature', width: '', formatter: formatter.temperatureFormatter},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
