<template>
    <zk-admin-table title="运单任务" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
            <template slot-scope="scope">
                <div v-if="v.field=='start_img'||v.field=='end_img'">
                    <el-image :src="scope.row[v.field]" v-if="scope.row[v.field]"
                              style="width: 100px;height: 100px"></el-image>
                </div>
                <div v-else>
                    {{scope.row[v.field]}}
                </div>
            </template>
        </el-table-column>
    </zk-admin-table>
</template>
<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'

    export default {
        name: 'cvsst-task',
        path: '/main/devices/cvsst/task',
        components: {
            SearchForm
        },
        data() {
            return {
                loading: false,
                table: data.table,
                searchVisible: true
            }
        },
        methods: {
            handleSearch(e) {
                console.log(e, "====")
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/devices/smartLockST/task?page=${this.table.page}&size=${this.table.pageSize}`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data.data.rows
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>

