const data = {
    formdata: [
        {

            "name": "name",
            "type": "input",
            "title": "车型名称",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "code",
            "type": "input",
            span:12,
            "title": "车型代码",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "maxLoad",
            "type": "number",
            "title": "额定载重",
            slots:"吨",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "maxVolumn",
            "type": "number",
            "title": "额定载积",
            slots:"m",
            sup:3,
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "height",
            "type": "number",
            "title": "外高度",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "defaultAvailableQuantity",
            "type": "number",
            "title": "默认可用数量",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "minVolumn",
            "type": "number",
            "title": "最小载积",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "comment",
            "type": "textarea",
            "title": "备注",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '车辆类型名称', field: 'name', width: ''},
            {title: '车辆类型代码', field: 'code', width: ''},
            {title: '默认可用数量', field: 'defaultAvailableQuantity', width: '200'},
            {title: '额定质量', field: 'maxLoad', width: ''},
            {title: '最小载质量', field: 'minLoad', width: ''},
            {title: '额定载积', field: 'maxVolume', width: ''},
            {title: '最小载积', field: 'minVolum', width: ''},
            {title: '发证机关', field: 'issueAuthority', width: ''},
            {title: '允许的货物类型', field: 'typeGoodsAllowed', width: '200'},
            {title: '运费费率', field: 'freightRate', width: ''},
            {title: '拼点费率', field: 'spellpointRate', width: ''},
            {title: '备注', field: 'comment', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
