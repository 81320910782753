const alarm_type_formatter = function (row, column) {
    const obj = row[column.property]
    if (obj === 12) return '高温警报'
    if (obj === 11) return '低温警报'
    return '温度警报'
}

const data = {
    table: {
        columns: [
            // {title: 'ID', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: '', show: localStorage.getItem("showSn")},
            {title: '别名', field: 'device_code', width: '', show: localStorage.getItem("showSnPrefix")},
            {title: '报警类型', field: 'alarm_type', width: '', formatter: alarm_type_formatter},
            {title: '异常数据', field: 'data', width: ''},
            {title: '报警时间', field: 'create_time', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
