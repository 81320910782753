import formatter from '@/components/formatter'
const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: '200'},
            {title: '电量', field: 'battery', width: '120',formatter: formatter.batteryFormatter},
            {title: '操作时间', field: 'create_time', width: '150'},
            {title: '操作用户', field: 'user_name', width: '150'},
            {title: '锁状态', field: 'lock_state', width: '100',formatter: formatter.formaLock},
            {title: '操作类型', field: 'type', width: '150',formatter: formatter.formatType},
            {title: '数据来源', field: 'log', width: '150',formatter: formatter.formatSources},
            {title: '随机码', field: 'ran', width: '100'},
            {title: 'NFC卡号', field: 'nfc_no', width: '100'},
            {title: '锁状态详情', field: 'lock_state_lnfo', width: '',formatter: formatter.formatlockstate},
            {title: '是否连接充电器', field: 'charger', width: '',formatter: formatter.formatcharger},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
