<template>
    <div>
        <zk-search-form-inline :form="form" @search="$emit('search', form)">
            <el-form-item :label="$t('logi.sn')">
                <el-input v-model="form.sn"></el-input>
            </el-form-item>
        </zk-search-form-inline>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>
