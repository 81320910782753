<template>
    <div class="page">
        <div class="page-header hidden-sm-and-down">
            <div style="float:right">
                <slot name="buttons"></slot>
            </div>
            <el-page-header @back="$router.go(-1)" :content="title"/>
        </div>
        <div class="page-search">
            <slot name="search"></slot>
        </div>
        <div class="page-main">
            <el-table :data="table.data" style="width: 100%;" height="100%" v-loading="loading" @selection-change="handleSelectionChange">
                <slot></slot>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
        <slot name="dialog"></slot>
        <slot name="download"></slot>
        <slot name="drawer"></slot>
    </div>
</template>


<script>
    export default {
        name: 'zk-admin-table',
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: '页面标题'
            },
            table: {
                default: {
                    search: [],
                    columns: [],
                    data: [],
                    total: 0,
                },
            },
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.$emit('change', this.table)
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.table.page = 1
                this.$emit('change', this.table)
            },
            handleSelectionChange(val) {
                this.$emit('select', val)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;

        .page-header {
            padding-bottom: 10px;
        }

        .page-search {
            background-color: #f7f7f8;
            border: 1px solid #EBEEF5;
            border-bottom: 0px;
            text-align: center;

            .el-form-item {
                margin: 0 5px;
            }

        }

        .page-main {
            flex: 1;
            min-height: 100px;
        }

        .page-footer {
            text-align: center;
            padding-top: 20px;
        }
    }
</style>
