const data = {
    formdata: [
        {

            "name": "status",
            "type": "input",
            "title": "状态",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "ReportLongPerson",
            "type": "input",
            "title": "上报人",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "ReportLongTime",
            "type": "number",
            "title": "上报时间",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "source",
            "type": "number",
            "title": "来源",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "fenceName",
            "type": "number",
            "title": "围栏名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "fenceNumber",
            "type": "number",
            "title": "围栏编号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "historicalLocation",
            "type": "number",
            "title": "历史位置",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "historicalLongitude",
            "type": "number",
            "title": "历史经度",
            required:false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "historicalLatitude",
            "type": "number",
            "title": "历史纬度",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '状态', field: 'status', width: ''},
            {title: '上报人', field: 'ReportLongPerson', width: '150'},
            {title: '上报时间', field: 'ReportLongTime', width: ''},
            {title: '来源', field: 'source', width: ''},
            {title: '围栏名称', field: 'fenceName', width: ''},
            {title: '围栏编号', field: 'fenceNumber', width: ''},
            {title: '历史位置', field: 'historicalLocation', width: ''},
            {title: '历史经度', field: 'historicalLongitude', width: ''},
            {title: '历史维度', field: 'historicalLatitude', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
