import formatter from '@/components/formatter'

const data = {
    formdata: [
        {
            "name": "device_name",
            "type": "input",
            "title": "设备名称",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "version",
            "type": "input",
            "title": "软件版本",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: true
        },
        {
            "name": "driver",
            "type": "input",
            "title": "司机名称",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "carNo",
            "type": "input",
            "title": "车牌号",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "alarmType",
            "type": "select-remote-multiple",
            "title": "报警类型",
            required: false,
            placeholder: "请输入",
            value: "",
            key: "label",
            disabled: false,
            options: [
                {
                    value: '1',
                    label: '进入围栏'
                },
                {
                    value: '2',
                    label: '超出围栏'
                }
            ]
        },
        {
            "name": "emailAddress",
            "type": "input",
            "title": "邮件地址",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
    ],
    table: {
        columns: [
            {title: '设备ID', field: 'device_id', width: ''},
            {title: 'Sn', field: 'sn', width: '200'},
            {title: '最后通讯时间', field: 'last_seen', width: '150'},
            {title: '电量', field: 'battery', width: '120'},
            {title: '定位方式', field: 'local_way', width: '100'},
            {title: '锁状态', field: 'lock_state', formatter: formatter.formatLockState, width: '100'},
            {title: 'ICCID', field: 'iccid', width: '150'},
            {title: '开始照片', field: 'start_img', width: ''},
            {title: '结束照片', field: 'end_img', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
