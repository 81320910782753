import formatter from '@/components/formatter'
const batteryFormatter = (row, column) => {
    const battery = row[column.property]
    try{
        if(parseInt(battery) == 0){
            return battery + '%'
        }
    } catch (e) {
        console.log(e)
    }
    if(battery == null) return "-"
    if(!battery) return "-"
    return battery + '%'
}

const data = {
    table: {
        columns: [
            {title: 'AP SN', field: 'ap_sn', width: ''},
            {title: '保温箱号', field: 'box_number', width: ''},
            {title: '最后采集时间', field: 'last_seen', width: ''},
            {title: '电量', field: 'battery', width: '', formatter: batteryFormatter},
            {title: '温度', field: 'temperature', width: '', formatter: formatter.temperatureFormatter},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data

