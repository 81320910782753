const data = {
    formdata: [
        {

            "name": "name",
            "type": "input",
            "title": "名称",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "phone",
            "type": "input",
            "title": "手机号",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "address",
            "type": "input",
            "title": "居住地址",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "vehicleId",
            "type": "input",
            "title": "绑定车辆",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }

    ],
    table: {
        columns: [
            {title: '姓名', field: 'name', width: ''},
            {title: '手机号', field: 'phone', width: ''},
            {title: '居住地址', field: 'address', width: ''},
            {title: '绑定车辆', field: 'vehicleId', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
