const data = {
    table: {
        columns: [
            {title: 'Number', field: 'id', width: ''},
            {title: 'device_id', field: 'sn', width: '200'},
            {title: 'signal', field: 'csq', width: '150'},
            {title: 'battery', field: 'battery', width: '120'},
            {title: 'format_time', field: 'format_time', width: '150'},
            {title: 'lockState', field: 'lock_state', width: '100'},
            {title: 'type', field: 'type', width: '150'},
            {title: 'data_source', field: 'log', width: '150'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
