<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="AP SN" v-if="$store.getters.showSn!='0'">
            <el-input v-model.trim="form.sn" clearable></el-input>
        </el-form-item>
        <el-form-item label="AP别名" v-if="$store.getters.showSnPrefix!='0'">
            <el-input v-model.trim="form.device_code" clearable></el-input>
        </el-form-item>
        <el-form-item label="AP名称">
            <el-input v-model.trim="form.device_name" clearable></el-input>
        </el-form-item>
        <el-form-item label="MAC地址">
            <el-input v-model.trim="form.mac" clearable></el-input>
        </el-form-item>
        <el-form-item label="在线状态">
            <el-select v-model="form.online"  clearable placeholder="请选择">
                <el-option label="在线" :value="1">
                </el-option>
                <el-option label="离线" :value="0">
                </el-option>
                <el-option label="未激活" :value="3">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('temperature.address')">
            <el-input v-model.trim="form.address" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('temperature.lastSeen')">
            <el-date-picker v-model="form.daterange"
                            size="small"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :range-separator="$t('temperature.to')"
                            :start-placeholder="$t('temperature.startDate')"
                            :end-placeholder="$t('temperature.startDate')">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {}
            }
        }
    }
</script>

