<template>
    <zk-admin-table title="LogiPlus2.0" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <!--                <el-button size="mini" icon="el-icon-upload2" circle></el-button>-->
                <!--                <el-button size="mini" icon="el-icon-download" circle></el-button>-->
                <!--                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>-->
                <!--                <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button>-->
                <!--                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button>-->
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" :label="$t('logi.operation')" fixed="right">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    {{$t('logi.modification')}}
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false"
                       @update="getData"></data-form>
        </template>
    </zk-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'
    import data from './data'

    export default {
        name: 'device-logiPlus',
        path: '/main/devices/logiPlus/logiPlus',
        components: {
            SearchForm,
            DataForm,
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                search: {}
            }
        },
        methods: {
            handleSearch(e) {
                this.search = e
                this.getData()
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            async handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/devices/logiPlus/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.status === 200) {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    } else {
                        this.$message.error(resp.data.message)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>
