const data = {
    formdata: [
        {

            "name": "name",
            "type": "input",
            "title": "片区名称",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "code",
            "type": "number",
            "title": "片区编码",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "comment",
            "type": "textarea",
            "title": "备注",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '片区名称', field: 'name', width: ''},
            {title: '片区编码', field: 'code', width: ''},
            {title: '备注', field: 'comment', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
