<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item :label="$t('temperature.sn')">
            <el-input v-model="form.sn" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.selectTime')">
            <el-date-picker v-model="form.daterange"
                            style="float: right; padding: 3px 10px"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            :range-separator="$t('temperature.to')"
                            :start-placeholder="$t('temperature.startDate')"
                            :end-placeholder="$t('temperature.startDate')">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

