import Vue from 'vue'
// import VueI18n from 'vue-i18n'
import App from './common/App.vue'
import './common'
import router from './router/index'
import store from './common/example/store'
import http from "./common/plugins/http"
import i18n from './i18n'
import BaiduMap from 'vue-baidu-map'
import echarts from 'echarts'
import ECharts from 'vue-echarts'

Vue.component('v-chart', ECharts)
Vue.prototype.$echarts = echarts

Vue.use(BaiduMap, {
    ak: 'OgxUIqg08BGUI8gzKGcT2GgsPiUT3PXu'  //  在此输入你自己的百度地图ak
})

Vue.config.productionTip = false
Vue.prototype.$http = http

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount('#app')
