import formatter from '@/components/formatter'
const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: '200'},
            {title: '最后通讯时间', field: 'last_seen', width: '150'},
            {title: '电量', field: 'battery', width: '',formatter: formatter.batteryFormatter},
            {title: '信号', field: 'signal', width: '',formatter: formatter.defaultStr},
            {title: '定位方式', field: 'locate_way', width: '100',formatter: formatter.locateWayFormatter},
            {title: '常开模式', field: 'hold_open', width: '100',formatter: formatter.holdOpenFormatter},
            {title: '锁状态', field: 'lock_state', width: '100',formatter: formatter.formatlockstate},
            {title: '碰撞', field: 'collision', width: '150',formatter: formatter.collisionFormatter},
            {title: '启停', field: 'start_stop', width: '150',formatter: formatter.startstopFormatter},
            {title: '横向倾斜', field: 'tilt_x_range', width: '150',formatter: formatter.tiltFormatter},
            {title: '纵向倾斜', field: 'tilt_y_range', width: '150',formatter: formatter.tiltFormatter},
            {title: '运输状态', field: 'work_status', width: '150',formatter: formatter.workstatusFormatter},
            {title: 'ICCID', field: 'iccid', width: '150'},
            // {title: 'imei', field: 'deviceCode', width: '150'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
