<template>
    <el-dialog :title="$t('logi.modelLabel')" :visible="visible" width="60%" @close="handleClose">
        <el-tabs v-model="activeName" style="max-height: 50vh;overflow: auto;">
            <el-tab-pane label="设备信息" name="tab1">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="设备号" prop="sn"
                                  :rules="{ required: true, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.sn" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="设备名称" prop="device_name">
                        <el-input v-model="form.device_name" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="品类" prop="category">
                        <el-select v-model="form.category" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="参数配置" name="tab2">
                <el-form ref="form" :model="form" label-width="150px">
                    <el-form-item label="温度计记录间隔" prop="report_interval" :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.report_interval" :disabled="!isNew" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="最低温度" prop="temperature_min" :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.temperature_min" :disabled="!isNew" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="最高温度" prop="temperature_max" :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.temperature_max" :disabled="!isNew" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="温度校准" prop="temperature_adjust" :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.temperature_adjust" :disabled="!isNew" type="number"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            isNew: {
                default: true
            },
            form: {
                default: {}
            }
        },
        data() {
            return {
                loading: false,
                options: [{
                    value: '未分类',
                    label: '未分类'
                }, {
                    value: '熟食',
                    label: '熟食'
                }, {
                    value: '肉品',
                    label: '肉品'
                }, {
                    value: '水产',
                    label: '水产'
                }, {
                    value: '蔬果',
                    label: '蔬果'
                }],
                activeName: 'tab1'
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                this.loading = true
                let method = this.form.id ? 'put' : 'post'
                const resp = await this.$http[method]('/devices/temperature/lora/', this.form)
                if (resp.data.status != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.$message.success(this.$t('device.success'))
                    this.$emit('update')
                    this.$emit('close')
                }
                this.loading = false
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>
