<template>
    <zk-admin-table :title="$t('menu.temperature-beacon')" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
<!--            <el-table-column align="center" prop="device_id" label="设备ID" width="100">-->
<!--            </el-table-column>-->
            <el-table-column align="center" prop="sn" label="温度计SN" v-if="$store.getters.showSn=='1'">
            </el-table-column>
            <el-table-column align="center" prop="device_code" label="温度计别名" v-if="$store.getters.showSnPrefix=='1'">
            </el-table-column>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :key="idx">
            </el-table-column>
            <el-table-column  align="center" width="120" :label="$t('logi.operation')" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"> {{$t('logi.modification')}}</el-button>
                    <el-button type="text" size="mini" @click="handleEcharts(scope.row)">{{$t('logi.echarts')}}</el-button>
                </template>
            </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
        </template>
        <template v-slot:download>
            <data-down-load :visible="downloadVisible" :form="search" :total="table.total" @close="downloadVisible=false"></data-down-load>
        </template>
    </zk-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'
    import DataDownLoad from './widgets/dataDownLoad'
    import data from './data'
    export default {
        name: 'device-beacon',
        path: '/main/devices/temperature/beacon',
        components: {
            SearchForm,
            DataForm,
            DataDownLoad
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                downloadVisible: false,
                form: {},
                search:{},
                multipleSelection: [],
                device_sn_prefix:''
            }
        },
        methods: {
            doDownLoad() {
                this.downloadVisible = true
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableSelect(val) {
                this.multipleSelection = val;
            },
            handleAdd(){
                this.form = {}
                this.dialogVisible = true
            },
            async handleMultiUpdate() {
                if (this.multipleSelection.length !== 1) {
                    this.$message.error('请选择一条记录')
                    return
                }
                this.handleUpdate(this.multipleSelection[0])
            },
            async handleUpdate(row) {
                this.form = row
                // this.form.version = 'E.0.0'
                this.dialogVisible = true
            },
            async handleDelete() {
                if (this.multipleSelection.length<1) {
                    this.$message.error('请选择要删除的对象')
                    return
                }
                let ids = []
                this.multipleSelection.forEach(e => {
                    ids.push(e.device_id)
                })
                const resp = await this.$http.delete(`/devices/ap/`, {params: {ids: ids}})
                if (resp.data.status == 200)
                    this.$message.success("删除成功")
                else
                    this.$message.error(resp.data.message)
                this.getData()
            },
            async getData() {
                try {
                    const resp = await this.$http.get(`/devices/temperature/beacon/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getCompany() {
                try {
                    const resp = await this.$http.get(`/company/info/`)
                    console.log(resp)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.device_sn_prefix = resp.data.data.device_sn_prefix
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleEcharts(row) {
                this.$router.push({
                    path: `/main/devices/temperature/beacon/charts/${row.sn}`,
                })
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
