<template>
    <el-dialog :title="$t('logi.modelLabel')" :visible="visible" width="60%" @close="handleClose">
        <el-tabs v-model="activeName">
            <el-tab-pane label="设备详情" name="tab1">
                <tab1></tab1>
            </el-tab-pane>
            <el-tab-pane label="设备信息" name="tab2">
                <el-form class="form" ref="form" :model="form" label-width="100px" size="small">
                    <el-form-item label="设备名称" prop="device_name"
                                  :rules="{ required: true, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.device_name" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="软件版本" prop="sn"
                                  :rules="{ required: true, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.version" :disabled="true"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="设备配置" name="tab4">
                <el-form :inline="true" ref="form" :model="form" label-width="150px"
                         style="max-height: 400px;overflow: auto">
                    <el-card class="box-card">
                        <el-form-item :label="$t('device.openLockWaySetting.holdOpen')" prop="holdOpen">
                            <el-switch v-model="form.hold_open"
                                       active-value="1"
                                       inactive-value="0"
                                       placeholder=""
                            />
                            <span style="color: red">{{ $t('device.openLockWaySetting.holdOpenTips') }}</span>
                        </el-form-item>
                        <div v-show="form.hold_open=='1'">
                            <el-divider content-position="left">{{ $t('device.openLockWaySetting.title') }}</el-divider>
                            <el-form-item :label="$t('device.openLockWaySetting.nfcFunc')" prop="nfcFunc">
                                <el-switch v-model="form.nfc_func"
                                           active-value="1"
                                           inactive-value="0"
                                />
                            </el-form-item>
                            <el-form-item :label="$t('device.openLockWaySetting.nfcList')" prop="nfcList">
                                <el-select v-model="form.nfc_list" multiple filterable placeholder="">
                                    <el-option
                                            clearable="true"
                                            v-for="item in nfcOptions"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <el-divider content-position="left">{{ $t('device.ShopSetting.title') }}</el-divider>
                        <el-form-item :label="$t('device.ShopSetting.shop')" prop="shop">
                            <el-select v-model="form.shop_list" multiple filterable>
                                <el-option
                                        clearable="true"
                                        v-for="item in shopOptions"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-card>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="运输配置" name="tab3">
                <el-form :inline="true" ref="form" :model="form" label-position="left">
                    <el-form-item label="工作状态" prop="work_status">
                        <el-switch v-model="form.work_status" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-form-item>
                    <el-divider content-position="left"></el-divider>
                    <el-form-item :label="$t('device.cabotage.title')" prop="period">
                        <el-radio-group v-model="form.cabotage">
                            <el-radio :label="1">{{ $t('device.cabotage.day') }}</el-radio>
                            <el-radio :label="2">{{ $t('device.cabotage.week') }}</el-radio>
                            <el-radio :label="3">{{ $t('device.cabotage.twoWeek') }}</el-radio>
                            <el-radio :label="4">{{ $t('device.cabotage.month') }}</el-radio>
                            <el-radio :label="5">{{ $t('device.cabotage.threeMonth') }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-divider content-position="left"></el-divider>
                    <el-form-item label="司机名称"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.driver" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="车排号"  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.car_no" :disabled="!isNew"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="告警配置" name="tab5">
                <el-form ref="form" :model="form" label-position="left">
                    <el-form-item :label="$t('device.batterySetting.alarm')">
                        <el-input v-model.number="form.battery_min">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警类型"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-select v-model="form.alarm_type" multiple placeholder="请选择">
                            <el-option
                                    v-for="item in alarm_options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="邮件地址"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.alarm_email" :disabled="!isNew"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import Tab1 from './tab1'

    export default {
        components: {
            Tab1,
        },
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            isNew: {
                default: true
            }
        },
        data() {
            return {
                loading: false,
                activeName: 'tab4',
                shopOptions: [],
                alarm_options: [],
                nfcOptions:[]
            }
        },
        methods: {
            async formSubmit() {
                const result = true
                if (result) {
                    try {
                        let url = `/devices/cvsst/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>

