<template>
    <el-dialog :title="$t('logi.modelLabel')" :visible="visible" width="60%" @close="close">
        <el-tabs v-model="activeName">
            <el-tab-pane label="设备信息" name="tab1">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="AP SN" prop="sn" v-if="$store.getters.showSn!='0'" :rules="{ required: true, message: 'AP SN不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.sn" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="AP别名"  v-if="$store.getters.showSnPrefix!='0'">
                        <el-input v-model.trim="form.device_code" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="AP名称" prop="device_name"
                                  :rules="{ required: false, message: '设备名称不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.device_name" ></el-input>
                    </el-form-item>
                    <el-form-item label="MAC 地址" prop="mac"
                                  :rules="{ required: true, message: 'MAC 不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.mac" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="版本号" prop="version"
                                  :rules="{ required: true, message: '版本不能为空', trigger: 'blur'}">
                        <el-input v-model.trim="form.version" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-input v-model.trim="form.address"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="参数配置" name="tab2">
                <el-form ref="form" :model="form" label-width="200px">
                    <el-form-item :label="$t('temperature.wifi_name')" prop="wifi_name">
                        <el-input v-model.trim="form.wifi_name"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('temperature.wifi_password')" prop="wifi_password">
                        <el-input v-model.trim="form.wifi_password"></el-input>
                    </el-form-item>
                    <!--                    <el-form-item :label="$t('temperature.connect_off_time')" prop="connect_off_time">-->
                    <!--                        <el-input v-model.number="form.connect_off_time"></el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item :label="$t('temperature.scan_temp_interval')" prop="scan_temp_interval">
                        <el-input v-model.number="form.scan_temp_interval">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="上传日志的时间" prop="log_except_times">
                        <el-time-picker v-model="form.start"
                                        value-format="HH:mm"
                                        placeholder="开始时间">
                        </el-time-picker>
                        <el-time-picker v-model="form.end"
                                        style="margin-left: 10px"
                                        value-format="HH:mm"
                                        placeholder="结束时间">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="广播过滤类型" prop="c_f">
                        <el-select v-model="form.s_f" multiple placeholder="请选择" disabled>
                            <el-option label="AK_F" value="AK_F"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="连接过滤类型" prop="c_f">
                        <el-select v-model="form.c_f" multiple placeholder="请选择" disabled>
                            <el-option label="AK_F" value="AK_F"/>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            isNew: {
                default: false
            }
        },
        data() {
            return {
                loading: false,
                activeName: 'tab1',
            }
        },
        methods: {
            async formSubmit() {
                if (this.form.start && !this.form.end) {
                    this.$message.warning("结束时间必填")
                    return
                }
                if (!this.form.start && this.form.end) {
                    this.$message.warning("开始时间必填")
                    return
                }
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                console.log(this.form.device_id)
                let method = this.form.device_id ? 'put' : 'post'
                var temp = JSON.stringify(this.form)
                temp = JSON.parse(temp)
                if (temp.end) {
                    temp["log_except_times"] = [this.form.start, this.form.end]
                }
                const resp = await this.$http[method]('/devices/ap/', temp)
                if (resp.data.status != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.$message.success(this.$t('device.success'))
                    this.$emit('update')
                    this.$emit('close')
                }
            },
            close() {
                this.$emit("close")
            },
        },
        mounted() {

        }
    };
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
