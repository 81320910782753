export default {
    //   app、company、transport、device、system 已按首字母排序，并使用驼峰命名法
    app:{
        home: '首页',
        logout: '退出',
        passwd: '修改密码',
        title: 'TMS运输管理平台',
    },
    company:{
        baseInfo: '企业基础信息',
        businessLicense: "营业执照",
        businessLicenseRequired: '营业执照必填项',
        businessScope: '经营领域',
        cancel: '取 消',
        cods: '社会统一信用代码',
        companyIsChain: '是否连锁',
        companyLogo: '企业Logo',
        companyName: '企业名称',
        companyType: '企业类型',
        confirm: '确 认',
        contactInformation: "联系人信息",
        contactName: "联系人姓名",
        contactPhone: '联系人电话',
        email: '联系人邮件',
        foundedDate: '成立日期',
        issueAuthority: "登记机关",
        legalRepresentative: '法人代表',
        no: '否',
        optionDate: '选择日期',
        phone: '企业电话',
        pictorialInformation: '图片信息',
        qualificationCertificate: '资格证书',
        registeredCapital: '注册资本',
        registrationAddress: '注册地址',
        roadTransportOperationPermit: '道路运输许可证号',
        yes: '是',

        //部门
        addDepartment: '新建部门',
        delete: "删除",
        departmentCode: '部门代码',
        departmentName: '部门名称',
        hint: '您确定要删除次数据吗？',
        placeHolder: "请选择上级部门",
        searchDepartment: '搜索部门',
        superiorDepartment: '上级部门',
        titlehDepartment: '添加/修改',

        //角色
        dataPermission: "数据权限",
        description: '角色描述',
        modification: "修 改",
        name: '角色名称',
        operation: "操作",
        permission: "设置权限",
        roleAdd: '添 加',
        roleMenu: '角色菜单',
        rolePlaceHolder: '名称',
        roleSearch: '搜索',
        roleSetting: '角色配置',
        roleTitle: '角色列表',
        save: '保 存',
        serialNumber: "序号",

        //用户
        activated: '用户状态',
        ccEmails: '抄送邮箱',
        deoption: '选择部门',
        dep: '所属部门',
        departmentIds: '所属部门',
        depPlaceHolder: "请选择",
        endDate: "结束日期",
        firstName: '用户名称',
        inPlaceHolder: "请输入",
        lastModifiedDate: "修改时间",
        login: '登录名',
        passwd: '密码',
        reset: "重置",
        resetPasswd: '重置密码',
        role: "角色名称",
        roleIds: '角色名称',
        startDate: "开始日期",
        textPlaceHolder: "支持多个邮箱，用英文分号隔开",
        to: '至',
        type: "用户类型",
        userList: "用户列表",

        //报警
        1: '发货人',
        2: '收货人',
        3: '收发货人',
        alarmList: "报警联系人列表",
        alarmName: '名称',
        delSuccess: '删除成功',
        departmentId: '所属部门',
        emailEndTime: '邮件接收时间',
        emailStartTime: "邮件接收时间",
        emailText: '若未填写邮箱或邮箱填写错误，则无法接收邮件推送。',
        regionId: '区域',
        required: '必填项',
        resetSuccess: '重置成功',
        rulePass: '请输入密码',
        rulePassText: '长度在 8 到 15 个字符',
        rulesName: '请输入名称',
        smsEndTime: '短信接收时间',
        smsStartTime: "短信接收时间",
        smsText: '若未购买短信服务，侧无法接收短信推送。',
        success: '保存成功',
        consignEntities: '收/发货人管理',
        roleList: '角色管理',
    },
    transport:{
        //通用
        description: '角色描述',
        add: '添 加',
        cancel: '取 消',
        comment: '备注',
        confirm: '确 认',
        delete: "删 除",
        delSuccess: '删除成功',
        depPlaceHolder: "请选择",
        endDate: "结束日期",
        hint: '您确定要删除次数据吗？',
        inPlaceHolder: "请输入",
        modification: "修改",
        operation: "操作",
        placeHolderHeight: '高',
        placeHolderLength: '长',
        placeHolderWidth: '宽',
        reset: "重置",
        resetPasswords: "重置密码",
        search: '搜索',
        serialNumber: "序号",
        startDate: "开始日期",
        success: '保存成功',
        titleh: '添加/修改',
        to: '至',
        modeltitle:'添加货物',

        //货物管理
        goodsCode: "货物编码",
        goodsList: '货物管理',
        goodsName: "货物名称",
        goodsType: "货物类型",
        packageType: "包装类型",
        price: "销售单价",
        specification: "规格",
        unit: "单位",
        unitConversion: "单位转换",
        unitVolume: "单位体积",
        unitWeight: "单位重量",
        wholeUnit: "整件单位",
        wholeVolume: "整件体积",
        wholeWeight: "整件重量",

        //周转箱
        basketsList: '周转筐列表',
        containerCode: "周转筐编码",
        containerName: "周转筐名称",
        height: "外高度",
        innerHeight: "内高度",
        innerLength: "内长度",
        innerVolume: "内体积",
        innerWidth: "内宽度",
        length: "外长度",
        maxLoad: "最大载重",
        netWeight: "净重",
        volume: "外体积",
        width: "外宽度",

        //客户管理
        accountInformation: '账户信息',
        associatedElectronicFence: '关联电子围栏',
        bankAccountName: "银行账户名称",
        bankAccountNumber: "银行账户号码",
        bankBranch: "银行分支行名称",
        bankName: "银行名称",
        basicInformation: '基本信息',
        billingInformation: "开票信息",
        certificateUrl: "证件照片地址",
        cityId: "所在城市",
        clientList: "客户列表",
        cods: "统一社会信用代码",
        contactName: "联系人姓名",
        customerCode: "客户代码",
        customerName: "客户名称",
        electricFenceId: "电子围栏",
        email: "联系邮箱",
        idPhoto: '证件照片',
        invoiceTitle: "发票抬头",
        phone: "联系人电话",
        taxAddress: "税号注册地址",
        taxBankName: "税号开户行",
        taxBankNumber: "税号开户行银行账号",
        taxNumber: "税号",
        taxPhone: "税号注册时的联系电话",

        //承运商
        businessLicenseUrl: '上传营业执照',
        carrierCode: "承运商代码",
        carrierList: "承运商管理",
        carrierName: "承运商名称",
        qualificationCertificateUrl: '上传经营许可证',
        roadTransportOperationPermit: "道路运输经营许可证",

        //车辆档案
        brand: "车辆品牌",
        classification: "车辆能源类型",
        commonUse: '常用司机',
        dbasicInformation: "基础信息",
        driversId: "常用司机",
        drivingLicenseUrl1: '上传行驶证主页照片',
        drivingLicenseUrl2: '上传行驶证翻页照片',
        energyType: "车辆能源类型",
        engineNumber: "发动机号",
        insuranceCompany: "保险公司",
        insuranceInformation: '保险信息',
        insuranceNumber: "保险编号",
        issueAuthority: "发证机关",
        issueDate: "发证日期",
        licenceExpiryDate: "行驶证有效期日期",
        managementInformation: '管理信息',
        owner: "所有人",
        plateColor: "车牌颜色",
        plateNumber: "车牌号",
        registerDate: "注册日期",
        roadTransportPermit: "道路运输证号",
        shuoyuan:"常用送货员",
        syuan: "常用送货员",
        transportLicenseUrl: '上传道路运输证',
        useCharacter: "使用性质",
        vehicleList: "车辆档案",
        vih: "车辆识别代号",
        weight: "质量",

        //司机档案
        age: "年龄",
        anchoredObject: "挂靠对象",
        classAllowed: '准驾车型',
        congYeUrl: '上传从业资格证',
        contactNumber:"联系电话",
        Department: "部门",
        driverCertUrl1: '上传驾驶证主页',
        driverCertUrl2: '上传驾驶证副页',
        driverList: '司机列表',
        driverName: "司机名称",
        drivingAge: "驾龄",
        drivingLicense: "行驶证有效开始日期",
        endLicenseExpiration: "驾驶证有效结束日期",
        gender: "性别",
        idCardUrl1: '上传身份证头像页',
        idCardUrl2: '上传身份证国徽页',
        man: '男',
        nationalId: "身份证",
        qualificationCertificate: '从业资格证号',
        weChat: '微信号',
        woman: '女',

        //电子围栏
        address: "地址",
        area: "面积",
        areaNumber: "区域编码",
        areaType: "面积类型",
        circle: '圆',
        consignType: "发货类型",
        districtId: '区域',
        electricFence: '电子围栏',
        electricFenceList: "电子围栏列表",
        latitude: "纬度",
        longitude: "经度",
        mapLabel: '在地图上标注',
        polygon: '多边形',
        province: "所属省份",
        radius: "半径",
        redraw: '绘画',
        searchAddress: '请输入搜索地址',
        shareMode: "共享模式",
        square: '正方形',
        type: "类型",

        //车辆类型管理
        vehicleType: '车型管理',
        code: "车辆类型代码",
        costetting: '成本设置',
        costettingtext: '设置后，可用于智能调度，选择‘成本最优’时，进行成本预估',
        defaultAvailableQuantity: "默认可用数量",
        freightRate: '运费费率',
        minLoad: "最小载质量",
        maxVolume: "额定载积",
        minVolume: "最小载积",
        maxVolumn: "额定载积",
        minVolumn: "最小载积",
        name: "车辆类型名称",
        piece: '件',
        spellpointRate: '拼点费率',
        ton: '吨',
        typeGoodsAllowed: '允许的货物类型',
        unitary: '元',
        vehicleTypeList: '车辆类型列表',
        vMaxLoad: "额定质量",

        //门店
        areaName: "片区名称",
        businessType: "业务类型",
        cargo: "货物",
        carrier: "承运公司",
        customer: "客户",
        deliveryEmployeeList: '送货员管理',
        departureTimeRequired: "要求起运时间",
        disList: '距离时效列表',
        distance: "距离（km）",
        end: "终点",
        fenceName: "围栏名称",
        fenceNumber: "围栏编号",
        historicalLatitude: "历史纬度",
        historicalLocation: "历史位置",
        historicalLongitude: "历史经度",
        line: "线路",
        maximumShipping: "要求最大运输时长(h)",
        receivingArea: "收货区域",
        ReportLongPerson: "上报人",
        ReportLongTime: "上报时间",
        requestDeliveryTime: "要求发到时间",
        ShippingArea: "发货区域",
        shopList: '门店位置校准列表',
        SliceCode: "片区编码",
        source: "来源",
        startingPoint: "起点",
        status: "状态",
        vehicleId: "绑定车辆",
        //车辆管理
        deliveryArea:"片区管理",
        //订单
        orderList:"订单管理",
        bizTypeId: "业务类型",
        customerId: "客户",
        departmentId: "所属部门",
        consignerRegionId: "发货区域",
        consigneeRegionId: "收货区域",
        requiredShippingTime: "要求起运时间",
        requiredReceiptTime: "要求送到时间",
        cargoId: "货物",
        routeId: "线路",
        shippingType: "配送方式",
        required: '必填项'
    },
    device:{
        add: '添 加',
        alarm_type: '报警类型',
        alarmList: '报警列表',
        brightInterval: '光感记录间隔',
        brightMax: '光感范围',
        brightMin: '光感范围',
        cancel: '取 消',
        charger: '是否连接充电器',
        chart: '图表',
        confirm: '确 认',
        create_time: '报警时间',
        danger: "告警",
        data: '异常数据',
        data_source: '数据来源',
        delete: "删 除",
        delSuccess: '删除成功',
        depPlaceHolder: "请选择",
        endDate: "结束日期",
        format_time: '操作时间',
        hint: '您确定要删除次数据吗？',
        humCalibration: '湿度校准',
        humInterval: '湿度记录间隔',
        humMax: '湿度范围',
        humMin: '湿度范围',
        iccId: 'ICCID',
        id: "ID",
        inPlaceHolder: "请输入",
        localWay: '定位方式',
        locateWay: '定位方式',
        location: '位置',
        log: '日志',
        lockStatus1: {
            state: "电机状态为关，锁杆按到了底部",
            state1: "唤醒微动按下，锁杆微动未按下，锁杆微动坏",
            state2: "电机状态为开，锁杆按到了底部",
            state3: "唤醒微动按下，锁杆微动未按下，锁杆微动坏",
            state4: "电机状态为关，锁杆在锁杆微动位置",
            state5: "锁杆被异常拔出",
            state6: "电机状态为开，锁杆在锁杆微动位置",
            state7: "电机状态为开，锁杆已弹出",
            state8: "电机没转",
            state9: "关锁成功",
            state10: "锁已关闭",
            state11: "锁杆未按到底",
            state12: "开锁成功",
            state13: "锁已打开",
            state14: "锁杆异常拔出",
            state15: "此锁故障"
        },
        locksetting: {
            autoLock: "自动关锁",
            passwordUnlock: "密码开锁",
            password: "开锁密码"
        },
        modification: "修改",
        num: 'No.',
        operation: "操作",
        reset: "重置",
        record: {
            events: "记录事件"
        },
        search: '搜索',
        serialNumber: "序号",
        signal: '信号',
        startDate: "开始日期",
        success: '保存成功',
        taskNumber: "运单号",
        tempCalibration: '温度校准',
        tempInterval: '温度记录间隔',
        tempMax: "温度范围",
        tempMin: '温度范围',
        tiltDetection: '电量报警',
        tiltInterval: '搜索范围',
        titleh: '添加/修改',
        to: '至',

        // 设备绑定
        comment: "备注",
        devList: "设备列表",
        name: "名称",
        sn: "设备号",
        type: "设备类型",

        //蓝牙设备
        address: '地址',
        average: '平均值',
        battery: "电量",
        bluetoothList: '蓝牙设备列表',
        box_number: "保温箱号",
        carNo: '车牌号',
        Configure: '修改配置',
        createTime: "创建时间",
        crud: '操作',
        csq: '信号',
        departmentIds: "所属部门",
        device_id: "设备ID",
        device_name: "设备名称",
        deviceCode: '设备号',
        deviceName: '设备名称',
        devices: '设备',
        driver: '司机名称',
        endImg: "结束照片",
        events: '记录事件',
        getTracker: '查询轨迹',
        gpsSignal: '信号',
        info: '设备详情',
        last_seen: "最后采集时间",
        lastMonth: '最近一个月',
        lastSeen: '最后通讯时间',
        lastThreeMonths: '最近三个月',
        lastWeek: '最近一周',
        lockState: '锁状态',
        lockStateInfo: '锁状态详情',
        lockStatus: '锁状态',
        logList: '日志列表',
        loraList: 'LORA设备计列表',
        map: '地图',
        maximum: '最大值',
        minimum: '最小值',
        nfcNo: 'NFC卡号',
        noTracker: '这段时间内没有轨迹',
        "Number": '序号',
        number: '序号',
        online: '在线',
        operationTime: "操作时间",
        operationType: '操作类型',
        passCode: "厂家识别码",
        ran: '随机码',
        remark: "备注",
        report_interval: "温度计记录间隔",
        required: '请选择',
        searchData: '没查询到数据',
        selectDevice: '所选设备',
        selectTime: '选择时间',
        settings: '设置',
        sourceChart: '温度变化曲线图',
        startImg: "开始照片",
        temperature: "温度",
        temperature_adjust: "温度校准",
        temperature_max: "最高温度",
        temperature_min: "最底温度",
        temperatureSetting: '修改配置',
        tilt: '倾斜',
        timestamp: '最后采集时间',
        uncap: "后盖状态",
        updateTime: "更新时间",
        userName: '用户名称',
        version: "软件版本",
        workStatus: "运输状态",
        wxName: '微信名称',
        wxPhone: '手机号',

        basicSetting: {
            title: '基础配置',
            lock: '锁上报间隔',
            getParam: '参数获取间隔',
        },
        batterySetting: {
            title: '电量配置',
            alarm: '电量报警',
            start: '开始充电',
            end: '结束充电',
        },
        bindTask: {
            title: '绑定派车单',
            confirm: '设备运输中，是否解除?',
            success: '任务解除成功!',
            cancel: '已取消'
        },
        cabotage: {
            title: '运送周期',
            day: '一天',
            week: '一周',
            twoWeek: '半个月',
            month: '一个月',
            threeMonth: '三个月',
        },
        collision: {
            title: '碰撞',
            collision: '碰撞',
            normal: '正常'
        },
        collisionSetting: {
            title: '碰撞配置',
            collisionSwitch: '碰撞开关',
            collisionRange: '碰撞范围',
            collisionInterval: '上报间隔',
        },
        gpsSetting: {
            title: '定位配置',
            interval: '定位上报间隔',
            logInterval: '无网络上报间隔',
        },
        locationWay: {
            title: '定位方式',
            locationGPS: '卫星定位',
            locationBase: '基站定位',
            locationWIFI: 'WIFI定位',
            locationOther: '其他',
        },
        lockState1: {
            title: '锁状态',
            info: "锁状态详情",
            lockOpen: '上锁',
            lockClose: '未上锁'
        },
        networkSetting: {
            title: '搜网模式配置',
            autoSwitch: '自动模式开关',
            enterTime: '进入无网络模式',
            searchTime: '搜网间隔',
        },
        openLockWaySetting: {
            title: '开锁方式配置',
            nfcFunc: 'NFC开锁',
            nfcList: 'NFC卡号',
            remoteFunc: '远程开锁',
            remoteTime: '过期时间',
            holdOpen: '常开模式',
            holdOpenTips: '改为常开模式时，将禁用NFC，远程，蓝牙开锁，设备会自动开锁'
        },
        rules: {
            batteryMin: '电量百分比必须大于10%'
        },
        setting: {
            minute: '分钟'
        },
        startStop: {
            title: '启停',
            start: '启动',
            stop: '停止',
            normal: '正常'
        },
        ShopSetting: {
            title: '电子围栏',
            shop: '关联围栏',
        },
        startStopSetting: {
            title: '启停配置',
            startStopSwitch: '启停开关',
            startStopRange: '启停范围',
            startStopInterval: '上报间隔',
            stopTime: '停车休眠',
        },
        tiltSetting: {
            title: '倾斜配置',
            tiltSwitch: '倾斜开关',
            tiltXRange: '横向倾斜范围',
            tiltYRange: '纵向倾斜范围',
            tiltInterval: '上报间隔',
            tiltDetection: '检测间隔',
        },
        tiltX: {
            title: '横向倾斜',
            tilt: '倾斜',
            normal: '正常'
        },
        tiltY: {
            title: '纵向倾斜',
            tilt: '倾斜',
            normal: '正常'
        },
        workStatus1: {
            title: '运输状态',
            running: '运输中 ',
            idle: '空闲'
        },
    },
    system:{
        0: '正常',
        1: '停用',
        add: '添 加',
        button: '按钮',
        cancel: '取 消',
        catalogue: '目录',
        confirm: '确 认',
        createdDate: '创建时间',
        delete: "删 除",
        delSuccess: '删除成功',
        depPlaceHolder: "请选择",
        dictLabel: '字典标签',
        dictList: '字典列表',
        dictName: '字典名称',
        dictSort: '字典排序',
        dictType: '字典类型',
        dictTypeList: '字典类型列表',
        dictValue: '字典键值',
        endDate: "结束日期",
        hidden: '显示',
        hint: '您确定要删除次数据吗？',
        icon: '图标',
        inPlaceHolder: "请输入",
        lastModifiedDate: '修改日期',
        menuName: '名称',
        menus: '菜单',
        menuSort: '排序',
        modification: "修改",
        menuList: '菜单列表',
        no: '否',
        operation: "操作",
        parentMenuId: '上级类目',
        permission: '权限标识',
        remark: '备注',
        required: '必填项',
        reset: "重置",
        routes: '全部页面',
        search: '搜索',
        serialNumber: "序号",
        sMenu: '选择上级类目',
        startDate: "开始日期",
        status: '状态',
        success: '保存成功',
        titleh: '添加/修改',
        to: '至',
        type: '菜单类型',
        url: '组件路径',
        yes: '是',
    },
//////////////////////////////////////////////////////////////////////////////////////////////////
//  以下已排序
    menu: {
        alarm: '报警联系人管理',
        'alphaLock': 'Alpha-Lock 2.0',
        'alphaLockList': '设备管理',
        alphaLockRecord: '开锁记录',
        'alphaLockTrack': '轨迹查询',
        buckets: '周转筐管理',
        carrier: '承运商管理',
        company: '企业管理',
        company_department: '部门管理',
        company_info: '企业信息',
        company_role: '角色管理',
        company_user: '用户管理',
        'consign-entities': '收/发货人管理',
        customer: '客户管理',
        cvs: 'Alpha-Smart Lock',
        cvsAlarm: '异常报警记录',
        cvsList: '设备管理',
        cvsOperation: '操作记录',
        cvsRecord: '数据记录',
        cvsst: 'Alpha-Smart Lock-ST',
        cvsstalarm: '异常报警记录',
        cvsstList: '设备管理',
        cvsstOperation: '操作记录',
        cvsstTask: '运输任务',
        cvsTask: '运单任务',
        cvsTrack: '设备轨迹',
        dashboard: '系统首页',
        'delivery-area': '片区管理',
        'delivery-employee': '送货员管理',
        device: '设备管理',
        'device-apbind': '设备绑定',
        'device-bluetooth': '蓝牙设备',
        'device-logi': "定位器",
        'device-lora': 'LORA 设备',
        dict: '字典管理',
        dictType: '字典类型',
        dis: '距离时效管理',
        driver: '司机档案',
        'electric-fence': '电子围栏',
        goods: '货物管理',
        keyBox: '钥匙盒',
        'keybox-List': '设备管理',
        'keybox-location': '设备轨迹',
        'keybox-operation': '操作记录',
        'keybox-record': '日志记录',
        logiPlus: "Alpha-Logi 2.0",
        'logiPlus-alarm': '设备报警信息',
        'logiPlusCat1': 'Logi2.0 CAT1',
        'logiPlusCat1-alarm': '异常报警记录',
        'logiPlusCat1-info': '设备管理',
        'logiPlusCat1-locate': '设备轨迹',
        'logiPlusCat1-record': '记录查询',
        'logiPlus-locate': '轨迹查询',
        'logiPlus-monitoring': '设备监控',
        'logiPlus-name': 'Logi2.0',
        'logiPlus-record': '记录查询',
        'logplusList': '设备管理',
        menu: '菜单管理',
        oplog: '系统日志',
        order: '订单管理',
        routes: '路由管理',
        shop: '门店位置校准',
        system: '系统设置',
        temperature: '温度计',
        'temperature-alarm': '异常报警记录',
        'temperature-ap': "AP管理",
        'temperature-ap-record': "AP记录",
        'temperature-apmanage': 'Ap',
        'temperature-beacon': '蓝牙温度计',
        'temperature-connect': '蓝牙连接型',
        'temperature-lora': 'LORA温度计信标',
        'temperature-lora-alarm': '异常报警记录',
        'temperature-lora-bt': 'Lora温度计信标 ',
        'temperature-lora-record': ' Lora温度计信标记录',
        'temperature-record': '温度计记录',
        transport: '运输管理',
        vehicle: '车辆档案',
        'vehicle-type': '车型管理',
    },
    dashboard: {
        alarmCount: '告警数',
        deptCount: '部门数',
        deviceCount: '设备数',
        sourceChart: '资源占用图',
        sourcePieChart: '资源分布图',
        userCount: '用户数',
    },
    logi: {
        add: '添 加',
        alarm_type: '报警类型',
        alarmList: '报警列表',
        brightInterval: '光感记录间隔',
        brightMax: '光感范围',
        brightMin: '光感范围',
        cancel: '取 消',
        chart: '图表',
        confirm: '确 认',
        create_time: '报警时间',
        danger: "告警",
        data: '异常数据',
        delete: "删 除",
        delSuccess: '删除成功',
        depPlaceHolder: "请选择",
        deviceCode: 'imei',
        echarts: '图表',
        endDate: "结束日期",
        hint: '您确定要删除次数据吗？',
        holdOpen: '常开模式',
        humCalibration: '湿度校准',
        humInterval: '湿度记录间隔',
        humMax: '湿度范围',
        humMin: '湿度范围',
        id: "ID",
        inPlaceHolder: "请输入",
        location: '位置',
        lockState: '锁状态',
        log: '日志',
        modification: "修改",
        operation: "操作",
        reset: "重置",
        search: '搜索',
        serialNumber: "序号",
        startDate: "开始日期",
        startStop: '启停',
        success: '保存成功',
        tempCalibration: '温度校准',
        tempInterval: '温度记录间隔',
        tempMax: "温度范围",
        tempMin: '温度范围',
        tiltDetection: '电量报警',
        tiltInterval: '搜索范围',
        titleh: '添加/修改',
        to: '至',
        utc8: '最后通讯时间',
        workStatus: '运输状态',

        // 设备绑定
        devList: "设备列表",
        name: "名称",
        sn: "设备号",
        type: "设备类型",
        comment: "备注",
        //蓝牙设备
        bluetoothList: '蓝牙设备列表',
        temperatureSetting: '修改配置',
        report_interval: "温度计记录间隔",
        temperature_max: "最高温度",
        temperature_min: "最底温度",
        temperature_adjust: "温度校准",
        box_number: "保温箱号",
        device_name: "设备名称",
        version: "软件版本",
        device_id: "设备ID",
        battery: "电量",
        temperature: "温度",
        last_seen: "最后采集时间",
        logList: '日志列表',
        timestamp: '最后采集时间',
        loraList: 'LORA设备计列表',
        sourceChart: '温度变化曲线图',
        maximum: '最大值',
        minimum: '最小值',
        average: '平均值',
        searchData: '没查询到数据',
        lastSeen: '最后通讯时间',
        locate_way: '定位方式',
        collision: '碰撞',
        start: '启动',
        tilt_x: '横向倾斜',
        tilt_y: '纵向倾斜',
        status: '运输状态',
        humidity: '湿度',
        bright: '光感',
        signal: '信号',
        iccId: 'ICCID',
        modelLabel: '操作',
    },
    temperature: {
        add: '添 加',
        alarm_type: '报警类型',
        alarmList: '报警列表',
        brightInterval: '光感记录间隔',
        brightMax: '光感范围',
        brightMin: '光感范围',
        cancel: '取 消',
        chart: '图表',
        confirm: '确 认',
        create_time: '报警时间',
        danger: "告警",
        data: '异常数据',
        delete: "删 除",
        delSuccess: '删除成功',
        depPlaceHolder: "请选择",
        devNum: '设备号',
        endDate: "结束日期",
        format_time: '操作时间',
        hint: '您确定要删除次数据吗？',
        humCalibration: '湿度校准',
        humInterval: '湿度记录间隔',
        humMax: '湿度范围',
        humMin: '湿度范围',
        id: "ID",
        inPlaceHolder: "请输入",
        log: '日志',
        modification: "操作",
        operation: "操作",
        reset: "重置",
        search: '搜索',
        serialNumber: "序号",
        sn_m: 'AP SN',
        sn_s: '温度计 SN',
        startDate: "开始日期",
        success: '保存成功',
        tempCalibration: '温度校准',
        tempInterval: '温度记录间隔',
        tempMax: "温度范围",
        tempMin: '温度范围',
        tiltDetection: '电量报警',
        tiltInterval: '搜索范围',
        titleh: '添加/修改',
        to: '至',
        xnum: '箱号',
        zhTime: '最后通讯时间',

        // 设备绑定
        comment: "备注",
        devList: "设备列表",
        name: "名称",
        sn: "设备号",
        type: "设备类型",

        //蓝牙设备
        address: '地址',
        alarmop: '解除报警',
        alarmtime: "报警数据",
        alarmType: '报警类型',
        apsn: 'AP SN',
        average: '平均值',
        battery: "电量",
        bluetoothList: '蓝牙设备列表',
        box_number: "保温箱号",
        boxNumber: '保温箱号',
        car_no: '车牌号',
        carNo: '车牌号',
        category: '品类',
        connect_off_time: '连接型无数据交互时断开时间',
        device_id: "设备ID",
        device_name: "设备名称",
        driver: '司机名称',
        email_address: '邮件地址',
        emailAddress: '邮件地址',
        formatTime: '时间',
        last_seen: "最后采集时间",
        lastSeen: '最后通讯时间',
        location: '位置',
        logList: '日志列表',
        loraList: 'LORA设备计列表',
        maximum: '最大值',
        minimum: '最小值',
        report_interval: "温度计记录间隔",
        required: '请选择',
        scan_temp_interval: '扫描型同一个设备扫描间隔',
        searchData: '没查询到数据',
        snM: 'AP',
        snS: '温度计SN',
        sourceChart: '温度变化曲线图',
        temperature: "温度",
        temperature_adjust: "温度校准",
        temperature_max: "最高温度",
        temperature_min: "最底温度",
        temperatureSetting: '修改温度计',
        timestamp: '最后采集时间',
        version: "软件版本",
        wifi_name: '网关连接WIFI名称',
        wifi_password: '网关连接WIFI密码',
    },
    log: {
        content: '内容',
        depPlaceHolder: "请选择",
        logList: '系统日志',
        screen: '筛选',
        time: '时间',
        type: '类型',
    },
    permission: {
        action: '动作',
        alarm: '报警联系人',
        all: '全部',
        buckets: '周转筐',
        carrier: '承运商',
        company: '公司信息',
        'consign-entities': '收发货联系人',
        customer: '客户',
        data: '数据',
        delete: '删除',
        'delivery-area': '片区',
        'delivery-employee': '送货员',
        departments: '公司部门',
        dept: '部门',
        device: '设备管理',
        'device-bluetooth': '蓝牙设备',
        'device-lora': 'LORA 设备',
        devices: '设备绑定',
        dict: '字典管理',
        dictType: '字典类型',
        dis: '距离时效',
        driver: '司机档案',
        'electric-fence': '电子围栏',
        get: '查询',
        goods: '货物',
        menu: '菜单管理',
        oplog: '系统日志',
        order: '订单',
        post: '新建',
        put: '修改',
        roles: '角色',
        self: '自己的',
        shop: '门店位置校准',
        users: '用户',
        vehicle: '车辆档案',
        'vehicle-type': '车型',
    }
}
/*
export default {
    app: {
        title: 'TMS运输管理平台',
        home: '首页',
        passwd: '修改密码',
        logout: '退出',
    },
    test: '测试',
    menu: {
        dashboard: '系统首页',
        company: '企业管理',
        company_info: '企业信息',
        company_department: '部门管理',
        company_role: '角色管理',
        company_user: '用户管理',
        alarm: '报警联系人管理',
        'consign-entities': '收/发货人管理',
        transport: '运输管理',
        goods: '货物管理',
        buckets: '周转筐管理',
        customer: '客户管理',
        carrier: '承运商管理',
        vehicle: '车辆档案',
        driver: '司机档案',
        'electric-fence': '电子围栏',
        'vehicle-type': '车型管理',
        shop: '门店位置校准',
        dis: '距离时效管理',
        'delivery-employee': '送货员管理',
        'delivery-area': '片区管理',
        order: '订单管理',
        system: '系统设置',
        dictType: '字典类型',
        dict: '字典管理',
        menu: '菜单管理',
        oplog: '系统日志',
        device: '设备管理',
        'device-apbind': '设备绑定',
        'device-bluetooth': '蓝牙设备',
        'device-lora': 'LORA 设备',
        'device-logi': "定位器",
        routes: '路由管理',
        temperature: '温度计',
        'temperature-connect': '蓝牙连接型',
        'temperature-beacon': '蓝牙温度计',
        'temperature-record': '温度计记录',
        'temperature-alarm': '异常报警记录',
        'temperature-lora': 'LORA温度计信标',
        'temperature-lora-bt': 'Lora温度计信标 ',
        'temperature-lora-record': ' Lora温度计信标记录',
        'temperature-lora-alarm': '异常报警记录',
        'temperature-apmanage': 'Ap',
        'temperature-ap': "AP管理",
        logiPlus: "Alpha-Logi 2.0",
        'logiPlus-name': 'Logi2.0',
        'logiPlus-alarm': '设备报警信息',
        'logplusList': '设备管理',
        'logiPlus-monitoring': '设备监控',
        'logiPlus-locate': '轨迹查询',
        'logiPlus-record': '记录查询',
        'logiPlusCat1-alarm': '异常报警记录',
        'logiPlusCat1-record': '记录查询',
        'logiPlusCat1-locate': '设备轨迹',
        'logiPlusCat1-info': '设备管理',
        'logiPlusCat1': 'Logi2.0 CAT1',
        'alphaLock': 'Alpha-Lock 2.0',
        'alphaLockList': '设备管理',
        'alphaLocktrack': '轨迹查询',
        alphaLockrecord: '开锁记录',
        cvs: 'Alpha-Smart Lock',
        cvsList: '设备管理',
        cvstrack: '设备轨迹',
        cvsoperation: '操作记录',
        cvsrecord: '数据记录',
        cvstask: '运单任务',
        cvsalarm: '异常报警记录',
        cvsst: 'Alpha-Smart Lock-ST',
        cvsstList: '设备管理',
        cvsstalarm: '异常报警记录',
        cvsstoperation: '操作记录',
        cvssttask: '运输任务',
        keyBox: '钥匙盒',
        'keybox-List': '设备管理',
        'keybox-location': '设备轨迹',
        'keybox-operation': '操作记录',
        'keybox-record': '日志记录'

    },
    dashboard: {
        userCount: '用户数',
        deptCount: '部门数',
        deviceCount: '设备数',
        alarmCount: '告警数',
        sourceChart: '资源占用图',
        sourcePieChart: '资源分布图',
    },
    company: {
        baseInfo: '企业基础信息',
        companyName: '企业名称',
        companyType: '企业类型',
        companyIsChain: '是否连锁',
        businessScope: '经营领域',
        roadTransportOperationPermit: '道路运输许可证号',
        cods: '社会统一信用代码',
        registrationAddress: '注册地址',
        phone: '企业电话',
        legalRepresentative: '法人代表',
        foundedDate: '成立日期',
        optiondate: '选择日期',
        issueAuthority: "登记机关",
        registeredCapital: '注册资本',
        contactinformation: "联系人信息",
        contactName: "联系人姓名",
        contactPhone: '联系人电话',
        email: '联系人邮件',
        pictorialinformation: '图片信息',
        businesslicense: "营业执照",
        businesslicenserequired: '营业执照必填项',
        qualificationCertificate: '资格证书',
        companylogo: '企业Logo',
        confirm: '确 认',
        cancel: '取 消',
        yes: '是',
        no: '否',
        //部门
        adddepartment: '新建部门',
        searchdepartment: '搜索部门',
        titlehDepartment: '添加/修改',
        superiordepartment: '上级部门',
        placeholder: "请选择上级部门",
        departmentName: '部门名称',
        departmentCode: '部门代码',
        hint: '您确定要删除次数据吗？',
        delete: "删除",
        //角色
        roleTitle: '角色列表',
        rolePlaceHolder: '名称',
        roleSearch: '搜索',
        roleAdd: '添 加',
        serialNumber: "序号",
        name: '角色名称',
        description: '角色描述',
        operation: "操作",
        modification: "修 改",
        permission: "设置权限",
        rolesetting: '角色配置',
        rolemenu: '角色菜单',
        save: '保 存',
        datapermission: "数据权限",
        //用户
        userList: "用户列表",
        to: '至',
        startDate: "开始日期",
        endDate: "结束日期",
        reset: "重置",
        resetPasswd: '重置密码',
        passwd: '密码',
        firstName: '用户名称',
        login: '登录名',
        activated: '用户状态',
        roleIds: '角色名称',
        departmentIds: '所属部门',
        ccEmails: '抄送邮箱',
        deoption: '选择部门',
        depPlaceHolder: "请选择",
        inPlaceHolder: "请输入",
        textPlaceHolder: "支持多个邮箱，用英文分号隔开",
        type: "用户类型",
        role: "角色名称",
        dep: '所属部门',
        lastModifiedDate: "修改时间",
        //报警
        alarmName: '名称',
        emailStartTime: "邮件接收时间",
        smsStartTime: "短信接收时间",
        departmentId: '所属部门',
        smsEndTime: '短信接收时间',
        emailEndTime: '邮件接收时间',
        smstext: '若未购买短信服务，侧无法接收短信推送。',
        emailtext: '若未填写邮箱或邮箱填写错误，则无法接收邮件推送。',
        alarmList: "报警联系人列表",
        1: '发货人',
        2: '收货人',
        3: '收发货人',
        regionId: '区域',
        required: '必填项',
        success: '保存成功',
        delSuccess: '删除成功',
        rulesname: '请输入名称',
        rulepass: '请输入密码',
        rulepasstext: '长度在 8 到 15 个字符',
        resetsuccess: '重置成功'
    },
    transport: {
        //通用
        success: '保存成功',
        delSuccess: '删除成功',
        to: '至',
        search: '搜索',
        startDate: "开始日期",
        endDate: "结束日期",
        reset: "重置",
        serialNumber: "序号",
        operation: "操作",
        modification: "修改",
        resetPasswords: "重置密码",
        comment: '备注',
        add: '添 加',
        confirm: '确 认',
        cancel: '取 消',
        hint: '您确定要删除次数据吗？',
        delete: "删 除",
        titleh: '添加/修改',
        depPlaceHolder: "请选择",
        inPlaceHolder: "请输入",
        placeHolderHeight: '高',
        placeHolderLength: '长',
        placeHolderWidth: '宽',
        //货物管理
        goodsList: '货物管理',
        goodsName: "货物名称",
        goodsCode: "货物编码",
        goodsType: "货物类型",
        unit: "单位",
        wholeUnit: "整件单位",
        unitConversion: "单位转换",
        unitWeight: "单位重量",
        wholeWeight: "整件重量",
        unitVolume: "单位体积",
        wholeVolume: "整件体积",
        price: "销售单价",
        packageType: "包装类型",
        specification: "规格",
        //周转箱
        basketsList: '周转筐列表',
        containerName: "周转筐名称",
        containerCode: "周转筐编码",
        length: "外长度",
        width: "外宽度",
        height: "外高度",
        innerLength: "内长度",
        innerWidth: "内宽度",
        innerHeight: "内高度",
        volume: "外体积",
        innerVolume: "内体积",
        netWeight: "净重",
        maxLoad: "最大载重",
        //客户管理
        clientList: "客户列表",
        customerName: "客户名称",
        customerCode: "客户代码",
        cods: "统一社会信用代码",
        cityId: "所在城市",
        email: "联系邮箱",
        contactName: "联系人姓名",
        phone: "联系人电话",
        bankAccountName: "银行账户名称",
        bankAccountNumber: "银行账户号码",
        bankName: "银行名称",
        bankBranch: "银行分支行名称",
        invoiceTitle: "发票抬头",
        taxNumber: "税号",
        taxAddress: "税号注册地址",
        taxBankName: "税号开户行",
        taxBankNumber: "税号开户行银行账号",
        taxPhone: "税号注册时的联系电话",
        electricFenceId: "电子围栏",
        certificateUrl: "证件照片地址",
        idPhoto: '证件照片',
        basicInformation: '基本信息',
        associatedElectronicFence: '关联电子围栏',
        accountInformation: '账户信息',
        billingInformation: "开票信息",
        //承运商
        carrierList: "承运商管理",
        carrierName: "承运商名称",
        carrierCode: "承运商代码",
        roadTransportOperationPermit: "道路运输经营许可证",
        businessLicenseUrl: '上传营业执照',
        qualificationCertificateUrl: '上传经营许可证',
        //车辆档案
        driversId: "常用司机",
        managementinformation: '管理信息',
        commonUse: '常用司机',
        shuoyuan: '常用送货员',
        syuan: "常用送货员",
        dbasicInformation: "基础信息",
        insuranceInformation: '保险信息',
        drivingLicenseUrl1: '上传行驶证主页照片',
        drivingLicenseUrl2: '上传行驶证翻页照片',
        transportLicenseUrl: '上传道路运输证',
        vehicleList: "车辆档案",
        brand: "车辆品牌",
        vih: "车辆识别代号",
        plateNumber: "车牌号",
        plateColor: "车牌颜色",
        engineNumber: "发动机号",
        registerDate: "注册日期",
        issueDate: "发证日期",
        licenceExpiryDate: "行驶证有效期日期",
        issueAuthority: "发证机关",
        weight: "质量",
        // "netWeight":"核定载质量",
        classification: "车辆能源类型",
        roadTransportPermit: "道路运输证号",
        owner: "所有人",
        useCharacter: "使用性质",
        energyType: "车辆能源类型",
        insuranceNumber: "保险编号",
        insuranceCompany: "保险公司",
        //司机档案
        driverList: '司机列表',
        idCardUrl1: '上传身份证头像页',
        idCardUrl2: '上传身份证国徽页',
        driverCertUrl1: '上传驾驶证主页',
        driverCertUrl2: '上传驾驶证副页',
        congYeUrl: '上传从业资格证',
        driverName: "司机名称",
        gender: "性别",
        nationalId: "身份证",
        age: "年龄",
        weChat: '微信号',
        classAllowed: '准驾车型',
        qualificationCertificate: '从业资格证号',
        // "contact number":"联系电话",
        drivingAge: "驾龄",
        Department: "部门",
        anchoredObject: "挂靠对象",
        drivingLicense: "行驶证有效开始日期",
        endLicenseExpiration: "驾驶证有效结束日期",
        man: '男',
        woman: '女',
        //电子围栏
        electricfence: '电子围栏',
        electricFenceList: "电子围栏列表",
        area: "区域名称",
        areanumber: "区域编码",
        areatype: "区域类型",
        districtId: '区域',
        consignType: "发货类型",
        type: "类型",
        shareMode: "共享模式",
        radius: "半径",
        latitude: "纬度",
        longitude: "经度",
        address: "地址",
        province: "所属省份",
        redraw: '绘画',
        searchAddress: '请输入搜索地址',
        mapLabel: '在地图上标注',
        polygon: '多边形',
        square: '正方形',
        circle: '圆',
        //车辆类型管理
        vehicleTypeList: '车辆类型列表',
        name: "车辆类型名称",
        code: "车辆类型代码",
        defaultAvailableQuantity: "默认可用数量",
        vMaxLoad: "额定质量",
        minLoad: "最小载质量",
        maxVolume: "额定载积",
        minVolume: "最小载积",
        typeGoodsAllowed: '允许的货物类型',
        costetting: '成本设置',
        costettingtext: '设置后，可用于智能调度，选择‘成本最优’时，进行成本预估',
        freightRate: '运费费率',
        spellpointRate: '拼点费率',
        ton: '吨',
        piece: '件',
        unitary: '元',
        //门店
        shopList: '门店位置校准列表',
        status: "状态",
        ReportLongPerson: "上报人",
        ReportLongTime: "上报时间",
        source: "来源",
        fenceName: "围栏名称",
        fenceNumber: "围栏编号",
        historicalLocation: "历史位置",
        historicalLongitude: "历史经度",
        historicalLatitude: "历史纬度",
        //
        disList: '距离时效列表',
        startingpoint: "起点",
        end: "终点",
        carrier: "承运公司",
        distance: "距离（km）",
        maximumshipping: "要求最大运输时长(h)",
        //
        // "name":"姓名",
        // "Phone number":"手机号",
        // "address":"住居地址",
        deliveryEmployeeList: '送货员管理',
        vehicleId: "绑定车辆",
        //
        Areaname: "片区名称",
        Slicecode: "片区编码",
        //
        businesstype: "业务类型",
        customer: "客户",
        receivingarea: "收货区域",
        Shippingarea: "发货区域",
        Departuretimerequired: "要求起运时间",
        requestdeliverytime: "要求发到时间",
        line: "线路",
        cargo: "货物",
        //d订单
        bizTypeId: "业务类型",
        customerId: "客户",
        departmentId: "所属部门",
        consignerRegionId: "发货区域",
        consigneeRegionId: "收货区域",
        requiredShippingTime: "要求起运时间",
        requiredReceiptTime: "要求送到时间",
        cargoId: "货物",
        routeId: "线路",
        shippingType: "配送方式",
        required: '必填项'
    },
    device: {
        locate_way: '定位方式',
        iccid: 'ICCID',
        charger: '是否连接充电器',
        taskNumber: "运单号",
        locksetting: {
            autoLock: "自动关锁",
            passwordUnlock: "密码开锁",
            password: "开锁密码"
        },
        record: {
            events: "记录事件"
        },
        format_time: '操作时间',
        lock_state: '锁状态',
        'Number': 'No.',
        location: '位置',
        signal: '信号',
        data_source: '数据来源',
        start_img: '开始照片',
        end_img: '结束照片',
        local_way: '定位方式',
        lockStatus1: {
            state: "电机状态为关，锁杆按到了底部",
            state1: "唤醒微动按下，锁杆微动未按下，锁杆微动坏",
            state2: "电机状态为开，锁杆按到了底部",
            state3: "唤醒微动按下，锁杆微动未按下，锁杆微动坏",
            state4: "电机状态为关，锁杆在锁杆微动位置",
            state5: "锁杆被异常拔出",
            state6: "电机状态为开，锁杆在锁杆微动位置",
            state7: "电机状态为开，锁杆已弹出",
            state8: "电机没转",
            state9: "关锁成功",
            state10: "锁已关闭",
            state11: "锁杆未按到底",
            state12: "开锁成功",
            state13: "锁已打开",
            state14: "锁杆异常拔出",
            state15: "此锁故障"
        },
        tiltDetection: '电量报警',
        tiltInterval: '搜索范围',
        tempMax: "温度范围",
        tempCalibration: '温度校准',
        tempInterval: '温度记录间隔',
        humMin: '湿度范围',
        humMax: '湿度范围',
        humInterval: '湿度记录间隔',
        humCalibration: '湿度校准',
        brightMax: '光感范围',
        brightMin: '光感范围',
        brightInterval: '光感记录间隔',
        tempMin: '温度范围',
        id: "ID",
        alarm_type: '报警类型',
        data: '异常数据',
        create_time: '报警时间',
        alarmList: '报警列表',
        danger: "告警",
        depPlaceHolder: "请选择",
        inPlaceHolder: "请输入",
        success: '保存成功',
        delSuccess: '删除成功',
        to: '至',
        search: '搜索',
        startDate: "开始日期",
        endDate: "结束日期",
        reset: "重置",
        operation: "操作",
        modification: "修改",
        add: '添 加',
        confirm: '确 认',
        cancel: '取 消',
        hint: '您确定要删除次数据吗？',
        delete: "删 除",
        titleh: '添加/修改',
        log: '日志',
        chart: '图表',
        serialNumber: "序号",
        // 设备绑定
        devList: "设备列表",
        name: "名称",
        sn: "设备号",
        type: "设备类型",
        comment: "备注",
        //蓝牙设备
        bluetoothList: '蓝牙设备列表',
        temperatureSetting: '修改配置',
        report_interval: "温度计记录间隔",
        temperature_max: "最高温度",
        temperature_min: "最底温度",
        temperature_adjust: "温度校准",
        box_number: "保温箱号",
        device_name: "设备名称",
        version: "软件版本",
        device_id: "设备ID",
        battery: "电量",
        temperature: "温度",
        last_seen: "最后采集时间",
        logList: '日志列表',
        timestamp: '最后采集时间',
        loraList: 'LORA设备计列表',
        sourceChart: '温度变化曲线图',
        maximum: '最大值',
        minimum: '最小值',
        average: '平均值',
        searchData: '没查询到数据',
        uncap: "后盖状态",
        setting: {
            minute: '分钟'
        },
        online: '在线',
        driver: '司机名称',
        carNo: '车牌号',
        address: '地址',
        info: '设备详情',
        settings: '设置',
        Configure: '修改配置',
        deviceCode: '设备号',
        deviceName: '设备名称',
        lastSeen: '最后通讯时间',
        gpsSignal: '信号',
        tilt: '倾斜',
        crud: '操作',
        locationWay: {
            title: '定位方式',
            locationGPS: '卫星定位',
            locationBase: '基站定位',
            locationWIFI: 'WIFI定位',
            locationOther: '其他',
        },
        lockState: '锁状态',
        lockState1: {
            title: '锁状态',
            info: "锁状态详情",
            lockOpen: '上锁',
            lockClose: '未上锁'
        },
        collision: {
            title: '碰撞',
            collision: '碰撞',
            normal: '正常'
        },
        startStop: {
            title: '启停',
            start: '启动',
            stop: '停止',
            normal: '正常'
        },
        tiltX: {
            title: '横向倾斜',
            tilt: '倾斜',
            normal: '正常'
        },
        tiltY: {
            title: '纵向倾斜',
            tilt: '倾斜',
            normal: '正常'

        },
        workStatus: "运输状态",
        workStatus1: {
            title: '运输状态',
            running: '运输中 ',
            idle: '空闲'
        },
        batterySetting: {
            title: '电量配置',
            alarm: '电量报警',
            start: '开始充电',
            end: '结束充电',
        },
        basicSetting: {
            title: '基础配置',
            lock: '锁上报间隔',
            getParam: '参数获取间隔',
        },
        gpsSetting: {
            title: '定位配置',
            interval: '定位上报间隔',
            logInterval: '无网络上报间隔',
        },
        networkSetting: {
            title: '搜网模式配置',
            autoSwitch: '自动模式开关',
            enterTime: '进入无网络模式',
            searchTime: '搜网间隔',
        },
        startStopSetting: {
            title: '启停配置',
            startStopSwitch: '启停开关',
            startStopRange: '启停范围',
            startStopInterval: '上报间隔',
            stopTime: '停车休眠',
        },
        collisionSetting: {
            title: '碰撞配置',
            collisionSwitch: '碰撞开关',
            collisionRange: '碰撞范围',
            collisionInterval: '上报间隔',
        },
        tiltSetting: {
            title: '倾斜配置',
            tiltSwitch: '倾斜开关',
            tiltXRange: '横向倾斜范围',
            tiltYRange: '纵向倾斜范围',
            tiltInterval: '上报间隔',
            tiltDetection: '检测间隔',
        },
        openLockWaySetting: {
            title: '开锁方式配置',
            nfcFunc: 'NFC开锁',
            nfcList: 'NFC卡号',
            remoteFunc: '远程开锁',
            remoteTime: '过期时间',
            holdOpen: '常开模式',
            holdOpenTips: '改为常开模式时，将禁用NFC，远程，蓝牙开锁，设备会自动开锁'
        },
        ShopSetting: {
            title: '电子围栏',
            shop: '关联围栏',
        },
        cabotage: {
            title: '运送周期',
            day: '一天',
            week: '一周',
            twoWeek: '半个月',
            month: '一个月',
            threeMonth: '三个月',
        },
        bindTask: {
            title: '绑定派车单',
            confirm: '设备运输中，是否解除?',
            success: '任务解除成功!',
            cancel: '已取消'
        },
        rules: {
            batteryMin: '电量百分比必须大于10%'
        },
        map: '地图',
        devices: '设备',
        selectTime: '选择时间',
        selectDevice: '所选设备',
        getTracker: '查询轨迹',
        lastWeek: '最近一周',
        lastMonth: '最近一个月',
        lastThreeMonths: '最近三个月',
        noTracker: '这段时间内没有轨迹',
        number: '序号',
        userName: '用户名称',
        events: '记录事件',
        wxName: '微信名称',
        wxPhone: '手机号',
        lockStatus: '锁状态',
        createTime: "创建时间",
        updateTime: "更新时间",
        startImg: "开始照片",
        endImg: "结束照片",
        remark: "备注",
        lockStateInfo: '锁状态详情',
        nfcNo: 'NFC卡号',
        ran: '随机码',
        csq: '信号',
        operationTime: "操作时间",
        operationType: '操作类型',
        departmentIds: "所属部门",
        passcode: "厂家识别码",
        required: '请选择',
    },
    logi: {
        echarts: '图表',
        location: '位置',
        utc8: '最后通讯时间',
        deviceCode: 'imei',
        workStatus: '运输状态',
        startStop: '启停',
        lockState: '锁状态',
        holdOpen: '常开模式',
        tiltDetection: '电量报警',
        tiltInterval: '搜索范围',
        tempMax: "温度范围",
        tempCalibration: '温度校准',
        tempInterval: '温度记录间隔',
        humMin: '湿度范围',
        humMax: '湿度范围',
        humInterval: '湿度记录间隔',
        humCalibration: '湿度校准',
        brightMax: '光感范围',
        brightMin: '光感范围',
        brightInterval: '光感记录间隔',
        tempMin: '温度范围',
        id: "ID",
        alarm_type: '报警类型',
        data: '异常数据',
        create_time: '报警时间',
        alarmList: '报警列表',
        danger: "告警",
        depPlaceHolder: "请选择",
        inPlaceHolder: "请输入",
        success: '保存成功',
        delSuccess: '删除成功',
        to: '至',
        search: '搜索',
        startDate: "开始日期",
        endDate: "结束日期",
        reset: "重置",
        operation: "操作",
        modification: "修改",
        add: '添 加',
        confirm: '确 认',
        cancel: '取 消',
        hint: '您确定要删除次数据吗？',
        delete: "删 除",
        titleh: '添加/修改',
        log: '日志',
        chart: '图表',
        serialNumber: "序号",
        // 设备绑定
        devList: "设备列表",
        name: "名称",
        sn: "设备号",
        type: "设备类型",
        comment: "备注",
        //蓝牙设备
        bluetoothList: '蓝牙设备列表',
        temperatureSetting: '修改配置',
        report_interval: "温度计记录间隔",
        temperature_max: "最高温度",
        temperature_min: "最底温度",
        temperature_adjust: "温度校准",
        box_number: "保温箱号",
        device_name: "设备名称",
        version: "软件版本",
        device_id: "设备ID",
        battery: "电量",
        temperature: "温度",
        last_seen: "最后采集时间",
        logList: '日志列表',
        timestamp: '最后采集时间',
        loraList: 'LORA设备计列表',
        sourceChart: '温度变化曲线图',
        maximum: '最大值',
        minimum: '最小值',
        average: '平均值',
        searchData: '没查询到数据',
        lastSeen: '最后通讯时间',
        locate_way: '定位方式',
        collision: '碰撞',
        start: '启动',
        tilt_x: '横向倾斜',
        tilt_y: '纵向倾斜',
        status: '运输状态',
        humidity: '湿度',
        bright: '光感',
        signal: '信号',
        iccid: 'ICCID',
        modelLabel: '操作',
    },
    temperature: {
        format_time: '操作时间',
        sn_m: 'AP SN',
        sn_s: '温度计 SN',
        devNum: '设备号',
        xnum: '箱号',
        zhTime: '最后通讯时间',
        tiltDetection: '电量报警',
        tiltInterval: '搜索范围',
        tempMax: "温度范围",
        tempCalibration: '温度校准',
        tempInterval: '温度记录间隔',
        humMin: '湿度范围',
        humMax: '湿度范围',
        humInterval: '湿度记录间隔',
        humCalibration: '湿度校准',
        brightMax: '光感范围',
        brightMin: '光感范围',
        brightInterval: '光感记录间隔',
        tempMin: '温度范围',
        id: "ID",
        alarm_type: '报警类型',
        data: '异常数据',
        create_time: '报警时间',
        alarmList: '报警列表',
        danger: "告警",
        depPlaceHolder: "请选择",
        inPlaceHolder: "请输入",
        success: '保存成功',
        delSuccess: '删除成功',
        to: '至',
        search: '搜索',
        startDate: "开始日期",
        endDate: "结束日期",
        reset: "重置",
        operation: "操作",
        modification: "操作",
        add: '添 加',
        confirm: '确 认',
        cancel: '取 消',
        hint: '您确定要删除次数据吗？',
        delete: "删 除",
        titleh: '添加/修改',
        log: '日志',
        chart: '图表',
        serialNumber: "序号",
        // 设备绑定
        devList: "设备列表",
        name: "名称",
        sn: "设备号",
        type: "设备类型",
        comment: "备注",
        //蓝牙设备
        bluetoothList: '蓝牙设备列表',
        temperatureSetting: '修改温度计',
        report_interval: "温度计记录间隔",
        temperature_max: "最高温度",
        temperature_min: "最底温度",
        temperature_adjust: "温度校准",
        box_number: "保温箱号",
        device_name: "设备名称",
        version: "软件版本",
        device_id: "设备ID",
        battery: "电量",
        temperature: "温度",
        last_seen: "最后采集时间",
        logList: '日志列表',
        timestamp: '最后采集时间',
        loraList: 'LORA设备计列表',
        sourceChart: '温度变化曲线图',
        maximum: '最大值',
        minimum: '最小值',
        average: '平均值',
        searchData: '没查询到数据',
        location: '位置',
        snM: 'AP',
        snS: '温度计SN',
        formatTime: '时间',
        apsn: 'AP SN',
        category: '品类',
        alarmop: '解除报警',
        emailAddress: '邮件地址',
        email_address: '邮件地址',
        boxNumber: '保温箱号',
        alarmtime: "报警数据",
        lastSeen: '最后通讯时间',
        wifi_name: '网关连接WIFI名称',
        wifi_password: '网关连接WIFI密码',
        connect_off_time: '连接型无数据交互时断开时间',
        scan_temp_interval: '扫描型同一个设备扫描间隔',
        address: '地址',
        driver: '司机名称',
        carNo: '车牌号',
        car_no: '车牌号',
        alarmType: '报警类型',
        required: '请选择',
        //蓝牙设备
    },
    system: {
        serialNumber: "序号",
        depPlaceHolder: "请选择",
        inPlaceHolder: "请输入",
        success: '保存成功',
        delSuccess: '删除成功',
        dictList: '字典列表',
        to: '至',
        search: '搜索',
        startDate: "开始日期",
        endDate: "结束日期",
        reset: "重置",
        operation: "操作",
        modification: "修改",
        add: '添 加',
        confirm: '确 认',
        cancel: '取 消',
        hint: '您确定要删除次数据吗？',
        delete: "删 除",
        titleh: '添加/修改',
        dictName: '字典名称',
        dictType: '字典类型',
        status: '状态',
        remark: '备注',
        createdDate: '创建时间',
        dictLabel: '字典标签',
        dictValue: '字典键值',
        dictSort: '字典排序',
        dictTypeList: '字典类型列表',
        1: '停用',
        0: '正常',
        required: '必填项',
        munelist: '菜单列表',
        menuName: '名称',
        icon: '图标',
        menuSort: '排序',
        hidden: '显示',
        permission: '权限标识',
        url: '组件路径',
        lastModifiedDate: '修改日期',
        yes: '是',
        no: '否',
        smenu: '选择上级类目',
        parentMenuId: '上级类目',
        type: '菜单类型',
        catalogue: '目录',
        menus: '菜单',
        button: '按钮',
        routes: '全部页面'
    },
    log: {
        depPlaceHolder: "请选择",
        logList: '系统日志',
        screen: '筛选',
        time: '时间',
        type: '类型',
        content: '内容'
    },
    permission: {
        get: '查询',
        post: '新建',
        put: '修改',
        delete: '删除',
        all: '全部',
        dept: '部门',
        self: '自己的',
        action: '动作',
        data: '数据',
        company: '公司信息',
        departments: '公司部门',
        roles: '角色',
        users: '用户',
        alarm: '报警联系人',
        'consign-entities': '收发货联系人',
        goods: '货物',
        buckets: '周转筐',
        customer: '客户',
        carrier: '承运商',
        vehicle: '车辆档案',
        driver: '司机档案',
        'electric-fence': '电子围栏',
        'vehicle-type': '车型',
        shop: '门店位置校准',
        dis: '距离时效',
        'delivery-employee': '送货员',
        'delivery-area': '片区',
        order: '订单',
        dictType: '字典类型',
        dict: '字典管理',
        menu: '菜单管理',
        oplog: '系统日志',
        device: '设备管理',
        devices: '设备绑定',
        'device-bluetooth': '蓝牙设备',
        'device-lora': 'LORA 设备',
    }
}
 */
