<template>
    <div>
        <el-form :model="dataform" :rules="rules" ref="dataform" label-width="200px" style="padding-bottom:80px">
            <el-row class="title">
                <span>{{$t('company.baseInfo')}}</span>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-col :span="8">
                        <el-form-item :label="$t('company.companyName')" prop="companyName">
                            <el-input v-model="dataform.companyName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.companyType')">
                            <el-input v-model="dataform.type">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.companyIsChain')">
                            <el-radio-group v-model="dataform.isChain">
                                <el-radio :label="true">{{$t('company.yes')}}</el-radio>
                                <el-radio :label="false">{{$t('company.no')}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="8">
                        <el-form-item :label="$t('company.businessScope')">
                            <el-input v-model="dataform.businessScope">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.roadTransportOperationPermit')">
                            <el-input v-model="dataform.roadTransportOperationPermit">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="8">
                        <el-form-item :label="$t('company.cods')">
                            <el-input v-model="dataform.cods">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.registrationAddress')">
                            <el-input v-model="dataform.registrationAddress">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.phone')">
                            <el-input v-model="dataform.phone">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.legalRepresentative')">
                            <el-input v-model="dataform.legalRepresentative">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.foundedDate')">
                            <el-date-picker
                                    value-format="yyyy-MM-dd"
                                    v-model="dataform.foundedDate"
                                    type="date"
                                    style="width: 100%"
                                    :placeholder="$t('company.optiondate')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.issueAuthority')" >
                            <el-input v-model="dataform.issueAuthority">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.registeredCapital')">
                            <el-input v-model="dataform.registeredCapital">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>
            <el-row class="title">
                <span>{{$t('company.contactInformation') }}</span>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item :label="$t('company.contactName')">
                        <el-input v-model="dataform.contactName">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="$t('company.contactPhone')">
                        <el-input v-model="dataform.contactPhone">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :label="$t('company.email')">
                        <el-input v-model="dataform.email">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="title">
                <span>{{$t('company.pictorialInformation')}}</span>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="">
                        <zk-upload-image v-model="dataform.businessLicenseUrl">
                            <div slot="title">
                                <span>{{$t('company.businessLicense')}}</span>
                                <span style="font-size: 12px;padding-left: 10px">({{$t('company.businessLicenseRequired')}})</span>
                            </div>
                        </zk-upload-image>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="">
                        <zk-upload-image v-model="dataform.qualificationCertificateUrl" :title="$t('company.qualificationCertificate')"></zk-upload-image>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="">
                        <zk-upload-image v-model="dataform.logoUrl" :title="$t('company.companyLogo')"></zk-upload-image>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="title center">
                <el-button type="primary" @click="formSubmit">{{$t('company.confirm')}}</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: 'company_info',
        path: '/main/company/info',
        data() {
            return {
                dataform: {},
                dialogVisible: false,
                rules: {
                    companyName: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['dataform'].validate()
                if (valid) {
                    try {
                        const resp = await this.$http.post('/company/info/', this.dataform)
                        if (resp.data.status !== 200) {

                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('company.success'))
                            this.getData()
                        }
                    } catch (e) {
                        this.$message.error(e)
                    }
                }
            },
            async getData() {
                try {
                    const resp = await this.$http.get(`/company/info/`)
                    console.log(resp)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.dataform = resp.data.data || {}
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getData()
        },
    }
</script>

<style scoped>
    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 15px;
    }

    .center {
        text-align: center;
    }
</style>
