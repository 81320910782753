import formatter from '@/components/formatter'
const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: ''},
            {title: '最后通讯时间', field: 'last_seen', width: ''},
            {title: '电量', field: 'battery', width: '',formatter: formatter.batteryFormatter},
            {title: '锁状态', field: 'lock_state', width: '100',formatter: formatter.formatlockstate},
            {title: '常开模式', field: 'hold_open', width: '',formatter: formatter.holdOpenFormatter},
            {title: '运输状态', field: 'work_status', width: '150',formatter: formatter.workstatusFormatter},

        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
