export default {
//   app、company、transport、device、system 已按首字母排序，并使用驼峰命名法
    app: {
        home: 'Home',
        logout: 'Logout',
        passwd: 'Change Password',
        title: 'TMS Admin',
    },
    company: {
        baseInfo: 'Basic Enterprise Information',
        businessLicense:"business license",
        businessLicenseRequired:'Business license is mandatory',
        businessScope: 'business Scope',
        cancel:'cancel',
        cods: 'Social uniform credit code',
        companyIsChain: 'company Is Chain',
        companyLogo:'company logo',
        companyName: 'company Name',
        companyType: 'company Type',
        confirm:'confirm',
        contactInformation:"contactin formation",
        contactName:"contact Name",
        contactPhone:'contact Phone',
        email:'email',
        foundedDate:'date of establishment',
        issueAuthority:"issue Authority",
        legalRepresentative:'legal Representative',
        no:'No',
        optionDate:'Select Date',
        phone:'phone',
        pictorialInformation:'pictorial Information',
        qualificationCertificate:'qualification certificate',
        registeredCapital:'registered Capital',
        registrationAddress:'registered address',
        roadTransportOperationPermit:'road Transport Operation Permit',
        yes:'Yes',

        //部门
        addDepartment:'The new department',
        delete:"delete",
        departmentCode:'DeptCode',
        departmentName:'DeptName',
        hint:'Are you sure you want to delete the data again？',
        placeholder:"Select a superior department",
        searchDepartment:'Search department',
        superiorDepartment:'superior department',
        titlehDepartment:'Add/Modify',

        //角色
        //用户
        activated:'activated',
        ccEmails:'Sent to your email',
        dataPermission:"data permission",
        deoption:'select department',
        dep:'department',
        departmentIds:'department',
        depPlaceHolder:"please select",
        description:'Role description',
        endDate:"end date",
        firstName:'User name',
        inPlaceHolder:"please enter",
        lastModifiedDate:"last Modified Date",
        login:'login name',
        modification :"modification",
        name:'Role name',
        operation :"operation",
        passwd:'password',
        permission:"set permission",
        reset :"reset",
        resetPasswd:'reset password',
        role:"role name",
        roleAdd:'add',
        roleIds:'Role name',
        roleMenu:'Role menu',
        rolePlaceHolder:'name',
        roleSearch:'search',
        roleSetting:'role configuration',
        roleTitle:'role list',
        save:'save',
        serialNumber:"serial number",
        startDate:"start date",
        textPlaceHolder:"Multiple email addresses are supported, separated by semicolons (;)",
        to:'to',
        type:"user type ",
        userList:"User list",

        //报警
        1:'consigner',
        2:'consignee',
        3:'consignee and consigner',
        alarmList:"Alarm Contact list  ",
        alarmName:'name',
        delSuccess:'successfully delete',
        departmentId:'department',
        emailEndTime:'email receiving time',
        emailStartTime:"email Start Time",
        emailText:'If you do not fill in the email address or fill in the email address incorrectly, you cannot receive email push ',
        regionId:'area',
        required:'required fields',
        resetSuccess:'Reset the success',
        rulepass:'Please enter a password',
        rulepasstext:'The length is 8 to 15 characters',
        rulesname:'Please enter a name',
        smsEndTime:'SMS receiving time',
        smsStartTime:"sms Start Time",
        smstext:'If the SMS service is not purchased, the espace ems cannot receive SMS push',
        success:'save successfully',

    },
    transport: {
        //通用
        add:'add',
        cancel:'cancel',
        comment:'remark',
        confirm:'confirm',
        delete:"delete",
        delSuccess:'successfully delete',
        depPlaceHolder:"please select",
        endDate:"end date",
        hint:'Are you sure you want to delete the data again？',
        inPlaceHolder:"please enter",
        modification :"modification",
        operation :"operation",
        placeHolderHeight:'height',
        placeHolderLength:'length',
        placeHolderWidth:'width',
        reset :"reset",
        resetPasswords :"reset password",
        search: 'search',
        serialNumber:"serial number",
        startDate:"start date",
        success:'save successfully',
        titleh:'Add/Modify',
        to:'to',
        modeltitle:'Add Transport',

        //货物管理
        goodsCode :"goods Code",
        goodsList: 'goods List',
        goodsName:"goods Name",
        goodsType :"goods Type",
        packageType :"package Type",
        price :"price",
        specification :"specification",
        unit :"unit",
        unitConversion :"unit Conversion",
        unitVolume :"unit Volume",
        unitWeight :"unit Weight",
        wholeUnit :"whole Unit",
        wholeVolume :"whole Volume",
        wholeWeight :"whole Weight",

        //周转箱
        basketsList:'container List',
        containerCode :"containe rCode",
        containerName:"container Name",
        height :"outer height",
        innerHeight :"inner Height",
        innerLength :"inner Length",
        innerVolume :"inner Volume",
        innerWidth :"inner Width",
        length :"outer length",
        maxLoad :"max Load",
        netWeight :"net Weight",
        volume :"outer volume",
        width :"outer width",

        //客户管理
        accountInformation:'account Information',
        associatedElectronicFence:'Associated electronic fence',
        bankAccountName :"bank Account Name",
        bankAccountNumber :"bank Account Number",
        bankBranch :"bank Branch",
        bankName :"bank Name",
        basicInformation:'basic Information',
        billingInformation:"billing Information",
        certificateUrl :"Id photo Address",
        cityId :"city",
        clientList :"Client list",
        cods :"Unify social credit codes",
        contactName :"contact Name",
        customerCode :"customer Code",
        customerName :"customer Name",
        electricFenceId :"electric FenceId",
        email :"email",
        idPhoto:'Id photo',
        invoiceTitle :"invoice Title",
        phone :"phone",
        taxAddress :"tax Address",
        taxBankName :"tax Bank Name",
        taxBankNumber :"tax Bank Number",
        taxNumber :"tax Number",
        taxPhone :"tax Phone",

        //承运商
        carrierCode:"carrier Code",
        carrierList:"carrier List",
        carrierName:"carrier Name",
        roadTransportOperationPermit:"road Transport Operation Permit",
        xukezhengUrl:'Uploading business License',
        yingyezgizhaoUrl:'Upload business license',

        //车辆档案
        brand:"Vehicle brand",
        classification:"Vehicle energy type",
        commonUse:'The driver of the commonly used',
        dbasicInformation:"basic Information",
        driversId:"The driver of the commonly used",
        energyType:"energy Type",
        engineNumber:"engine number",
        insuranceCompany:"insurance Company",
        insuranceInformation:'insurance Information',
        insuranceNumber:"insurance Number",
        issueAuthority:"licence-issuing authority",
        issueDate:"date of issue",
        licenceExpiryDate:"Date of validity of driving permit",
        managementInformation:'management Information',
        owner:"owner",
        plateColor:"The license plate color",
        plateNumber:"license plate number",
        registerDate:"register Date",
        roadTransportPermit:"Road transport Certificate no",
        shuoyuan:'Regular delivery man',
        syuan:"Regular delivery man",
        useCharacter:"use Character",
        vehicleList:"vehicle List",
        vih:"vehicle identification number",
        weight:"weight",
        xingshiagainstUrl:'Upload photos of driving license turning pages',
        xingshijustUrl:'Upload photos of driving permit homepage',
        yunshuUrl:'Upload road transport certificate',

        //司机档案
        age:"age",
        anchoredObject:"The affiliated object",
        classAllowed:'Quasi driving type',
        congYeUrl:'Upload professional qualification certificate',
        Department:"Department",
        driverList:'driver list',
        driverName:"driver Name",
        drivingAge:"driving years",
        drivingLicense:"Date of commencement of driving permit",
        endLicenseExpiration:"Expiration date of driving license",
        gender:"gender",
        identityCardNationalemblemUrl:'Upload id card national emblem page',
        identitycardUrl:'Upload id card profile picture page',
        jiashifuyeUrl:'Upload the secondary page of driving license',
        jiashizhuyeUrl:'Upload driving license homepage',
        man:'man',
        nationalId:"identity card ",
        qualificationCertificate:'QualificationCode',
        weChat:'weChat',
        woman:'woman',

        //电子围栏
        address:"address",
        area:"area",
        areanumber:"area number",
        areatype:"area type",
        circle:'circle',
        consignType:"consign Type",
        desc:"province",
        districtId:'district',
        electricfence:'electric fence',
        electricFenceList:"electric fence List",
        latitude:"latitude",
        longitude:"longitude",
        mapLabel:'Mark it on a map',
        polygon:'polygon',
        radius:"radius",
        redraw:'redraw',
        searchAddress:'Please enter search address',
        shareMode:"shareMode",
        square:'square',
        type:"type",

        //车辆类型管理
        code:"Vehicle Type Code",
        costetting:'The cost of setting',
        costettingtext:'After this parameter is set, it can be used for intelligent scheduling. If Cost Optimal is selected, cost estimation can be performed',
        defaultAvailableQuantity:"Default available quantity",
        freightRates:'Freight rates',
        maxVolumn:"The rated load product",
        minLoad:"Minimum load mass",
        minVolumn:"Minimum load product",
        name:"Vehicle Type Name",
        piece:'piece',
        Spellpointsrate:'Spell points rate',
        ton:'ton',
        typeGoodsAllowed:'Type of goods allowed',
        unitary:'unitary',
        vehicleTypeList:'vehicle type list',
        vMaxLoad:"Rated the quality of",

        //门店
        Areaname:"Area name",
        businesstype:"Area code service type",
        cargo:"cargo",
        carrier:"The carrier company",
        customer:"customer",
        deliveryEmployeeList:'Delivery man management',
        Departuretimerequired:"Ask for departure time",
        disList:'Distance aging list',
        distance:"distance(km)",
        end:"destination",
        fenceName:"fence name",
        fenceNumber:"fence number",
        historicalLatitude:"historical latitude",
        historicalLocation:"historical location",
        historicalLongitude:"historical logitude",
        line:"line",
        maximumshipping:"Maximum shipping time required(h)",
        receivingarea:"Receiving area",
        ReportLongPerson:"Reportiong person",
        ReportLongTime:"Reportiong time",
        requestdeliverytime:"Required delivery time",
        Shippingarea:"Shipping area",
        shopList:'Store location calibration list',
        Slicecode:"Area code",
        source:"source",
        startingpoint:"origin",
        status:"status",
        vehicleId:"The binding of vehicle",

        //订单
        bizTypeId:"business type",
        cargoId:"cargo",
        consigneeRegionId:"Receiving area",
        consignerRegionId:"Shipping area",
        customerId:"customer",
        departmentId:"department",
        required:'required fields',
        requiredReceiptTime:"Request delivery time",
        requiredShippingTime:"Ask for departure time",
        routeId:"line",
        shippingType:"mode of distribution",
    },
    device: {
        add:'add',
        cancel:'cancel',
        chart:'chart',
        confirm:'confirm',
        danger:"alarm",
        delete:"delete",
        delSuccess:'successfully delete',
        depPlaceHolder:"please select",
        endDate:"end date",
        hint:'Are you sure you want to delete the data again？',
        inPlaceHolder:"please enter",
        log:'log',
        modification :"modification",
        operation :"operation",
        reset :"reset",
        search: 'search',
        serialNumber:"serial number",
        startDate:"start date",
        success:'save successfully ',
        titleh:'Add/Modify',
        to:'to',

        // 设备绑定
        comment:"remark",
        devlist:"The equipment list",
        name:"name",
        sn:"device number",
        type:"device type",

        //蓝牙设备
        average:'average',
        battery:"battery",
        bluetoothlist:'Bluetooth Device List',
        box_number:"Insulation carton no",
        device_id:"device Id",
        device_name:"device name",
        last_seen:"Last acquisition time",
        logList:'Log list',
        loralist:'LORA equipment list  ',
        maximum:'maximum',
        minimum:'minimum',
        report_interval:"Thermometer recording interval",
        searchdata:'No data was found',
        sourceChart:'Temperature curve',
        temperature:"temperature",
        temperature_adjust:"temperature correction ",
        temperature_max:"maximum temperature",
        temperature_min:"The bottom temperature",
        temperatureSetting:'Modifying thermometer Configurations',
        timestamp:'Last acquisition time',
        version:"software version",
    },

    system: {
        0:'normal',
        1:'block up',
        add:'add',
        button:'button',
        cancel:'cancel',
        catalogue:'catalogue',
        confirm:'confirm',
        createdDate:'created Date',
        delete:"delete",
        delSuccess:'successfully delete',
        depPlaceHolder:"please select",
        dictLabel:'The dictionary labels',
        dictList:'The dictionary list',
        dictName:'Name of the dictionary',
        dictSort:'Dictionary order',
        dictType:'Tpye of the dictionary',
        dictTypeList:'Dictionary type list',
        dictValue:'The dictionary keys',
        endDate:"end date",
        hidden:'show',
        hint:'Are you sure you want to delete the data again？',
        icon:'icon',
        inPlaceHolder:"please enter",
        lastModifiedDate:'date changed',
        menuName:'name',
        menus:'menu',
        menuSort:'Sort',
        modification :"modification",
        munelist:'Menu list',
        no:'No',
        operation :"operation",
        parentMenuId:'The superior category',
        permission:'Permission to identify',
        remark:'remark',
        required:'required fields',
        reset :"reset",
        routes:'All pages',
        search: 'search',
        serialNumber:'serial number',
        smenu:'Select the parent category',
        startDate:"start date",
        status:'status',
        success:'save successfully ',
        titleh:'Add/Modify',
        to:'to',
        type:'The menu type',
        url:'The component path',
        yes:'Yes',
    },
//////////////////////////////////////////////////////////////////////////////////////////////////
//  以下已排序
    menu: {
        dashboard: 'Dashboard',
        alarm: 'Alarm Contact Management',
        buckets:'basket management',
        carrier:'carrier management',
        company: 'Company',
        company_department: 'division management ',
        company_form: 'Company Form',
        company_index: 'Company List',
        company_info: 'enterprise Information',
        company_overdue: 'Company Overdue',
        company_role: 'role management',
        company_user: 'user management',
        'consign-entities': 'Consignee/consignor management',
        customer: 'customer management',
        'delivery-area': 'delivery area',
        'delivery-employee': 'delivery employee',
        device: 'device management',
        'device-apbind': 'Equipment binding',
        'device-bluetooth': 'device bluetooth',
        'device-logi':'device logi',
        'device-lora': 'device lora',
        dict: 'dictionary management',
        dictType: 'dictionary type',
        dis: 'Distance aging management',
        driver: ' driver files',
        'electric-fence': 'electric fence',
        goods: 'goods management',
        menu: 'menu management',
        oplog: 'Option Log',
        order: 'order management',
        routes: 'Routing management',
        shop: 'Store location calibration',
        system: 'system setting',
        transport: 'transportation management',
        vehicle: 'vehicle files',
        'vehicle-type': 'vehicle type',
    },
    dashboard: {
        userCount: 'user number',
        deptCount: 'Department number',
        deviceCount: 'device number',
        alarmCount: 'alarm number',
        sourceChart: 'Resource occupancy chart',
        sourcePieChart: 'resource map',
    },
    log:{
        content:'content',
        depPlaceHolder:"please select",
        logList:'system log',
        screen:'screen',
        time:'time',
        type:'type',
    },
    permission: {
        action: 'action',
        alarm: 'Alarm contact',
        all: 'all',
        buckets:'buckets',
        carrier:'carrier',
        company: 'company',
        'consign-entities': 'consign entities',
        customer: 'customer',
        data: 'data',
        delete: 'delete',
        'delivery-area': 'delivery area',
        'delivery-employee': 'delivery employee',
        department: 'department',
        dept:'Department',
        device: 'device management',
        'device-apbind': 'Equipment binding',
        'device-bluetooth': 'device bluetooth',
        'device-lora': 'device lora',
        dict: 'dictionary management',
        dictType: 'dictionary type',
        dis: 'Distance aging management',
        driver: 'driver',
        'electric-fence': 'electric fence',
        get: 'query',
        goods: 'goods',
        menu: 'menu management',
        order: 'order management',
        post: 'new construction',
        put: 'modification',
        role: 'role',
        self:'own',
        shop: 'Store location calibration',
        system: 'system setting',
        user: 'user',
        vehicle: 'vehicle',
        'vehicle-type': 'vehicle type',
    },
    skin: {
        Blue: 'Blue',
        Green: 'Green'
    }
}
/*
export default {
    app: {
        title: 'TMS Admin',
        home: 'Home',
        passwd: 'Change Password',
        logout: 'Logout',
    },
    menu: {
        dashboard: 'Dashboard',
        company: 'Company',
        company_form: 'Company Form',
        company_index: 'Company List',
        company_overdue: 'Company Overdue',
        oplog: 'Option Log',
        company_info: 'enterprise Information',
        company_department: 'division management ',
        company_role: 'role management',
        company_user: 'user management',
        alarm: 'Alarm Contact Management',
        'consign-entities': 'Consignee/consignor management',
        transport: 'transportation management',
        goods: 'goods management',
        buckets:'basket management',
        customer: 'customer management',
        carrier:'carrier management',
        vehicle: 'vehicle files',
        driver: ' driver files',
        'electric-fence': 'electric fence',
        'vehicle-type': 'vehicle type',
        shop: 'Store location calibration',
        dis: 'Distance aging management',
        'delivery-employee': 'delivery employee',
        'delivery-area': 'delivery area',
        order: 'order management',
        system: 'system setting',
        dictType: 'dictionary type',
        dict: 'dictionary management',
        menu: 'menu management',
        device: 'device management',
        'device-apbind': 'Equipment binding',
        'device-bluetooth': 'device bluetooth',
        'device-lora': 'device lora',
        'device-logi':'device logi',
        routes: 'Routing management'

    },
    dashboard: {
        userCount: 'user number',
        deptCount: 'Department number',
        deviceCount: 'device number',
        alarmCount: 'alarm number',
        sourceChart: 'Resource occupancy chart',
        sourcePieChart: 'resource map',
    },
    company: {
        baseInfo: 'Basic Enterprise Information',
        companyName: 'company Name',
        companyType: 'company Type',
        companyIsChain: 'company Is Chain',
        businessScope: 'business Scope',
        roadTransportOperationPermit:'road Transport Operation Permit',
        cods: 'Social uniform credit code',
        registrationAddress:'registered address',
        phone:'phone',
        legalRepresentative:'legal Representative',
        foundedDate:'date of establishment',
        optionDate:'Select Date',
        issueAuthority:"issue Authority",
        registeredCapital:'registered Capital',
        contactInformation:"contactin formation",
        contactName:"contact Name",
        contactPhone:'contact Phone',
        email:'email',
        pictorialInformation:'pictorial Information',
        businessLicense:"business license",
        businessLicenseRequired:'Business license is mandatory',
        qualificationCertificate:'qualification certificate',
        companyLogo:'company logo',
        confirm:'confirm',
        cancel:'cancel',
        yes:'Yes',
        no:'No',
        //部门
        addDepartment:'The new department',
        searchDepartment:'Search department',
        titlehDepartment:'Add/Modify',
        superiorDepartment:'superior department',
        placeholder:"Select a superior department",
        departmentName:'DeptName',
        departmentCode:'DeptCode',
        hint:'Are you sure you want to delete the data again？',
        delete:"delete",
        //角色
        roleTitle:'role list',
        rolePlaceHolder:'name',
        roleSearch:'search',
        roleAdd:'add',
        serialNumber:"serial number",
        name:'Role name',
        description:'Role description',
        operation :"operation",
        modification :"modification",
        permission:"set permission",
        roleSetting:'role configuration',
        roleMenu:'Role menu',
        save:'save',
        dataPermission:"data permission",
        //用户
        userList:"User list",
        to:'to',
        startDate:"start date",
        endDate:"end date",
        reset :"reset",
        resetPasswd:'reset password',
        passwd:'password',
        firstName:'User name',
        login:'login name',
        activated:'activated',
        roleIds:'Role name',
        departmentIds:'department',
        ccEmails:'Sent to your email',
        deoption:'select department',
        depPlaceHolder:"please select",
        inPlaceHolder:"please enter",
        textPlaceHolder:"Multiple email addresses are supported, separated by semicolons (;)",
        type:"user type ",
        role:"role name",
        dep:'department',
        lastModifiedDate:"last Modified Date",
        //报警
        alarmName:'name',
        emailStartTime:"email Start Time",
        smsStartTime:"sms Start Time",
        departmentId:'department',
        smsEndTime:'SMS receiving time',
        emailEndTime:'email receiving time',
        smstext:'If the SMS service is not purchased, the espace ems cannot receive SMS push',
        emailText:'If you do not fill in the email address or fill in the email address incorrectly, you cannot receive email push ',
        alarmList:"Alarm Contact list  ",
        1:'consigner',
        2:'consignee',
        3:'consignee and consigner',
        regionId:'area',
        required:'required fields',
        success:'save successfully',
        delSuccess:'successfully delete',
        rulesname:'Please enter a name',
        rulepass:'Please enter a password',
        rulepasstext:'The length is 8 to 15 characters',
        resetSuccess:'Reset the success'
    },
    transport: {
        //通用
        success:'save successfully',
        delSuccess:'successfully delete',
        to:'to',
        search: 'search',
        startDate:"start date",
        endDate:"end date",
        reset :"reset",
        serialNumber:"serial number",
        operation :"operation",
        modification :"modification",
        resetPasswords :"reset password",
        comment:'remark',
        add:'add',
        confirm:'confirm',
        cancel:'cancel',
        hint:'Are you sure you want to delete the data again？',
        delete:"delete",
        titleh:'Add/Modify',
        depPlaceHolder:"please select",
        inPlaceHolder:"please enter",
        placeHolderHeight:'height',
        placeHolderLength:'length',
        placeHolderWidth:'width',
        //货物管理
        goodsList: 'goods List',
        goodsName:"goods Name",
        goodsCode :"goods Code",
        goodsType :"goods Type",
        unit :"unit",
        wholeUnit :"whole Unit",
        unitConversion :"unit Conversion",
        unitWeight :"unit Weight",
        wholeWeight :"whole Weight",
        unitVolume :"unit Volume",
        wholeVolume :"whole Volume",
        price :"price",
        packageType :"package Type",
        specification :"specification",
        //周转箱
        basketsList:'container List',
        containerName:"container Name",
        containerCode :"containe rCode",
        length :"outer length",
        width :"outer width",
        height :"outer height",
        innerLength :"inner Length",
        innerWidth :"inner Width",
        innerHeight :"inner Height",
        volume :"outer volume",
        innerVolume :"inner Volume",
        netWeight :"net Weight",
        maxLoad :"max Load",
        //客户管理
        clientList :"Client list",
        customerName :"customer Name",
        customerCode :"customer Code",
        cods :"Unify social credit codes",
        cityId :"city",
        email :"email",
        contactName :"contact Name",
        phone :"phone",
        bankAccountName :"bank Account Name",
        bankAccountNumber :"bank Account Number",
        bankName :"bank Name",
        bankBranch :"bank Branch",
        invoiceTitle :"invoice Title",
        taxNumber :"tax Number",
        taxAddress :"tax Address",
        taxBankName :"tax Bank Name",
        taxBankNumber :"tax Bank Number",
        taxPhone :"tax Phone",
        electricFenceId :"electric FenceId",
        certificateUrl :"Id photo Address",
        idPhoto:'Id photo',
        basicInformation:'basic Information',
        associatedElectronicFence:'Associated electronic fence',
        accountInformation:'account Information',
        billingInformation:"billing Information",
        //承运商
        carrierList:"carrier List",
        carrierName:"carrier Name",
        carrierCode:"carrier Code",
        roadTransportOperationPermit:"road Transport Operation Permit",
        yingyezgizhaoUrl:'Upload business license',
        xukezhengUrl:'Uploading business License',
        //车辆档案
        driversId:"The driver of the commonly used",
        managementInformation:'management Information',
        commonUse:'The driver of the commonly used',
        shuoyuan:'Regular delivery man',
        syuan:"Regular delivery man",
        dbasicInformation:"basic Information",
        insuranceInformation:'insurance Information',
        xingshijustUrl:'Upload photos of driving permit homepage',
        xingshiagainstUrl:'Upload photos of driving license turning pages',
        yunshuUrl:'Upload road transport certificate',
        vehicleList:"vehicle List",
        brand:"Vehicle brand",
        vih:"vehicle identification number",
        plateNumber:"license plate number",
        plateColor:"The license plate color",
        engineNumber:"engine number",
        registerDate:"register Date",
        issueDate:"date of issue",
        licenceExpiryDate:"Date of validity of driving permit",
        issueAuthority:"licence-issuing authority",
        weight:"weight",
        classification:"Vehicle energy type",
        roadTransportPermit:"Road transport Certificate no",
        owner:"owner",
        useCharacter:"use Character",
        energyType:"energy Type",
        insuranceNumber:"insurance Number",
        insuranceCompany:"insurance Company",
        //司机档案
        driverList:'driver list',
        identitycardUrl:'Upload id card profile picture page',
        identityCardNationalemblemUrl:'Upload id card national emblem page',
        jiashizhuyeUrl:'Upload driving license homepage',
        jiashifuyeUrl:'Upload the secondary page of driving license',
        congYeUrl:'Upload professional qualification certificate',
        driverName:"driver Name",
        gender:"gender",
        nationalId:"identity card ",
        age:"age",
        weChat:'weChat',
        classAllowed:'Quasi driving type',
        qualificationCertificate:'QualificationCode',
        // "contact number":"联系电话",
        drivingAge:"driving years",
        Department:"Department",
        anchoredObject:"The affiliated object",
        drivingLicense:"Date of commencement of driving permit",
        endLicenseExpiration:"Expiration date of driving license",
        man:'man',
        woman:'woman',
        //电子围栏
        electricfence:'electric fence',
        electricFenceList:"electric fence List",
        area:"area",
        areanumber:"area number",
        areatype:"area type",
        districtId:'district',
        consignType:"consign Type",
        type:"type",
        shareMode:"shareMode",
        radius:"radius",
        latitude:"latitude",
        longitude:"longitude",
        address:"address",
        desc:"province",
        redraw:'redraw',
        searchAddress:'Please enter search address',
        mapLabel:'Mark it on a map',
        polygon:'polygon',
        square:'square',
        circle:'circle',
        //车辆类型管理
        vehicleTypeList:'vehicle type list',
        name:"Vehicle Type Name",
        code:"Vehicle Type Code",
        defaultAvailableQuantity:"Default available quantity",
        vMaxLoad:"Rated the quality of",
        minLoad:"Minimum load mass",
        maxVolumn:"The rated load product",
        minVolumn:"Minimum load product",
        typeGoodsAllowed:'Type of goods allowed',
        costetting:'The cost of setting',
        costettingtext:'After this parameter is set, it can be used for intelligent scheduling. If Cost Optimal is selected, cost estimation can be performed',
        freightRates:'Freight rates',
        Spellpointsrate:'Spell points rate',
        ton:'ton',
        piece:'piece',
        unitary:'unitary',
        //门店
        shopList:'Store location calibration list',
        status:"status",
        ReportLongPerson:"Reportiong person",
        ReportLongTime:"Reportiong time",
        source:"source",
        fenceName:"fence name",
        fenceNumber:"fence number",
        historicalLocation:"historical location",
        historicalLongitude:"historical logitude",
        historicalLatitude:"historical latitude",
        //
        disList:'Distance aging list',
        startingpoint:"origin",
        end:"destination",
        carrier:"The carrier company",
        distance:"distance(km)",
        maximumshipping:"Maximum shipping time required(h)",
        //
        // "name":"姓名",
        // "Phone number":"手机号",
        // "address":"住居地址",
        deliveryEmployeeList:'Delivery man management',
        vehicleId:"The binding of vehicle",
        //
        Areaname:"Area name",
        Slicecode:"Area code",
        //
        businesstype:"Area code service type",
        customer:"customer",
        receivingarea:"Receiving area",
        Shippingarea:"Shipping area",
        Departuretimerequired:"Ask for departure time",
        requestdeliverytime:"Required delivery time",
        line:"line",
        cargo:"cargo",
        //d订单
        bizTypeId:"business type",
        customerId:"customer",
        departmentId:"department",
        consignerRegionId:"Shipping area",
        consigneeRegionId:"Receiving area",
        requiredShippingTime:"Ask for departure time",
        requiredReceiptTime:"Request delivery time",
        cargoId:"cargo",
        routeId:"line",
        shippingType:"mode of distribution",
        required:'required fields'
    },
    device: {
        danger:"alarm",
        depPlaceHolder:"please select",
        inPlaceHolder:"please enter",
        success:'save successfully ',
        delSuccess:'successfully delete',
        to:'to',
        search: 'search',
        startDate:"start date",
        endDate:"end date",
        reset :"reset",
        operation :"operation",
        modification :"modification",
        add:'add',
        confirm:'confirm',
        cancel:'cancel',
        hint:'Are you sure you want to delete the data again？',
        delete:"delete",
        titleh:'Add/Modify',
        log:'log',
        chart:'chart',
        serialNumber:"serial number",
        // 设备绑定
        devlist:"The equipment list",
        name:"name",
        sn:"device number",
        type:"device type",
        comment:"remark",
        //蓝牙设备
        bluetoothlist:'Bluetooth Device List',
        temperatureSetting:'Modifying thermometer Configurations',
        report_interval:"Thermometer recording interval",
        temperature_max:"maximum temperature",
        temperature_min:"The bottom temperature",
        temperature_adjust:"temperature correction ",
        box_number:"Insulation carton no",
        device_name:"device name",
        version:"software version",
        device_id:"device Id",
        battery:"battery",
        temperature:"temperature",
        last_seen:"Last acquisition time",
        logList:'Log list',
        timestamp:'Last acquisition time',
        loralist:'LORA equipment list  ',
        sourceChart:'Temperature curve',
        maximum:'maximum',
        minimum:'minimum',
        average:'average',
        searchdata:'No data was found'
    },
    system: {
        depPlaceHolder:"please select",
        inPlaceHolder:"please enter",
        success:'save successfully ',
        delSuccess:'successfully delete',
        dictList:'The dictionary list',
        to:'to',
        search: 'search',
        startDate:"start date",
        endDate:"end date",
        reset :"reset",
        operation :"operation",
        modification :"modification",
        add:'add',
        confirm:'confirm',
        cancel:'cancel',
        hint:'Are you sure you want to delete the data again？',
        delete:"delete",
        titleh:'Add/Modify',
        dictName:'Name of the dictionary',
        dictType:'Tpye of the dictionary',
        status:'status',
        remark:'remark',
        createdDate:'created Date',
        dictLabel:'The dictionary labels',
        dictValue:'The dictionary keys',
        dictSort:'Dictionary order',
        dictTypeList:'Dictionary type list',
        1:'block up',
        0:'normal',
        required:'required fields',
        munelist:'Menu list',
        menuName:'name',
        icon:'icon',
        menuSort:'Sort',
        hidden:'show',
        permission:'Permission to identify',
        url:'The component path',
        lastModifiedDate:'date changed',
        yes:'Yes',
        no:'No',
        smenu:'Select the parent category',
        parentMenuId:'The superior category',
        type:'The menu type',
        catalogue:'catalogue',
        menus:'menu',
        button:'button',
        routes:'All pages',
        serialNumber:'serial number'
    },
    log:{
        depPlaceHolder:"please select",
        logList:'system log',
        screen:'screen',
        time:'time',
        type:'type',
        content:'content'
    },
    permission: {
        get: 'query',
        post: 'new construction',
        put: 'modification',
        delete: 'delete',
        all: 'all',
        dept:'Department',
        self:'own',
        action: 'action',
        data: 'data',
        company: 'company',
        department: 'department',
        role: 'role',
        user: 'user',
        alarm: 'Alarm contact',
        'consign-entities': 'consign entities',
        goods: 'goods',
        buckets:'buckets',
        customer: 'customer',
        carrier:'carrier',
        vehicle: 'vehicle',
        driver: 'driver',
        'electric-fence': 'electric fence',
        'vehicle-type': 'vehicle type',
        shop: 'Store location calibration',
        dis: 'Distance aging management',
        'delivery-employee': 'delivery employee',
        'delivery-area': 'delivery area',
        order: 'order management',
        system: 'system setting',
        dictType: 'dictionary type',
        dict: 'dictionary management',
        menu: 'menu management',
        device: 'device management',
        'device-apbind': 'Equipment binding',
        'device-bluetooth': 'device bluetooth',
        'device-lora': 'device lora',
    },
    skin: {
        Blue: 'Blue',
        Green: 'Green'
    }
}
 */