const data = {
    formdata: [
        {

            "name": "carrierName",
            "type": "input",
            "title": "承运商名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "carrierCode",
            "type": "input",
            "title": "承运商代码",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "cods",
            "type": "input",
            "title": "统一社会信用代码",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "roadTransportOperationPermit",
            "type": "input",
            "title": "道路运输经营许可证",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "cityId",
            "type": "number",
            "title": "所在城市id",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "email",
            "type": "input",
            "title": "联系邮箱",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "contactName",
            "type": "input",
            "title": "联系人姓名",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "phone",
            "type": "input",
            "title": "联系人电话",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "bankAccountName",
            "type": "input",
            "title": "银行账户名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "bankAccountNumber",
            "type": "input",
            "title": "银行账户号码",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "bankName",
            "type": "input",
            "title": "银行名称",
            required:false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "bankBranch",
            "type": "input",
            "title": "银行分支行名称",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "invoiceTitle",
            "type": "input",
            "title": "发票抬头",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "taxNumber",
            "type": "input",
            "title": "税号",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "taxAddress",
            "type": "input",
            "title": "税号注册的地址",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "taxBankName",
            "type": "input",
            "title": "税号开户行",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "taxBankNumber",
            "type": "input",
            "title": "税号开户行银行账号",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "taxPhone",
            "type": "input",
            "title": "税号注册时的联系电话",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        // {
        //     "name": "company",
        //     "type": "select",
        //     "title": "公司",
        //     required: false,
        //     placeholder: "请选择",
        //     value: "",
        //     key: "id",
        //     disabled: false,
        //     label:"companyName",
        //     options:[
        //     ]
        // },
    ],
    table: {
        columns: [
            {title: '承运商名称', field: 'carrierName', width: '120'},
            {title: '承运商代码', field: 'carrierCode', width: '120'},
            {title: '统一社会信用代码', field: 'cods', width: '200'},
            {title: '道路运输经营许可证', field: 'roadTransportOperationPermit', width: '250'},
            {title: '所在城市', field: 'cityId', width: '120'},
            {title: '联系邮箱', field: 'email', width: '120'},
            {title: '联系人姓名', field: 'contactName', width: '120'},
            {title: '联系人电话', field: 'phone', width: '120'},
            {title: '银行账户名称', field: 'bankAccountName', width: '160'},
            {title: '银行账户号码', field: 'bankAccountNumber', width: '180'},
            {title: '银行名称', field: 'bankBranch', width: '120'},
            {title: '发票抬头', field: 'invoiceTitle', width: '120'},
            {title: '税号', field: 'taxNumber', width: '120'},
            {title: '税号注册的地址', field: 'taxAddress', width: '180'},
            {title: '税号开户行', field: 'taxBankName', width: '140'},
            {title: '税号开户行银行账号', field: 'taxBankNumber', width: '180'},
            {title: '税号注册时的联系电话', field: 'taxPhone', width: '180'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
