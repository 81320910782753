<template>
    <div>
        <el-form ref="form" :model="form" label-position="top" :rules="rules">
            <el-form-item label="工作状态" prop="workStatus">
                <el-switch
                        v-model="form.workStatus"
                        active-value="1"
                        inactive-value="0"
                        placeholder=""
                        @change="closeMotionSensor"
                />
            </el-form-item>
            <el-divider content-position="left"></el-divider>
            <el-form-item :label="$t('device.cabotage.title')" prop="period">
                <el-radio-group v-model="form.period" @change="cabotageChange">
                    <el-radio :label="1">{{ $t('device.cabotage.day') }}</el-radio>
                    <el-radio :label="2">{{ $t('device.cabotage.week') }}</el-radio>
                    <el-radio :label="3">{{ $t('device.cabotage.twoWeek') }}</el-radio>
                    <el-radio :label="4">{{ $t('device.cabotage.month') }}</el-radio>
                    <el-radio :label="5">{{ $t('device.cabotage.threeMonth') }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-card class="box-card">
                <el-divider content-position="left">{{ $t('device.batterySetting.title') }}</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item :label="$t('device.batterySetting.alarm')" prop="batteryMin">
                                <el-input v-model.number="form.batteryMin">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">{{ $t('device.networkSetting.title') }}</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="20">
                            <el-form-item :label="$t('device.networkSetting.searchTime')" prop="searchTime">
                                <el-input v-model.number="form.sounet" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                                当设备长时间处于无网络的状态时,会自动应用这个配置
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">温度配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="温度最小值" prop="tempMin">
                                <el-input v-model.number="form.tempMin" placeholder="">
                                    <template slot="append">{{tempUnit}}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="温度最大值" prop="tempMax">
                                <el-input v-model.number="form.tempMax" placeholder="">
                                    <template slot="append">{{tempUnit}}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="8">
                            <el-form-item label="温度校准" prop="tempCalibration">
                                <el-input v-model.number="form.tempCalibration" placeholder="">
                                    <template slot="append">{{tempUnit}}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="温度记录间隔" prop="tempInterval">
                                <el-input v-model.number="form.tempInterval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="温度单位" prop="tempUnit">
                                <el-radio-group v-model="form.tempUnit" @change="changeTempUnit">
                                    <el-radio :label="0">摄氏度</el-radio>
                                    <el-radio :label="1">华氏度</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">湿度配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="湿度最小值" prop="humMin">
                                <el-input v-model.number="form.humMin" placeholder="">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="湿度最大值" prop="humMax">
                                <el-input v-model.number="form.humMax" placeholder="">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="湿度校准" prop="humCalibration">
                                <el-input v-model.number="form.humCalibration" placeholder="">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="湿度记录间隔" prop="humInterval">
                                <el-input v-model.number="form.humInterval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>

                <el-divider content-position="left">光感配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="8">
                            <el-form-item label="光感范围" prop="brightMin">
                                <el-input v-model.number="form.brightMin" placeholder="">
                                    <template slot="append"></template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="光感范围" prop="brightMax">
                                <el-input v-model.number="form.brightMax" placeholder="">
                                    <template slot="append"></template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="光感记录间隔" prop="brightInterval">
                                <el-input v-model.number="form.brightInterval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">定位配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="定位上报间隔" prop="brightMin">
                                <el-input v-model.number="form.gpsInterval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-form-item label="GPS开关" prop="gpsSwitch">
                    <el-switch
                            v-model="form.gpsSwitch"
                            active-value="1"
                            inactive-value="0"
                            placeholder=""
                    />
                    （仅在设备安装了外接GPS天线，并且该外接天线处于和天空之间无金属遮挡物的情况下才可使能）
                </el-form-item>
                <div v-show="collisionDiv">
                    <el-divider content-position="left">{{ $t('device.collisionSetting.title') }}
                        <el-switch
                                v-model="form.collisionSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""
                        />
                    </el-divider>
                    <div v-show="form.collisionSwitch=='1'">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.collisionSetting.collisionInterval')"
                                                  prop="collisionInterval">
                                        <el-input v-model.number="form.collisionInterval" placeholder="">
                                            <template slot="append">{{ $t('device.setting.minute') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.collisionSetting.collisionRange')"
                                                  prop="collisionRange">
                                        <el-input v-model="form.collisionRange" placeholder="">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div v-show="startStopDiv">
                    <el-divider content-position="left">{{ $t('device.startStopSetting.title') }}
                        <el-switch
                                v-model="form.startStopSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""
                        />
                    </el-divider>
                    <div v-show="form.startStopSwitch=='1'">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.startStopSetting.startStopInterval')"
                                                  prop="startStopInterval">
                                        <el-input v-model.number="form.startStopInterval" placeholder="">
                                            <template slot="append">{{ $t('device.setting.minute') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.startStopSetting.startStopRange')"
                                                  prop="startStopRange">
                                        <el-input v-model="form.startStopRange" placeholder="">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div v-show="form.workStatus =='1'">
                    <el-divider content-position="left">长时间停车休眠
                        <el-switch
                                v-model="form.sleepSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""
                        />
                    </el-divider>
                    <div v-show="form.sleepSwitch=='1'">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.startStopSetting.stopTime')" prop="sleepTime">
                                        <el-input v-model.number="form.sleepTime" placeholder="">
                                            <template slot="append">{{ $t('device.setting.minute') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10">
                                    <el-form-item label="休眠后上报间隔" prop="sleepInterval">
                                        <el-input v-model="form.sleepInterval" placeholder="">
                                            <template slot="append">{{ $t('device.setting.minute') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div v-if="tiltDiv">
                    <el-divider content-position="left">{{ $t('device.tiltSetting.title') }}
                        <el-switch
                                v-model="form.tiltSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""
                        />
                    </el-divider>
                    <div v-show="form.tiltSwitch=='1'">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.tiltSetting.tiltXRange')" prop="tiltXRange">
                                        <el-input v-model.number="form.tiltXRange" placeholder="">
                                            <template slot="append">°</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.tiltSetting.tiltYRange')" prop="tiltYRange">
                                        <el-input v-model.number="form.tiltYRange" placeholder="">
                                            <template slot="append">°</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                            <el-col :span="24">
                                <el-col :span="10">
                                    <el-form-item :label="$t('device.tiltSetting.tiltInterval')" prop="tiltInterval">
                                        <el-input v-model.number="form.tiltInterval" placeholder="">
                                            <template slot="append">{{ $t('device.setting.minute') }}</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-card>
            <el-card class="box-card">
                <el-divider content-position="left">{{ $t('device.ShopSetting.title') }}</el-divider>
                <el-form-item :label="$t('device.ShopSetting.shop')" prop="shop">
                    <el-select v-model="form.shopList" multiple filterable>
                        <el-option
                                clearable="true"
                                v-for="item in shopOptions"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-card>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="close">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props: {
            form: {
                default: {}
            }
        },
        data() {
            return {
                loading: false,
                startStopDiv: true,
                collisionDiv: true,
                tiltDiv: true,
                nfcOptions: [],
                shopOptions: [],
                tempUnit: "℃",
                rules: {
                    batteryMin: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tempMin: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tempMax: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tempCalibration: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tempInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    humMin: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    searchTime: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    humMax: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    humInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    humCalibration: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    brightMin: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    brightMax: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    brightInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    gpsInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    collisionInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    collisionRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    startStopInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    startStopRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    sleepTime: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    sleepInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltXRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltYRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            closeMotionSensor(val){
                if(val==="0"){
                    this.startStopDiv = false
                    this.collisionDiv = false
                    this.tiltDiv = false
                }else {
                    this.startStopDiv = true
                    this.collisionDiv = true
                    this.tiltDiv = true
                }
            },
            changeTempUnit(val) {
                switch (val) {
                    case 0:
                        return this.tempUnit = "℃"
                    case 1:
                        return this.tempUnit = "℉"
                }
            },
            cabotageChange(val) {
                switch (val) {
                    case 1:
                        this.startStopDiv = true
                        this.collisionDiv = true
                        this.tiltDiv = true
                        this.form.sounet = 60
                        this.form.gpsInterval = 12
                        this.form.logGpsReport = 60
                        this.form.tiltInterval = 6
                        this.form.startStopInterval = 6
                        this.form.collisionInterval = 6
                        this.form.brightInterval = 6
                        this.form.tempInterval = 6
                        this.form.humInterval = 6
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        this.form.sleepSwitch = "0"
                        break;
                    case 2 :
                        this.startStopDiv = true
                        this.collisionDiv = true
                        this.tiltDiv = false
                        this.form.sounet = 60
                        this.form.gpsInterval = 60
                        this.form.logGpsReport = 60
                        this.form.tiltInterval = 30
                        this.form.startStopInterval = 30
                        this.form.collisionInterval = 30
                        this.form.brightInterval = 30
                        this.form.tempInterval = 30
                        this.form.humInterval = 30
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        this.form.sleepSwitch = "0"
                        break;
                    case 3 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.sounet = 60
                        this.form.gpsInterval = 60
                        this.form.logGpsReport = 60
                        this.form.tiltInterval = 30
                        this.form.startStopInterval = 30
                        this.form.collisionInterval = 30
                        this.form.brightInterval = 30
                        this.form.tempInterval = 30
                        this.form.humInterval = 30
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        this.form.sleepSwitch = "0"
                        break;
                    case 4 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.sounet = 4 * 60
                        this.form.gpsInterval = 60
                        this.form.logGpsReport = 4 * 60
                        this.form.tiltInterval = 60
                        this.form.startStopInterval = 60
                        this.form.collisionInterval = 60
                        this.form.brightInterval = 60
                        this.form.tempInterval = 60
                        this.form.humInterval = 60
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        this.form.sleepSwitch = "0"
                        break;
                    case 5 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.sounet = 10 * 60
                        this.form.gpsInterval = 60
                        this.form.logGpsReport = 10 * 60
                        this.form.tiltInterval = 60
                        this.form.startStopInterval = 60
                        this.form.collisionInterval = 60
                        this.form.brightInterval = 60
                        this.form.tempInterval = 60
                        this.form.humInterval = 60
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        this.form.sleepSwitch = "0"
                        break;
                }
            },
            formSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$emit('formSubmit', this.form)
                    }
                });

            },
            close(){
                this.$emit('close')
            },
        }
    }
</script>
<style scoped>
    .formborder {
        border-bottom: 1px solid #e4e7ed;
    }

    /deep/ .el-form-item__label {
        font-weight: bold;
    }
</style>
