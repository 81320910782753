<template>
    <div class="page">
        <div class="page-header hidden-sm-and-down">
            <div style="float:right">
                <el-row style="float: right">
                    <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                    <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                </el-row>
            </div>
            <el-page-header @back="$router.go(-1)" content="设备监控"/>
        </div>
        <div class="page-search">
                <el-form :inline="true" :model="formsearch" class="demo-form-inline" size="small" v-show="searchVisible">
                    <el-form-item :label="$t('temperature.devNum')">
                        <el-input v-model="formsearch.sn" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" type="primary" plain icon="el-icon-search" @click="getData">
                            {{$t('device.search')}}
                        </el-button>
                    </el-form-item>
                </el-form>
        </div>
        <div class="page-main">
            <div class="content" v-loading="loading">
                <div class="itemBox">
                    <div class="items" v-for="item in infoList" v-bind:key="item.id" @click="handleEcharts(item.sn)">
                        <div :class="{tit: true, 'titbg': item.state == 0}"><span>{{item.sn}}
                            <br/>{{!item.device_name ? "未命名" : item.device_name}}</span>
                        </div>
                        <div class="tem"><span><img class="icon" src="@/assets/ic_wd_sz.png"
                                                    style="width: 22px;height: 22px;margin: 1px 5px 0px 0px"/> <span
                                :class="{'col': item.tempMin>=item.temperature>=item.tempMax}">{{item.temperature}}</span> ℃</span>
                            <span>-20/-60</span></div>
                        <div class="hum"><span><img class="icon" src="@/assets/ic_hsd_sz.png"
                                                    style="width: 22px;height: 22px;margin: 1px 5px 0px 0px"/> <span
                                :class="{'col': item.humMin>=item.humidity>=item.humMax}">{{item.humidity}}</span> %</span>
                            <span>5/95</span></div>
                    </div>
                </div>
                <div style="text-align: center;margin: 20px">
                    <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[5, 10, 15, 20, 30, 50]"
                                   :total="table.total" :page-size="table.pageSize"
                                   @current-change="handleCurrentChange"
                                   @size-change="handleSizeChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'logiPlus-monitoring',
        path: '/main/devices/logiPlus/monitoring',
        data() {
            return {
                formsearch: {
                    daterange: [],
                    device_name: ''
                },
                loading: false,
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 15
                },
                dialogVisible: false,
                searchVisible: true,
                infoList: []
            }
        },
        methods: {
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/devices/logiPlus/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.formsearch})
                    if (!resp.data) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.infoList = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            handleEcharts(sn) {
                this.$router.push({
                    path: `/main/devices/logiPlus/temperature/echarts/${sn}`,
                })
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;

        .page-header {
            padding-bottom: 10px;
        }

        .page-search {
            background-color: #f7f7f8;
            border: 1px solid #EBEEF5;
            border-bottom: 0px;
            text-align: center;

            .el-form-item {
                margin: 5px;
            }

        }
        .topLable {
            margin-bottom: 15px;
            position: relative;
            display: flex;
            justify-content: space-between;
            position: relative;
            color: #fff;
            font-size: 14px;

            .tit {
                font-weight: bold;
                font-size: 16px;
                line-height: 30px;
            }

            .info {
                color: #fff;
                display: flex;
                text-align: center;
                font-size: 12px;
                line-height: 1.5;

                .butItem {
                    padding: 4px 15px;
                    margin: 0 5px;
                    border: solid 1px #fff;
                    border-radius: 3px;
                }

                .select {
                    margin-left: 5px;
                }
            }

            .time {
                margin-left: 40px;
                font-size: 14px;
            }
        }

        .content {
            background: #fff;
            padding: 15px;
            border-radius: 5px;
            min-height: 740px;

            .itemBox {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
            }

            .items {
                width: 18%;
                margin: 10px 1%;
                height: 203px;
                border-radius: 5px;
                box-shadow: 0 0 5px #ccc;
                line-height: 40px;

                .icon {
                    display: inline-block;
                    color: #27c7d8;
                    font-size: 24px;
                    width: 24px;
                    text-align: center;
                }

                .tit {
                    padding: 0 15px;
                    display: flex;
                    justify-content: space-between;
                    line-height: 40px;
                    color: #186bd0;
                }

                .titbg {
                    background: #e7f0fa;
                }

                .icon {
                    position: relative;
                    top: 2px;
                }

                .hum {
                    margin: 0 15px;
                    display: flex;
                    justify-content: space-between;
                }

                .tem {
                    margin: 0 15px;
                    border-bottom: solid 1px #e7f0fa;
                    display: flex;
                    justify-content: space-between;
                }

                .col {
                    color: #f00;
                }
            }
        }

        .active {
            color: #27c7d8;
            border-color: #27c7d8 !important;
        }

        .pageList {
            text-align: right;
            margin: 10px 0;
        }
    }
</style>

