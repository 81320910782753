const data = {
    formdata: [
        {
            "name": "brand",
            "type": "input",
            "title": "车辆品牌",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "vih",
            "type": "input",
            "title": "车辆识别代号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "plateNumber",
            "type": "input",
            "title": "车辆识别代号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "plateColor",
            "type": "input",
            "title": "车辆识别代号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "engineNumber",
            "type": "input",
            "title": "发动机号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "registerDate",
            "type": "date",
            "title": "注册日期",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "issueDate",
            "type": "date",
            "title": "发证日期",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "licenceExpiryDate",
            "type": "date",
            "title": "行驶证有效期日期",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "issueAuthority",
            "type": "input",
            "title": "发证机关",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "length",
            "type": "number",
            "title": "长度",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "width",
            "type": "number",
            "title": "宽度",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "height",
            "type": "number",
            "title": "高度",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "weight",
            "type": "number",
            "title": "质量",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "netWeight",
            "type": "number",
            "title": "净重",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "maxLoad",
            "type": "number",
            "title": "核定载质量",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "energyType",
            "type": "input",
            "title": "车辆能源类型",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "roadTransportPermit",
            "type": "input",
            "title": "道路运输证号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "owner",
            "type": "input",
            "title": "所有人",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "useCharacter",
            "type": "input",
            "title": "使用性质",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "classification",
            "type": "input",
            "title": "车辆类型",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "insuranceNumber",
            "type": "input",
            "title": "保险编号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "insuranceCompany",
            "type": "input",
            "title": "保险公司",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
    ],
    table: {
        columns: [
            {title: '车辆品牌', field: 'brand', width: '150'},
            {title: '车辆识别代号', field: 'vih', width: '220'},
            {title: '车牌号', field: 'plateNumber', width: '180'},
            {title: '车牌颜色', field: 'plateColor', width: '180'},
            {title: '发动机号', field: 'engineNumber', width: '150'},
            {title: '注册日期', field: 'registerDate', width: '150'},
            {title: '发证日期', field: 'issueDate', width: '150'},
            {title: '行驶证有效期日期', field: 'licenceExpiryDate', width: '250'},
            {title: '发证机关', field: 'issueAuthority', width: '200'},
            {title: '长度', field: 'length', width: '150'},
            {title: '宽度', field: 'width', width: '150'},
            {title: '高度', field: 'height', width: '150'},
            {title: '质量', field: 'weight', width: '150'},
            {title: '净重', field: 'netWeight', width: '150'},
            {title: '核定载质量', field: 'maxLoad', width: '120'},
            {title: '车辆能源类型', field: 'energyType', width: '120'},
            {title: '道路运输证号', field: 'roadTransportPermit', width: '220'},
            {title: '所有人', field: 'owner', width: '150'},
            {title: '使用性质', field: 'useCharacter', width: '150'},
            {title: '车辆类型', field: 'classification', width: '160'},
            {title: '保险编号', field: 'insuranceNumber', width: '150'},
            {title: '保险公司', field: 'insuranceCompany', width: '160'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
