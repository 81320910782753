import formatter from '@/components/formatter'
const data = {
    formdata: [
        {
            "name": "dictName",
            "type": "input",
            "title": "字典名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "dictType",
            "type": "input",
            "title": "字典类型",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "status",
            "type": "radio",
            "title": "状态",
            required: true,
            span:24,
            placeholder: "请输入",
            value: "",
            disabled: false,
            options:[
                {'value':"0",'label':"正常"},
                {'value':"1",'label':"停用"},
            ]
        },
        {
            "name": "remark",
            "type": "textarea",
            "title": "备注",
            required: false,
            span:24,
            rows:4,
            maxlength:200,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
    ],
    table: {
        columns: [
            {title: '字典名称', field: 'dictName', width: ''},
            {title: '字典类型', field: 'dictType', width: ''},
            {title: '状态', field: 'status', width: '',formatter:formatter.dictStatus},
            {title: '备注', field: 'remark', width: ''},
            {title: '创建时间', field: 'createdDate', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
