<template>
    <div>
        <el-row>
            <el-form ref="form" :model="dataform"  label-width="160px">
                <el-row class="title">
                    <span>{{$t('transport.idPhoto')}}</span>
                </el-row>
                <el-row>
                    <el-form-item>
                        <div style="display: flex">
                            <div>
                                <zk-upload-image v-model="dataform.businessLicenseUrl" :title="$t('transport.businessLicenseUrl')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.qualificationCertificateUrl" :title="$t('transport.qualificationCertificateUrl')"></zk-upload-image>
                            </div>
                        </div>
                    </el-form-item>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.basicInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.carrierName')" prop="carrierName">
                            <el-input v-model="dataform.carrierName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.carrierCode')" prop="carrierCode">
                            <el-input v-model="dataform.carrierCode">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.email')">
                            <el-input v-model="dataform.email">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.cityId')">
                            <el-cascader
                                    size="small"
                                    style="width: 100%;"
                                    :options="options"
                                    :props="{emitPath:false}"
                                    v-model="dataform.cityId"
                            >
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.contactName')">
                            <el-input v-model="dataform.contactName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.phone')">
                            <el-input v-model="dataform.phone">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.cods')">
                            <el-input v-model="dataform.cods">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.roadTransportOperationPermit')">
                            <el-input v-model="dataform.roadTransportOperationPermit">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.accountInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankAccountName')">
                            <el-input v-model="dataform.bankAccountName" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankAccountNumber')">
                            <el-input v-model="dataform.bankAccountNumber" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankName')">
                            <el-input v-model="dataform.bankName" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankBranch')">
                            <el-input v-model="dataform.bankBranch" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.billingInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.invoiceTitle')">
                            <el-input v-model="dataform.invoiceTitle" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.taxNumber')">
                            <el-input v-model="dataform.taxNumber" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.taxAddress')">
                            <el-input v-model="dataform.taxAddress" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.taxBankName')">
                            <el-input v-model="dataform.taxBankName" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.taxBankNumber')">
                            <el-input v-model="dataform.taxBankNumber" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.taxPhone')">
                            <el-input v-model="dataform.taxPhone" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <el-row>
            <div class="add">
                <el-button @click="$router.go(-1)">{{ $t('transport.cancel') }}</el-button>
                <el-button type="primary" @click="formSubmit">{{ $t('transport.confirm') }}</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>
    import {provinceAndCityData} from "element-china-area-data";
    export default {
        name: 'carrier-form',
        path: ['/main/transport/carrier/form', '/main/transport/carrier/form/:pk'],
        data() {
            return {
                Options: [],
                options: provinceAndCityData,
                rules: {
                    customerName: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    customerCode: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                },
                dataform: {}
            }
        },
        methods: {
            async formSubmit() {
                var result = 0
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        result = 1
                    }
                })
                if (result) {
                    try {
                        const resp = await this.$http.post(`/transport/carrier/`, this.dataform)
                        if (resp.data.status !== 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('transport.success'))
                            this.$router.push({
                                path: `/main/transport/carrier`,
                            })
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }

                }
            },
            async getData() {
                try {
                    let pk = this.$route.params.pk
                    if (pk) {
                        const resp = await this.$http.get(`/transport/carrier/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.dataform = resp.data.data
                            this.dataform.cityId = this.dataform.cityId.toString()
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getData()
        },
    }
</script>
<style scoped>
    .add {
        position: fixed;
        bottom: 10px;
        left: 270px;
        right: 27px;
        text-align: center;
        background-color: #fff;
        border-radius: 0 !important;
        border: 1px solid #e6e6e6;
        height: 55px !important;
        line-height: 55px;
        color: #234;
        z-index: 100;
    }

    /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #EBEEF5;
        background-color: aliceblue;
    }

    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 5px;
    }

    .tabeldata {
        border: 1px solid red;
    }

    .el-upload--picture-card {
        line-height: inherit;
        display: inherit;
        text-align: center;
        align-items: center;
    }
</style>
