import formatter from '@/components/formatter'

const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: '200'},
            {title: '最后通讯时间', field: 'last_seen', width: '150'},
            {title: '电量', field: 'battery', width: '120', formatter: formatter.batteryFormatter},
            {title: '温度', field: 'temperature', width: '100', formatter: formatter.temperatureFormatter},
            {title: '湿度', field: 'humidity', width: '100', formatter: formatter.humidityFormatter},
            {title: '光感', field: 'bright', width: '100'},
            {title: '信号', field: 'signal', width: '150'},
            {title: '碰撞', field: 'crash', width: '150', formatter: formatter.defaultStr},
            {title: '启停', field: 's_s', width: '150', formatter: formatter.defaultStr},
            {title: '倾斜', field: 'tilt', width: '150', formatter: formatter.defaultStr},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
