<template>
    <el-dialog :title="$t('transport.titleh')" :visible="visible" width="60%" @close="handleClose">
        <el-form ref="form" :model="form"  label-width="120px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="姓名" prop="name">
                        <el-input
                                autocomplete="off"
                                v-model="form.name"
                                placeholder="请输入">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号" >
                        <el-input
                                autocomplete="off"
                                v-model="form.phone"
                                placeholder="请输入">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('company.departmentIds')">
                        <treeselect
                                v-model="form.departmentId"
                                :options="depList"
                                style="width: 100%;"
                                :placeholder="$t('company.deoption')"
                                :default-expand-level="1"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮件">
                        <el-input
                                autocomplete="off"
                                v-model="form.email"
                                placeholder="请输入">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-col :span="12">
                        <el-form-item label="短信接收时间" prop="smsStartTime">
                            <el-time-picker
                                    is-range
                                    v-model="form.smsStartTime"
                                    range-separator="至"
                                    style="width: 100%"
                                    value-format="HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    placeholder="选择时间范围">
                            </el-time-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <div style="line-height: 35px;">
                            <i class="el-icon-warning-outline" style="padding-left: 20px;color: #d2d8d9;"></i>
                            <span style="padding-left: 10px;color: #d2d8d9;font-size: 13px">若未购买短信服务，侧无法接收短信推送。</span>
                        </div>
                    </el-col>
                </el-col>
                <el-col :span="24">
                    <el-col :span="12">
                        <el-form-item label="邮件接收时间" prop="emailStartTime">
                            <el-time-picker
                                    is-range
                                    style="width: 100%"
                                    v-model="form.emailStartTime"
                                    value-format="HH:mm:ss"
                                    range-separator="至"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    placeholder="选择时间范围">
                            </el-time-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <div  style="line-height: 35px;">
                            <i class="el-icon-warning-outline" style="padding-left: 20px;color: #d2d8d9;"></i>
                            <span style="padding-left: 10px;color: #d2d8d9;font-size: 13px">若未填写邮箱或邮箱填写错误，则无法接收邮件推送。</span>
                        </div>
                    </el-col>
                </el-col>
            </el-row>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">确认提交
                </el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        components: {
            Treeselect
        },
        props: {
            form: {
                default: {}
            },
            visible:{
                default: false
            }
        },
        data() {
            return {
                loading: false,
                depList:[]
            }
        },
        methods: {
            walk(list, callback, deep = 0) {
                return list.map(it => {
                    const result = callback({ ...it }, deep);
                    if (it.children) {
                        result.children = this.walk(it.children, callback, deep + 1);
                    }
                    return result;
                });
            },
            treeChange(arr) {
                return arr.map(item => {
                    if (item.children && item.children.length > 0) {
                        this.treeChange(item.children)
                    } else {
                        delete item.children
                    }
                    return item
                })
            },
            async getDep() {
                try {
                    const resp = await this.$http.get(`/company/department/tree/`)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        const data = this.walk(resp.data.data.children, (data) => ({
                            ...data,
                            label: data.node.departmentName,
                            value: data.node.id,
                        }), 0);
                        this.depList = this.treeChange(data)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                try {
                    var temp = JSON.stringify(this.form)
                    temp = JSON.parse(temp)
                    temp["smsStartTime"] = this.form.smsStartTime[0]
                    temp["smsEndTime"] = this.form.smsStartTime[1]
                    temp["emailStartTime"] = this.form.emailStartTime[0]
                    temp["emailEndTime"] = this.form.emailStartTime[1]
                    const resp = await this.$http.post("/company/alertReceiver/", temp)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.$message.success(this.$t('company.success'))
                        this.$emit('update')
                        this.$emit('close')
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleClose() {
                this.$emit('close')
            },
        },
        mounted() {
            this.getDep()
        }
    }
</script>
