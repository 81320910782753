import formatter from '@/components/formatter'
const data = {
    formdata: [
        {
            "name": "type",
            "type": "radio",
            "title": "收发货类型",
            required: true,
            span:24,
            placeholder: "请输入",
            value: "",
            disabled: false,
            options:[
                {'value':'1','label':"发货人"},
                {'value':'2','label':"收货人"},
                {'value':'3','label':"收发货人"},
            ]
        },
        {

            "name": "name",
            "type": "input",
            "title": "名称",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },

        {
            "name": "phone",
            "type": "input",
            "title": "手机号码",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "regionId",
            "type": "number",
            "title": "区域id",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "email",
            "type": "input",
            "title": "邮箱",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }

    ],
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
            {title: '收发货类型', field: 'type', width: '',formatter: formatter.ShouFaType},
            {title: '手机号码', field: 'phone', width: ''},
            {title: '邮箱', field: 'email', width: ''},
            {title: '区域', field: 'regionId', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
