<template>
    <div>
        <el-table stripe :data="table.data" style="width: 100%;margin-top:10px;height: 100%" v-loading="loading">
            <slot></slot>
        </el-table>
        <div style="text-align: center;margin: 20px">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'data-table',
        props: {
            loading: {
                default: false
            },
            table: {
                default: {}
            }
        },
        methods: {
            handleCurrentChange(val) {
                this.table.page = val
                this.$emit('change', this.table)
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.$emit('change', this.table)
            },
        }
    }
</script>