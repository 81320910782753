<template>
    <zk-admin-table title="日志记录" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle
                           @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :key="idx">
            </el-table-column>
    </zk-admin-table>
</template>
<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'

    export default {
        name: 'keybox-record',
        path: '/main/devices/keybox/record',
        components: {
            SearchForm
        },
        data() {
            return {
                loading: false,
                table: data.table,
                formsearch: {
                    daterange: [],
                    sn: '',
                    lockState: '',
                },
                searchVisible: true
            }
        },
        methods: {
            handleSearch(e) {
                this.formsearch = e
                console.log(e,"====")
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            async getData() {
                this.loading = true
                try {
                    let start = this.formsearch.daterange && this.formsearch.daterange.length === 2 ? this.formsearch.daterange[0] : ''
                    let end = this.formsearch.daterange && this.formsearch.daterange.length === 2 ? this.formsearch.daterange[1] : ''

                    const resp = await this.$http.get(`/devices/keybox/record?page=${this.table.page}&pageSize=${this.table.pageSize}&end=${end}&start=${start}&sn=${this.formsearch.sn}&lockState=${this.formsearch.lockState}`)
                    console.log(resp.data.data)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>

