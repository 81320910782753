<template>
    <div>
        <el-page-header @back="$router.go(-1)" :content="$t('device.devlist')"/>
        <div style="margin-top:20px">
            <div class="search" style="background-color: #f7f7f7;padding:20px 20px 0 20px">
                <el-form :inline="true" :model="formsearch" class="demo-form-inline">
                    <el-form-item :label="$t('device.search')">
                        <el-input v-model="formsearch.keywords"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="formsearch.daterange"
                                        style="float: right; padding: 3px 10px"
                                        type="daterange"
                                        value-format="yyyy-MM-dd"
                                        :range-separator="$t('device.to')"
                                        :start-placeholder="$t('device.startDate')"
                                        :end-placeholder="$t('device.startDate')">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" type="success" icon="el-icon-search" @click="getData">{{$t('device.search')}}
                        </el-button>
                        <el-button class="filter-item" type="warning" icon="el-icon-refresh-left"
                                   @click="getData()">{{$t('device.reset')}}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="text-align:right;margin:20px 0">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">{{ $t('device.add')}}</el-button>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="index"
                                 :label="$t('device.serialNumber')"
                                 header-align="center"
                                 width="120"
                                 align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="$t('device.'+v.field)"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" width="380" :label="$t('device.operation')" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" @click="handleUpdate(scope.row)">{{$t('device.modification')}}</el-button>
                        <el-button type="danger" size="mini" @click="handleDel(scope.row)">{{$t('device.delete')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin: 20px">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
        <el-dialog :title="$t('device.titleh')" :visible.sync="dialogVisible" width="50%">
            <my-form :formData="formData" :formSubmit="formSubmit" :form="form" ref="myforms" :close="close"></my-form>

        </el-dialog>
        <el-dialog
                :title="$t('device.delete')"
                :visible.sync="delmodel"
                width="30%"
        >
            <span style="text-align: center;">{{$t('device.hint')}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delmodel = false">{{ $t('device.cancel') }}</el-button>
                <el-button type="primary" @click="delSubmit">{{ $t('device.confirm') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import myForm from "@/components/data/deviceForm"
    import data from "../../data/devbind"

    export default {
        name: 'device-apbind',
        path: '/main/devices/apbind',
        components: {
            myForm,
        },
        data() {
            return {
                formsearch: {
                    daterange: [],
                    keywords: ''
                },
                delmodel: false,
                loading: false,
                formData: [],
                form: {},
                delId: null,
                dialogVisible: false,
                table: data.table,
            }
        },
        methods: {
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/devices?page=${this.table.page - 1}&size=${this.table.pageSize}`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data
                        this.table.total = resp.data.length
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async formSubmit() {
                const result = this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        var method = this[`form`]['id'] !== undefined ? 'put' : 'post'
                        var url = '/devices'
                        if (method == 'put') {
                            url = `/devices/${this[`form`]['id']}`
                        }
                        if (this.form['departmentIds']) {
                            this.form['departmentIds'] = this.form['departmentIds'].join(",")
                        }
                        const resp = await this.$http[method](url, this.form)
                        if (!resp.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.$message.success(this.$t('device.success'))
                            this.dialogVisible = false
                            this.getData()
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }

                }
            },
            handleStatus(row) {
                this.$router.push({
                    path: `/main/dev/status/${row.sn}`,
                })
            },
            handleLog(row) {
                this.$router.push({
                    path: `/main/dev/log/${row.sn}`,
                })
            },
            async handleDel(row) {
                this.delId = row.id
                this.delmodel = true
            },
            async delSubmit() {
                try {
                    const resp = await this.$http.delete(`/devices/${this.delId}`)
                    if (resp.status != 204) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.$message.success(this.$t('device.delSuccess'))
                        this.delmodel = false
                        this.getData()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }

            },
            handleUpdate(row) {
                if (typeof(row.departmentIds) === 'string') {
                    let ids = row.departmentIds.split(',')
                    ids = ids.map(item => {
                        return +item;
                    });
                    row.departmentIds = ids
                }
                this.form = row
                this.dialogVisible = true
            },
            handleAdd() {
                this.setDefaultValue()
                this.dialogVisible = true
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
            close() {
                this.dialogVisible = false
            },
            setDefaultValue() {
                var that = this
                that.formData = data.formdata
                const formData = {...this.form};
                // 设置默认值
                that.formData.forEach(item => {
                    const {name} = item;
                    if (name) {
                        formData[name] = '';
                    }
                });
                this.form = formData
            },
        },
        mounted() {
            this.getData()
            this.setDefaultValue()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>
