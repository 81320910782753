const data = {
    formdata: [
        {
            "name": "goodsName",
            "type": "input",
            "title": "货物名称",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "goodsCode",
            "type": "input",
            "title": "货物编码",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "unit",
            "type": "input",
            "title": "单位",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "goodsType",
            "type": "input",
            "title": "货物类型",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "wholeUnit",
            "type": "input",
            "title": "整件单位",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "unitConversion",
            "type": "input",
            "title": "单位转换",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "unitWeight",
            "type": "number",
            "title": "单位重量",
            required: false,
            span:12,
            slots:"kg",
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "wholeWeight",
            "type": "number",
            "title": "整件重量",
            slots:"kg",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "unitVolume",
            "type": "number",
            "title": "单位体积",
            slots:"m",
            sup:3,
            required: false,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "wholeVolume",
            "type": "number",
            "title": "整件体积",
            slots:"m",
            sup:3,
            required: false,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "price",
            "type": "number",
            "title": "销售单价",
            slots:"元",
            required: false,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "packageType",
            "type": "input",
            "title": "包装类型",
            required: false,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "specification",
            "type": "input",
            "title": "规格",
            required: false,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        // {
        //     "name": "company",
        //     "type": "select",
        //     "title": "公司",
        //     required: false,
        //     placeholder: "请选择",
        //     value: "",
        //     disabled: false,
        //     key: "id",
        //     label:"companyName",
        //     options:[
        //     ]
        // },
        {
            "name": "comment",
            "type": "textarea",
            "title": "备注",
            required: false,
            span:24,
            rows:4,
            maxlength:200,
            placeholder: "请输入",
            value: "",
            disabled: false
        },

    ],
    table: {
        columns: [
            // {title: '公司', field: 'company.id', width: ''},
            {title: '货物名称', field: 'goodsName', width: '120'},
            {title: '货物编码', field: 'goodsCode', width: '120'},
            {title: '货物类型', field: 'goodsType', width: '120'},
            {title: '单位', field: 'unit', width: '120'},
            {title: '整件单位', field: 'wholeUnit', width: '120'},
            {title: '单位转换', field: 'unitConversion', width: '140'},
            {title: '单位重量', field: 'unitWeight', width: '120'},
            {title: '整件重量', field: 'wholeWeight', width: '120'},
            {title: '单位体积', field: 'unitVolume', width: '120'},
            {title: '整件体积', field: 'wholeVolume', width: '120'},
            {title: '销售单价', field: 'price', width: '120'},
            {title: '包装类型', field: 'packageType', width: '120'},
            {title: '规格', field: 'specification', width: '120'},
            {title: '备注', field: 'comment', width: '120'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
