const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: ''},
            {title: '运输状态', field: 'work_status', width: ''},
            {title: '运单号', field: 'task_number', width: ''},
            {title: '创建时间', field: 'create_time', width: ''},
            {title: '更新时间', field: 'update_time', width: ''},
            {title: '备注', field: 'remark', width: ''},
            {title: '开始照片', field: 'start_img', width: ''},
            {title: '结束照片', field: 'end_img', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
