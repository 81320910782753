<template>
    <div>
        <el-row style="text-align: right">
            <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleCreated"></el-button>
        </el-row>
        <el-table :header-cell-style="{background:'#f7fbff',color:'#333'}"
                :data="data"
                style="width: 100%;margin-bottom: 20px;margin-top:20px;"
                row-key="id"
                :expand-on-click-node="false"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterNode">
            <el-table-column
                    label="部门名称"
                    sortable
                    style="width:35%">
                <template slot-scope="scope">
                    {{scope.row.node.departmentName}}
                </template>
            </el-table-column>
            <el-table-column
                    label="备注"
                    style="width:35%">
            </el-table-column>
            <el-table-column label="操作" width="210">
                <template slot-scope="scope">
                    <el-button
                            type="text"
                            size="mini"
                            @click="() => add(scope.row)">
                        <i class="el-icon-plus"></i>
                    </el-button>
                    <el-button
                            type="text"
                            size="mini"
                            @click="() => update(scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button
                            type="text"
                            size="mini"
                            @click="() => remove(scope.row)">
                        <i class="el-icon-close"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
                :title="$t('company.titlehDepartment')"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
                <el-form-item :label="$t('company.superiorDepartment')">
                    <el-select v-model="form.parentDepartmentId" :placeholder="$t('company.placeHolder')" style="width: 100%" filterable
                               clearable>
                        <el-option :label="item.departmentName" :value="item.id" v-for="item in depList"
                                   :key="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('company.departmentName')" prop="departmentName">
                    <el-input v-model="form.departmentName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('company.departmentCode')" prop="departmentCode">
                    <el-input v-model="form.departmentCode"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('company.cancel')}}</el-button>
                <el-button type="primary" @click="formSubmit">{{ $t('company.confirm')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
                :title="$t('company.delete')"
                :visible.sync="model3"
                width="30%"
        >
            <span>{{$t('company.hint')}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="model3 = false">{{$t('company.cancel')}}</el-button>
                <el-button type="primary" @click="delSubmit">{{ $t('company.confirm')}}</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'department',
        path: '/main/company/department',
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        data() {
            return {
                form: {
                    departmentName: '',
                    parentDepartmentId: 0,
                    departmentCode: ''
                },
                loading: false,
                delId: '',
                model3: false,
                dialogVisible: false,
                filterText: '',
                clickCode: [],
                depList: [],
                data: [],
                defaultProps: {
                    children: 'children',
                    // eslint-disable-next-line no-unused-vars
                    label: (data, node) => {
                        return data.node.departmentName
                    },
                },
                rules: {
                    departmentName: [
                        {required: true, message: this.$t('company.rulesName'), trigger: 'blur'},
                    ],
                    departmentCode: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                },
            };
        },

        methods: {
            handleCreated() {
                this.form = {
                    departmentName: '',
                    parentDepartmentId: 0,
                    departmentCode: ''
                }
                this.dialogVisible = true
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.node.departmentName.indexOf(value) !== -1;
            },
            handleClose(done) {
                done()
            },
            mouseenter(node, data) {
                this.$set(data, 'show', true)
            },
            // 树节点鼠标移入
            mouseleave(node, data) {
                this.$set(data, 'show', false)
            },
            async delSubmit() {
                try {
                    const resp = await this.$http.delete(`/company/department/${this.delId}`)
                    if (resp.data.status != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success(this.$t('company.delSuccess'))
                        this.model3 = false
                        this.getData()
                        this.getDataList()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }

            },
            add(data) {
                this.form = {
                    departmentName: '',
                    parentDepartmentId: data.id,
                    departmentCode: ''
                }
                this.dialogVisible = true
            },
            update(data) {
                this.form = {
                    departmentName: data.node.departmentName,
                    parentDepartmentId: data.parentNode.id,
                    departmentCode: data.node.departmentCode,
                    id: data.id
                }
                this.dialogVisible = true
            },
            remove(data) {
                this.delId = data.id
                this.model3 = true
            },
            async formSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (!valid) {
                        return
                    }
                })
                try {
                    const resp = await this.$http.post(`/company/department/`, this.form)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success(this.$t('company.success'))
                        this.dialogVisible = false
                        this.getData()
                        this.getDataList()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/company/department/tree/`)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.data = resp.data.data.children
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async getDataList() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/company/department/`)
                    if (!resp.data) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.depList = resp.data.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
        },
        mounted() {
            this.getDataList()
            this.getData()
        }
    };
</script>
<style>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
