<template>
    <div>
        <el-row>
            <el-form :model="dataform" :rules="rules" ref="form" label-width="200px" style="padding-bottom:80px"
            >
                <el-row class="title">
                    <span>{{$t('transport.idPhoto')}}</span>
                </el-row>
                <el-row>
                    <el-form-item label="">
                        <div style="display: flex">
                            <div>
                                <zk-upload-image v-model="dataform.drivingLicenseUrl1" :title="$t('transport.drivingLicenseUrl1')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.drivingLicenseUrl2" :title="$t('transport.drivingLicenseUrl2')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.transportLicenseUrl" :title="$t('transport.transportLicenseUrl')"></zk-upload-image>
                            </div>
                        </div>
                    </el-form-item>
                </el-row>
                <el-row class="title">
                    <span>{{ $t('transport.managementInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.plateNumber')" prop="plateNumber">
                            <el-input v-model="dataform.plateNumber">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.classification')" >
                            <el-input v-model="dataform.classification">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.owner')" >
                            <el-input v-model="dataform.owner">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.departmentId')" >
                            <treeselect
                                    v-model="dataform.department"
                                    :options="depList"
                                    style="width: 100%;"
                                    :placeholder="$t('transport.depPlaceHolder')"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.name')" >
                            <el-input v-model="dataform.name">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.phone')" >
                            <el-input v-model="dataform.phone">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.commonUse')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.driversId')" >
                            <el-select
                                    style="width: 100%"
                                    filterable
                                    v-model="dataform.driversId"
                                    :placeholder="$t('transport.depPlaceHolder')"
                            >
                                <el-option
                                        v-for="o in driversList"
                                        :key="o.id"
                                        :label="o.driverName"
                                        :value="o.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.shuoyuan')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.syuan')" >
                            <el-select
                                    style="width: 100%"
                                    filterable
                                    v-model="dataform.syuan"
                                    :placeholder="$t('transport.depPlaceHolder')"
                            >
                                <el-option
                                        v-for="o in deliveryEmployeeList"
                                        :key="o.id"
                                        :label="o.name"
                                        :value="o.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.dbasicInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.invoiceTitle')" >
                            <el-input v-model="dataform.invoiceTitle" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.brand')" >
                            <el-input v-model="dataform.brand" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.plateColor')" >
                            <el-input v-model="dataform.plateColor" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.classification')" >
                            <el-input v-model="dataform.classification" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.owner')" >
                            <el-input v-model="dataform.owner" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.useCharacter')" >
                            <el-input v-model="dataform.useCharacter" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.vih')" >
                            <el-input v-model="dataform.vih" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.engineNumber')" >
                            <el-input v-model="dataform.engineNumber" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.registerDate')" >
                            <el-date-picker
                                    v-model="dataform.registerDate"
                                    type="date"
                                    style="width:100%"
                                    :placeholder="$t('transport.depPlaceHolder')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.issueDate')" >
                            <el-date-picker
                                    v-model="dataform.issueDate"
                                    type="date"
                                    style="width:100%"
                                    :placeholder="$t('transport.depPlaceHolder')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.issueAuthority')" >
                            <el-input v-model="dataform.issueAuthority" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.weight')" >
                            <el-input v-model="dataform.weight" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.maxLoad')" >
                            <el-input v-model="dataform.maxLoad" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.length')" >
                            <el-input v-model="dataform.length" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.width')" >
                            <el-input v-model="dataform.width" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.height')" >
                            <el-input v-model="dataform.height" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.licenceExpiryDate')" >
                            <el-date-picker
                                    v-model="dataform.licenceExpiryDate"
                                    type="date"
                                    style="width:100%"
                                    :placeholder="$t('transport.depPlaceHolder')">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.energyType')" >
                            <el-input v-model="dataform.energyType" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.roadTransportPermit')" >
                            <el-input v-model="dataform.roadTransportPermit" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.insuranceInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.insuranceNumber')" >
                            <el-input v-model="dataform.insuranceNumber" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.insuranceCompany')" >
                            <el-input v-model="dataform.insuranceCompany" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <el-row>
            <div class="add">
                <el-button @click="$router.go(-1)">{{ $t('transport.cancel') }}</el-button>
                <el-button type="primary" @click="formSubmit">{{ $t('transport.confirm') }}</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {
        name: 'vehicle-from',
        path: ['/main/transport/vehicle/form', '/main/transport/vehicle/form/:pk'],
        components: {
            Treeselect
        },
        data() {
            return {
                dataform: {},
                deliveryEmployeeList: [],
                dialogVisible: false,
                Options: [],
                depList: [],
                driversList: [],
                rules: {
                    customerName: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    customerCode: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            async formSubmit() {
                var result = 0
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        result = 1
                    }
                })
                if (result) {
                    try {
                        const resp = await this.$http.post(`/transport/vehicle/`, this.dataform)
                        if (!resp.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.$message.success(this.$t('transport.success'))
                            this.$router.push({
                                path: `/main/transport/vehicle`,
                            })
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }

                }
            },
            async getData() {
                try {
                    let pk = this.$route.params.pk
                    if (pk) {
                        const resp = await this.$http.get(`/transport/vehicle/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.dataform = resp.data.data
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            treeChange(arr) {
                return arr.map(item => {
                    if (item.children && item.children.length > 0) {
                        this.treeChange(item.children)
                    } else {
                        delete item.children
                    }
                    return item
                })
            },
            async getDep() {
                try {
                    const resp = await this.$http.get(`/company/department/tree/`)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.msg)
                    } else {
                        const data = this.walk(resp.data.data.children, (data) => ({
                            ...data,
                            label: data.node.departmentName,
                            value: data.node.id,
                        }), 0);
                        this.depList = this.treeChange(data)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            walk(list, callback, deep = 0) {
                return list.map(it => {
                    const result = callback({...it}, deep);
                    if (it.children) {
                        result.children = this.walk(it.children, callback, deep + 1);
                    }
                    return result;
                });
            },
            async getDe() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/transport/deliveryEmployee/?page=1&pageSize=1000`)
                    if (!resp.data.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.deliveryEmployeeList = resp.data.data.records
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async getDrivers() {
                try {
                    const resp = await this.$http.get(`/transport/driver/?page=1&pageSize=1000`)
                    if (!resp.data.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.driversList = resp.data.data.records
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getDep()
            this.getDe()
            this.getDrivers()
            this.getData()
        },
    }
</script>

<style scoped>
    .add {
        position: fixed;
        bottom: 10px;
        left: 270px;
        right: 27px;
        text-align: center;
        background-color: #fff;
        border-radius: 0 !important;
        border: 1px solid #e6e6e6;
        height: 55px !important;
        line-height: 55px;
        color: #234;
        z-index: 100;
    }

    /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #EBEEF5;
        background-color: aliceblue;
    }

    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 5px;
    }

    .tabeldata {
        border: 1px solid red;
    }

    /deep/ .el-upload--picture-card {
        line-height: inherit;
        display: grid;
        text-align: center;
        align-items: center;
    }
</style>
