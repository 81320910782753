const data = {
    table: {
        columns: [
            {title: '用户名称', field: 'firstName', width: '150'},
            {title: '登录名', field: 'login', width: '150'},
            {title: '用户类型', field: 'type', width: '150'},
            {title: '用户状态', field: 'activated', width: '150', type: "switch"},
            {title: '角色名称', field: 'role', width: '150'},
            {title: '所属部门', field: 'dep', width: '150'},
            {title: '手机号', field: 'phone', width: '150'},
            {title: '邮箱', field: 'email', width: '150'},
            {title: '抄送邮箱', field: 'ccEmails', width: '150'},
            {title: '修改时间', field: 'lastModifiedDate', width: '150'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
