<template>
    <zk-admin-table :title="$t('company.userList')" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="200" :label="$t('transport.operation')" fixed="right">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    {{$t('transport.modification')}}
                </el-button>
                <el-button type="text" size="mini" @click="handleRest(scope.row)" icon="el-icon-refresh-right">{{$t('company.resetPasswd')}}</el-button>
                <el-button type="text" size="mini" @click="handleDel(scope.row)" icon="el-icon-delete" >{{$t('transport.delete')}}</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog
                    :title="$t('transport.delete')"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">{{$t('transport.hint')}}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">{{ $t('transport.cancel') }}</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">{{ $t('transport.confirm') }}</el-button>
                </span>
            </el-dialog>
            <el-dialog
                    :title="$t('company.resetPasswd')"
                    :visible.sync="model3"
                    width="30%"
            >
                <el-form :rules="rules" ref="accountReactform" :model="accountReactform" label-width="80px"
                         style="padding-left:10%;">
                    <el-form-item :label="$t('company.passwd')" prop="newPassword">
                        <el-input v-model="accountReactform.newPassword" type="password" style="width:80%"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="model3 = false">{{$t('company.cancel')}}</el-button>
                <el-button type="primary" @click="reactSubmit()">{{ $t('company.confirm')}}</el-button>
            </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>
<script>
    import data from "./data"
    import SearchForm from './widgets/searchForm'
    export default {
        name: 'company_user',
        path: '/main/company/user',
        components: {
            SearchForm
        },
        data() {
            return {
                search: {},
                delmodel: false,
                loading: false,
                delId: null,
                dialogVisible: false,
                searchVisible: true,
                table: data.table,
                model3: false,
                accountReactform: {
                    newPassword: '',
                    userId: null
                },
                rules: {
                    newPassword: [
                        {required: true, message: this.$t('company.rulepass'), trigger: 'blur'},
                        {min: 8, max: 15, message: this.$t('company.rulepasstext'), trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/company/user/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async handleDel(row) {
                this.delId = row.id
                this.delmodel = true
            },
            handleRest(row) {
                this.accountReactform.userId = row.id
                this.model3 = true
            },
            async reactSubmit() {
                var result = 0
                this.$refs['accountReactform'].validate((valid) => {
                    if (valid) {
                        result = 1
                    }
                })
                if (result) {
                    try {
                        const resp = await this.$http.post("/company/user/resetPassword/", this.accountReactform)
                        if (resp.statusText != "OK") {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.$message.success(this.$t('company.resetsuccess'))
                            this.model3 = false
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
            },
            async delSubmit() {
                try {
                    const resp = await this.$http.delete(`/company/user/${this.delId}`)
                    if (resp.data.status != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success(this.$t('transport.delSuccess'))
                        this.delmodel = false
                        this.getData()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }

            },
            handleUpdate(row) {
                this.$router.push({
                    path: `/main/company/user/form/${row.id}`,
                })
            },
            handleAdd() {
                this.$router.push({
                    path: `/main/company/user/form`,
                })
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>
