<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item :label="$t('temperature.sn')">
            <el-input v-model="form.sn" clearable></el-input>
        </el-form-item>
<!--        <el-form-item :label="$t('temperature.alarm_type')">-->
<!--            <el-select v-model="form.alarm_type" clearable :placeholder="$t('temperature.depPlaceHolder')">-->
<!--                <el-option-->
<!--                        v-for="item in options"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                </el-option>-->
<!--            </el-select>-->
<!--        </el-form-item>-->

        <el-form-item :label="$t('temperature.alarmtime')">
            <el-date-picker v-model="form.alarmtime"
                            style="float: right; padding: 3px 10px"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            :range-separator="$t('temperature.to')"
                            :start-placeholder="$t('temperature.startDate')"
                            :end-placeholder="$t('temperature.startDate')">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
                options: [
                    {
                        value: '1',
                        label: '低温报警'
                    },
                    {
                        value: '2',
                        label: '高温报警'
                    }
                ]
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>
