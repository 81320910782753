const data = {
    formdata: [
        {
            "name": "customerName",
            "type": "input",
            "title": "客户名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "customerCode",
            "type": "input",
            "title": "客户代码",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "cods",
            "type": "input",
            "title": "统一社会信用代码",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "cityId",
            "type": "number",
            "title": "所在城市id",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required"]
        },
        {
            "name": "email",
            "type": "input",
            "title": "联系邮箱",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "contactName",
            "type": "input",
            "title": "联系人姓名",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "phone",
            "type": "input",
            "title": "联系人姓名",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "bankAccountName",
            "type": "input",
            "title": "银行账户名称",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "bankAccountNumber",
            "type": "input",
            "title": "银行账户号码",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "bankName",
            "type": "input",
            "title": "银行名称",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "bankBranch",
            "type": "input",
            "title": "银行分支行名称",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "invoiceTitle",
            "type": "input",
            "title": "发票抬头",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "taxNumber",
            "type": "input",
            "title": "税号",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "taxAddress",
            "type": "input",
            "title": "税号注册的地址",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "taxBankName",
            "type": "input",
            "title": "税号开户行",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "taxBankNumber",
            "type": "input",
            "title": "税号开户行银行账号",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "taxPhone",
            "type": "input",
            "title": "税号注册时的联系电话",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required", "minlength", "maxlength"],
            "fieldValidateRulesMinlength": "2",
            "fieldValidateRulesMaxlength": "64"
        },
        {
            "name": "electricFenceId",
            "type": "number",
            "title": "电子围栏id",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
            "fieldValidateRules": ["required"]
        },
        {
            "name": "certificateUrl",
            "type": "input",
            "title": "证件照片地址",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false,
        },
    ],
    table: {
        columns: [
            {title: '客户名称', field: 'customerName', width: '150'},
            {title: '客户代码', field: 'customerCode', width: '150'},
            {title: '统一社会信用代码', field: 'cods', width: '200'},
            {title: '所在城市', field: 'cityId', width: '150'},
            {title: '联系邮箱', field: 'email', width: '150'},
            {title: '联系人姓名', field: 'contactName', width: '130'},
            {title: '联系人电话', field: 'phone', width: '130'},
            {title: '银行账户名称', field: 'bankAccountName', width: '200'},
            {title: '银行账户号码', field: 'bankAccountNumber', width: '200'},
            {title: '银行名称', field: 'bankAccountNumber', width: '200'},
            {title: '银行账户号码', field: 'bankName', width: '130'},
            {title: '银行分支行名称', field: 'bankBranch', width: '130'},
            {title: '发票抬头', field: 'invoiceTitle', width: '150'},
            {title: '税号', field: 'taxNumber', width: '150'},
            {title: '税号注册的地址', field: 'taxAddress', width: '150'},
            {title: '税号开户行', field: 'taxBankName', width: '150'},
            {title: '税号开户行银行账号', field: 'taxBankNumber', width: '150'},
            {title: '税号注册时的联系电话', field: 'taxPhone', width: '180'},
            {title: '电子围栏', field: 'electricFenceId', width: '160'},
            {title: '证件照片地址', field: 'certificateUrl', width: '160'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
