<template>
    <zk-admin-table :title="$t('transport.shopList')" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="160" :label="$t('transport.operation')" fixed="right">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    {{$t('transport.modification')}}
                </el-button>
                <el-button slot="reference" type="text" size="mini" @click="handleDel(scope.row)" icon="el-icon-delete">{{$t('transport.delete')}}</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog
                    :title="$t('transport.delete')"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">{{$t('transport.hint')}}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">{{ $t('transport.cancel') }}</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">{{ $t('transport.confirm') }}</el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>
<script>
    import data from "./data"
    import SearchForm from './widgets/searchForm'
    export default {
        name: 'shop',
        path: '/main/transport/shop',
        components: {
            SearchForm
        },
        data() {
            return {
                search: {},
                delmodel: false,
                loading: false,
                delId: null,
                dialogVisible: false,
                searchVisible: true,
                table: data.table,
            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            async getData() {

            },
            async handleDel(row) {
                this.delId = row.id
                this.delmodel = true
            },
            async delSubmit() {


            },
            handleUpdate(row) {

            },
            handleAdd() {

            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>
