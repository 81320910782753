<template>
    <div>
        <el-form ref="form" :model="form" label-position="top" :rules="rules">
            <el-form-item label="工作状态" prop="work_status">
                <el-switch
                        v-model="form.work_status"
                        active-value="1"
                        inactive-value="0"
                        placeholder=""/>
            </el-form-item>
            <el-divider content-position="left"></el-divider>
            <el-form-item :label="$t('device.cabotage.title')" prop="period">
                <el-radio-group v-model="form.period"  @change="cabotageChange">
                    <el-radio :label="1">{{ $t('device.cabotage.day') }}</el-radio>
                    <el-radio :label="2">{{ $t('device.cabotage.week') }}</el-radio>
                    <el-radio :label="3">{{ $t('device.cabotage.twoWeek') }}</el-radio>
                    <el-radio :label="4">{{ $t('device.cabotage.month') }}</el-radio>
                    <el-radio :label="5">{{ $t('device.cabotage.threeMonth') }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-card class="box-card">
                <el-divider content-position="left">{{ $t('device.batterySetting.title') }}</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item :label="$t('device.batterySetting.alarm')">
                                <el-input v-model.number="form.battery_min">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">{{ $t('device.networkSetting.title') }}</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item :label="$t('device.networkSetting.searchTime')" prop="sounet">
                                <el-input v-model.number="form.sounet" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">温度配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="温度范围" prop="tempMin">
                                <el-input v-model.number="form.temp_min" placeholder="">
                                    <template slot="append">℃</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="温度范围" prop="tempMax">
                                <el-input v-model.number="form.temp_max" placeholder="">
                                    <template slot="append">℃</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="温度校准" prop="tempCalibration">
                                <el-input v-model.number="form.temp_calibration" placeholder="">
                                    <template slot="append">℃</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="温度记录间隔" prop="tempInterval">
                                <el-input v-model.number="form.temp_interval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>

                    </el-col>
                </el-row>
                <el-divider content-position="left">湿度配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="湿度范围" prop="humMin">
                                <el-input v-model.number="form.hum_min" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="湿度范围" prop="humMax">
                                <el-input v-model.number="form.hum_max" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="湿度校准" prop="humCalibration">
                                <el-input v-model.number="form.hum_calibration" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="湿度记录间隔" prop="humInterval">
                                <el-input v-model.number="form.hum_interval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">光感配置</el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="光感范围" prop="brightMin">
                                <el-input v-model.number="form.bright_min" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="光感范围" prop="brightMax">
                                <el-input v-model.number="form.bright_max" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item label="光感记录间隔" prop="brightInterval">
                                <el-input v-model.number="form.bright_interval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-divider content-position="left">{{ $t('device.gpsSetting.title') }}
                    <el-switch
                            v-model="form.gpsSwitch"
                            active-value="1"
                            inactive-value="0"
                            placeholder=""/>
                </el-divider>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-col :span="10">
                            <el-form-item :label="$t('device.gpsSetting.interval')" prop="gpsInterval">
                                <el-input v-model.number="form.gps_interval" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item :label="$t('device.gpsSetting.logInterval')" prop="logGpsReport">
                                <el-input v-model.number="form.log_gps_report" placeholder="">
                                    <template slot="append">{{ $t('device.setting.minute') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <div v-if="collisionDiv">
                    <el-divider content-position="left">{{ $t('device.collisionSetting.title') }}
                        <el-switch
                                v-model="form.collisionSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""/>
                    </el-divider>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-col :span="10">
                                <el-form-item :label="$t('device.collisionSetting.collisionInterval')" prop="collisionInterval">
                                    <el-input v-model.number="form.collisionInterval" placeholder="">
                                        <template slot="append">{{ $t('device.setting.minute') }}</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item :label="$t('device.collisionSetting.collisionRange')" prop="collisionRange">
                                    <el-input v-model="form.collisionRange" placeholder="">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </div>
                <div v-if="startStopDiv">
                    <el-divider content-position="left">{{ $t('device.startStopSetting.title') }}
                        <el-switch
                                v-model="form.startStopSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""/>
                    </el-divider>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-col :span="10">
                                <el-form-item :label="$t('device.startStopSetting.startStopInterval')" prop="startStopInterval">
                                    <el-input v-model.number="form.startStopInterval" placeholder="">
                                        <template slot="append">{{ $t('device.setting.minute') }}</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item :label="$t('device.startStopSetting.startStopRange')" prop="startStopRange">
                                    <el-input v-model="form.startStopRange" placeholder="">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </div>
                <div v-if="startStopDiv">
                    <el-divider content-position="left">长时间停车休眠
                        <el-switch
                                v-model="form.sleepSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""/>
                    </el-divider>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-col :span="10">
                                <el-form-item :label="$t('device.startStopSetting.stopTime')" prop="sleepTime">
                                    <el-input v-model.number="form.sleepTime" placeholder="">
                                        <template slot="append">{{ $t('device.setting.minute') }}</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="休眠后上报间隔" prop="sleepInterval">
                                    <el-input v-model="form.sleepInterval" placeholder="">
                                        <template slot="append">{{ $t('device.setting.minute') }}</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </div>
                <div v-if="tiltDiv">
                    <el-divider content-position="left">{{ $t('device.tiltSetting.title') }}
                        <el-switch
                                v-model="form.tiltSwitch"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""/>
                    </el-divider>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-col :span="10">
                                <el-form-item :label="$t('device.tiltSetting.tiltXRange')" prop="tiltXRange">
                                    <el-input v-model.number="form.tiltXRange" placeholder="">
                                        <template slot="append">°</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item :label="$t('device.tiltSetting.tiltYRange')" prop="tiltYRange">
                                    <el-input v-model.number="form.tiltYRange" placeholder="">
                                        <template slot="append">°</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :span="24">
                            <el-col :span="10">
                                <el-form-item :label="$t('device.tiltSetting.tiltInterval')" prop="tiltInterval">
                                    <el-input v-model.number="form.tiltInterval" placeholder="">
                                        <template slot="append">{{ $t('device.setting.minute') }}</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item :label="$t('device.tiltSetting.tiltDetection')" prop="tiltDetection">
                                    <el-input v-model.number="form.tiltDetection" placeholder="">
                                        <template slot="append">{{ $t('device.setting.minute') }}</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-card class="box-card">
                <el-divider content-position="left">{{ $t('device.ShopSetting.title') }}</el-divider>
                <el-form-item :label="$t('device.ShopSetting.shop')" prop="shop">
                    <el-select v-model="form.shopList" multiple filterable>
                        <el-option
                                clearable="true"
                                v-for="item in shopOptions"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                    <el-button @click="close">{{$t('temperature.cancel')}}</el-button>
                    <el-button @click="formSubmit" type="primary"
                               :loading="loading"
                               :disabled="loading">{{$t('temperature.confirm')}}
                    </el-button>
                </el-form-item>
            </el-card>
        </el-form>
    </div>
</template>
<script>
    export default {
        props: {
            form: {
                default: {}
            }
        },
        data() {
            return {
                loading: false,
                startStopDiv: true,
                collisionDiv: true,
                tiltDiv: true,
                nfcOptions: [],
                shopOptions: [],
                rules: {
                    // batteryMin: [
                    //     {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    // ],
                    // gpsInterval: [
                    //     {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    // ],
                    // logGpsReport: [
                    //     {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    // ],
                    // searchTime: [
                    //     {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    // ],
                    // startStopRange: [
                    //     {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    // ],
                    // startStopInterval: [
                    //     {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    // ],
                    stopTime: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    collisionRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    collisionInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltXRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltYRange: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltInterval: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    tiltDetection: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            cabotageChange(val) {
                switch (val) {
                    case 1:
                        this.startStopDiv = true
                        this.collisionDiv = true
                        this.tiltDiv = true
                        this.form.gpsInterval = 12
                        this.form.logGpsReport = 60
                        this.form.tiltInterval = 6
                        this.form.startStopInterval = 6
                        this.form.collisionInterval = 6
                        this.form.searchTime = 6
                        this.form.startStopSwitch = "1"
                        this.form.collisionSwitch = "1"
                        this.form.tiltSwitch = "1"
                        break;
                    case 2 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.gpsInterval = 12
                        this.form.logGpsReport = 60
                        this.form.tiltInterval = 12
                        this.form.startStopInterval = 12
                        this.form.collisionInterval = 12
                        this.form.searchTime = 60
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        this.form.stopTime = 30
                        break;
                    case 3 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.gpsInterval = 30
                        this.form.logGpsReport = 60
                        this.form.tiltInterval = 30
                        this.form.startStopInterval = 30
                        this.form.collisionInterval = 30
                        this.form.searchTime = 30
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        break;
                    case 4 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.gpsInterval = 60
                        this.form.logGpsReport = 240
                        this.form.tiltInterval = 60
                        this.form.startStopInterval = 60
                        this.form.collisionInterval = 60
                        this.form.searchTime = 240
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        break;
                    case 5 :
                        this.startStopDiv = false
                        this.collisionDiv = false
                        this.tiltDiv = false
                        this.form.gpsInterval = 60
                        this.form.logGpsReport = 600
                        this.form.tiltInterval = 60
                        this.form.searchTime = 600
                        this.form.startStopInterval = 60
                        this.form.collisionInterval = 60
                        this.form.startStopSwitch = "0"
                        this.form.collisionSwitch = "0"
                        this.form.tiltSwitch = "0"
                        break;
                }
            },
            formSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$emit('formSubmit', this.form)
                    }
                });
            },
            close(){
                this.$emit('close')
            },
        }
    }
</script>
<style scoped>
    .formborder{
        border-bottom: 1px solid #e4e7ed;
    }
    /deep/ .el-form-item__label {
        font-weight: bold;
    }
</style>
