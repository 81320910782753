<template>
    <el-dialog :title="$t('transport.titleh')" :visible="visible" width="60%" @close="handleClose">
        <el-form ref="form" :model="form"  label-width="120px">
            <el-row>
                <div v-for="(item, index) in formData" :key="index">
                    <el-col :span="item.span?item.span:24">
                        <el-form-item :label="$t('transport.'+item.name)"
                                      :prop="item.required?item.name:''"
                                      :rules="item.required?[{ required: true, message: $t('transport.required'),trigger: 'change'}]:[]">
                            <el-date-picker
                                    v-model="form[item.name]"
                                    v-if="item.type==='date'"
                                    value-format="yyyy-MM-dd"
                                    :placeholder="$t('transport.depPlaceHolder')">
                            </el-date-picker>
                            <el-date-picker
                                    v-model="form[item.name]"
                                    v-if="item.type==='datetime'"
                                    value-format="yyyy-MM-ddThh:mm:ss"
                                    :placeholder="$t('transport.depPlaceHolder')">
                            </el-date-picker>
                            <el-radio-group
                                    v-else-if="item.type==='radio'"
                                    v-model="form[item.name]"
                            >
                                <el-radio :label="iteme.label" :key="iteme.label"
                                          v-for="iteme in item.options"></el-radio>
                            </el-radio-group>
                            <el-input
                                    :disabled="item.disabled"
                                    autocomplete="off"
                                    v-model="form[item.name]"
                                    v-else-if="item.type==='input'"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                            </el-input>
                            <el-input
                                    :disabled="item.disabled"
                                    v-model="form[item.name]"
                                    autocomplete="off"
                                    type="hidden"
                                    v-else-if="item.type==='hidden'"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}</template>
                            </el-input>
                            <el-input
                                    :disabled="item.disabled"
                                    v-model="form[item.name]"
                                    autocomplete="off"
                                    type="number"
                                    v-else-if="item.type==='number'"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                            </el-input>
                            <el-input
                                    :disabled="item.disabled"
                                    v-model="form[item.name]"
                                    autocomplete="off"
                                    type="number"
                                    v-else-if="item.type==='number'"
                                    :placeholder="$t('transport.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                            </el-input>
                            <el-input
                                    v-else-if="item.type==='textarea'"
                                    type="textarea"
                                    :disabled="item.disabled"
                                    :maxlength="item.maxlength"
                                    show-word-limit
                                    :rows="item.rows"
                                    v-model="form[item.name]"
                                    :placeholder="$t('transport.inPlaceHolder')"
                            >
                            </el-input>
                            <el-select
                                    v-else-if="item.type==='select'"
                                    style="width: 100%"
                                    filterable
                                    v-model="form[item.name]"
                                    :placeholder="$t('transport.depPlaceHolder')"
                            >
                                <el-option
                                        v-for="o in item.options"
                                        :key="o.id"
                                        :label="o[item.label]"
                                        :value="o[item.key]"
                                >
                                </el-option>
                            </el-select>
                            <span v-else>
                                <span></span>
                            </span>
                        </el-form-item>
                    </el-col>
                </div>
            </el-row>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="handleClose">{{$t('transport.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('transport.confirm')}}
                </el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>

<script>
    import data from "../../data"
    export default {
        props: {
            form: {
                default: {}
            },
            visible:{
                default: false
            }
        },
        data() {
            return {
                loading: false,
                formData: data.formdata
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                try {
                    const resp = await this.$http.post(`/company/role/`, this.form)
                    if (resp.data.status != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success(this.$t('transport.success'))
                        this.$emit('update')
                        this.$emit('close')
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>
