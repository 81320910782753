const data = {
    formdata: [
        {

            "name": "departmentIds",
            "type": "departmentIds",
            "title": "所属部门",
            required: true,
            placeholder: "请选择",
            value: "",
            disabled: false
        },
        {

            "name": "name",
            "type": "input",
            "title": "名称",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "sn",
            "type": "input",
            "title": "设备号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "passcode",
            "type": "input",
            "title": "厂家识别码",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "type",
            "type": "select",
            "title": "设备类型",
            required: true,
            placeholder: "请输入",
            value: "",
            key: "label",
            disabled: false,
            options:[
                {'value':"thermometer",'label':"温度计"},
                {'value':"lora",'label':"LORA"},
                {'value':"logi",'label':"Alpha-Logi"},
            ]
        },
        {
            "name": "comment",
            "type": "textarea",
            "title": "备注",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '设备名称', field: 'name', width: ''},
            {title: '设备号', field: 'sn', width: ''},
            {title: '设备类型', field: 'type', width: ''},
            {title: '备注', field: 'comment', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
