 const data = {
    menuItems: [
        {
            id: '10',
            label: 'menu.dashboard',
            path: '/main/dashboard',
            icon: 'el-icon-view',
        },
        {
            id: '30',
            label: 'menu.company',
            path: '/main/company',
            icon: 'el-icon-film',
            children: [
                {
                    id: '30-1',
                    label: 'menu.company_info',
                    path: '/main/company/info',
                },
                {
                    id: '30-2',
                    label: 'menu.company_department',
                    path: '/main/company/department',
                },
                {
                    id: '30-3',
                    label: 'menu.company_role',
                    path: '/main/company/role',
                },
                {
                    id: '30-4',
                    label: 'menu.company_user',
                    path: '/main/company/user',
                },
                {
                    id: '30-5',
                    label: 'menu.alarm',
                    path: '/main/system/alarm',
                },
                {
                    id: '30-6',
                    label: 'menu.consign-entities',
                    path: '/main/system/consign-entities',
                },
            ]
        },
        {
            id: '40',
            label: 'menu.transport',
            path: '/main/transport',
            icon: 'el-icon-film',
            children: [
                {
                    id: '40-1',
                    label: 'menu.goods',
                    path: '/main/transport/goods',
                },
                {
                    id: '40-2',
                    label: 'menu.buckets',
                    path: '/main/transport/buckets',
                },
                {
                    id: '40-3',
                    label: 'menu.customer',
                    path: '/main/transport/customer',
                },
                {
                    id: '40-4',
                    label: 'menu.carrier',
                    path: '/main/transport/carrier',
                },
                {
                    id: '40-5',
                    label: 'menu.vehicle',
                    path: '/main/transport/vehicle',
                },
                {
                    id: '40-6',
                    label: 'menu.driver',
                    path: '/main/transport/driver',
                },
                {
                    id: '40-7',
                    label: 'menu.electric-fence',
                    path: '/main/transport/electric-fence',
                },
                {
                    id: '40-8',
                    label: 'menu.vehicle-type',
                    path: '/main/transport/vehicle-type',
                },
                {
                    id: '40-9',
                    label: 'menu.shop',
                    path: '/main/transport/shop',
                },
                {
                    id: '40-10',
                    label: 'menu.dis',
                    path: '/main/transport/dis',
                },
                {
                    id: '40-11',
                    label: 'menu.delivery-employee',
                    path: '/main/transport/delivery-employee',
                },
                {
                    id: '40-12',
                    label: 'menu.delivery-area',
                    path: '/main/transport/delivery-area',
                },
                {
                    id: '40-13',
                    label: 'menu.order',
                    path: '/main/transport/order',
                },
            ]
        },
        {
            id: '50',
            label: 'menu.device',
            path: '/main/device',
            icon: 'el-icon-film',
            children: [
                // {
                //     id: '50-1',
                //     label: 'menu.device-apbind',
                //     path: '/main/devices/apbind',
                // },
                {
                    id: '80',
                    label: 'menu.logiPlus',
                    path: '/main/devices/logiPlus',
                    // icon: 'el-icon-position',
                    children: [
                        {
                            id: '80-1',
                            label: 'menu.logiPlus-name',
                            path: '/main/devices/logiPlus/logiPlus',
                            icon: '',
                            children: [
                                {
                                    id: '80-1-2',
                                    label: 'menu.logplusList',
                                    path: '/main/devices/logiPlus/logiPlus',
                                },
                                {
                                    id: '80-1-3',
                                    label: 'menu.logiPlus-monitoring',
                                    path: '/main/devices/logiPlus/monitoring',
                                },
                                {
                                    id: '80-1-4',
                                    label: 'menu.logiPlus-locate',
                                    path: '/main/devices/logiPlus/locate',
                                },
                                {
                                    id: '80-1-5',
                                    label: 'menu.logiPlus-record',
                                    path: '/main/devices/logiPlus/record',
                                },
                                {
                                    id: '80-1-1',
                                    label: 'menu.logiPlus-alarm',
                                    path: '/main/devices/logiPlus/alarm',

                                },
                            ]
                        },
                        {
                            id: '80-2',
                            label: 'menu.logiPlusCat1',
                            path: '/main/devices/logiPlusCat1',
                            icon: '',
                            children: [
                                {
                                    id: '80-2-1',
                                    label: 'menu.logiPlusCat1-info',
                                    path: '/main/devices/logiPlus/logiPlusCat1',

                                },
                                {
                                    id: '80-2-2',
                                    label: 'menu.logiPlusCat1-locate',
                                    path: '/main/devices/logiPlus/logiPlusCat1/locate',

                                },
                                {
                                    id: '80-2-3',
                                    label: 'menu.logiPlusCat1-record',
                                    path: '/main/devices/logiPlus/logiPlusCat1/record',

                                },
                                {
                                    id: '80-2-4',
                                    label: 'menu.logiPlusCat1-alarm',
                                    path: '/main/devices/logiPlus/logiPlusCat1/alarm',

                                },
                            ]
                        },
                    ]
                },
                {
                    id: '90',
                    label: 'menu.alphaLock',
                    path: '/main/devices/alphaLock',
                    // icon: 'el-icon-lock',
                    children: [
                        {
                            id: '90-1',
                            label: 'menu.alphaLockList',
                            path: '/main/devices/alphaLock/alphaLock',
                        },
                        {
                            id: '90-2',
                            label: 'menu.alphaLockTrack',
                            path: '/main/devices/alphaLock/locate',
                        },
                        {
                            id: '90-3',
                            label: 'menu.alphaLockRecord',
                            path: '/main/devices/alphaLock/record',
                        },
                    ]
                },
                {
                    id: '100',
                    label: 'menu.cvs',
                    path: '/main/devices/cvs',
                    // icon: 'el-icon-film',
                    children: [
                        {
                            id: '100-1',
                            label: 'menu.cvsList',
                            path: '/main/devices/cvs/cvs',
                        },
                        {
                            id: '100-2',
                            label: 'menu.cvsTrack',
                            path: '/main/devices/cvs/locate',
                        },
                        {
                            id: '100-3',
                            label: 'menu.cvsOperation',
                            path: '/main/devices/cvs/operation',
                        },
                        {
                            id: '100-4',
                            label: 'menu.cvsRecord',
                            path: '/main/devices/cvs/record',
                        },
                        {
                            id: '100-5',
                            label: 'menu.cvsTask',
                            path: '/main/devices/cvs/task',
                        },
                        {
                            id: '100-6',
                            label: 'menu.cvsAlarm',
                            path: '/main/devices/cvs/alarm',
                        },
                    ]
                },
                {
                    id: '110',
                    label: 'menu.cvsst',
                    path: '/main/devices/cvsst',
                    // icon: 'el-icon-film',
                    children: [
                        {
                            id: '110-1',
                            label: 'menu.cvsstList',
                            path: '/main/devices/cvsst/cvsst',
                        },
                        {
                            id: '110-2',
                            label: 'menu.cvsstalarm',
                            path: '/main/devices/cvsst/alarm',
                        },
                        {
                            id: '110-3',
                            label: 'menu.cvsstOperation',
                            path: '/main/devices/cvsst/operation',
                        },
                        {
                            id: '110-4',
                            label: 'menu.cvsstTask',
                            path: '/main/devices/cvsst/task',
                        },

                    ]
                },
                {
                    id: '70',
                    label: 'menu.temperature',
                    path: '/main/devices/temperature',
                    // icon: 'el-icon-film',
                    children: [
                        {
                            id: '70-1',
                            label: 'menu.temperature-connect',
                            path: '/main/devices/temperature/connect',
                            icon: '',
                            children: [
                                {
                                    id: '70-1-1',
                                    label: 'menu.temperature-beacon',
                                    path: '/main/devices/temperature/beacon',
                                },
                                {
                                    id: '70-1-2',
                                    label: 'menu.temperature-record',
                                    path: '/main/devices/temperature/beacon/record',
                                },
                                {
                                    id: '70-1-3',
                                    label: 'menu.temperature-alarm',
                                    path: '/main/devices/temperature/beacon/alarm',
                                },

                            ]
                        },
                        {
                            id: '70-2',
                            label: 'menu.temperature-lora',
                            path: '/main/devices/temperature/lora',
                            icon: '',
                            children: [
                                {
                                    id: '70-2-1',
                                    label: 'menu.temperature-lora',
                                    path: '/main/devices/temperature/lora',

                                },
                                {
                                    id: '70-2-2',
                                    label: 'menu.temperature-lora-record',
                                    path: '/main/devices/temperature/lora/record',

                                },
                                {
                                    id: '70-2-3',
                                    label: 'menu.temperature-lora-alarm',
                                    path: '/main/devices/temperature/lora/alarm',

                                },

                            ]
                        },
                        {
                            id: '70-3',
                            label: 'menu.temperature-apmanage',
                            path: '/main/devices/temperature/ap',
                            icon: '',
                            children: [
                                {
                                    id: '70-2-1',
                                    label: 'menu.temperature-ap',
                                    path: '/main/devices/temperature/ap',

                                },
                                {
                                    id: '70-3-2',
                                    label: 'menu.temperature-ap-record',
                                    path: '/main/devices/temperature/ap/record',

                                },
                            ]
                        },
                    ]
                },
                {
                    id: '120',
                    label: 'menu.keyBox',
                    path: '/main/devices/keyBox',
                    // icon: 'el-icon-film',
                    children: [
                        {
                            id: '120-1',
                            label: 'menu.keybox-List',
                            path: '/main/devices/keybox/keybox',
                        },
                        {
                            id: '120-2',
                            label: 'menu.keybox-location',
                            path: '/main/devices/keybox/location',
                        },
                        {
                            id: '120-3',
                            label: 'menu.keybox-operation',
                            path: '/main/devices/keybox/operation',
                        },
                        {
                            id: '120-4',
                            label: 'menu.keybox-record',
                            path: '/main/devices/keybox/record',
                        },

                    ]
                },
            ]
        },
        {
            id: '60',
            label: 'menu.system',
            path: '/main/system',
            icon: 'el-icon-film',
            children: [
                {
                    id: '60-1',
                    label: 'menu.dictType',
                    path: '/main/system/dictType',
                },
                {
                    id: '60-2',
                    label: 'menu.dict',
                    path: '/main/system/dict',
                },
                // {
                //     id: '60-3',
                //     label: 'menu.menu',
                //     path: '/main/system/menu',
                // },
            ]
        },
        {
            id: '120',
            label: 'menu.oplog',
            path: '/main/oplog',
            icon: 'el-icon-notebook-1',
        },
        // {
        //     id: '130',
        //     label: 'menu.routes',
        //     path: '/main/routes',
        //     icon: 'el-icon-notebook-1',
        // }
    ],
    toList() {
        let newArr = []
        this.menuItems.forEach(e => {
            newArr.push({id: e.id, menuName: e.label})
            if (e.children) {
                e.children.forEach(c => {
                    newArr.push({id: c.id, menuName: c.label, parentMenuId: e.id})
                })
            }
        })
        return newArr
    },
    filterArrForKey(arr, key, value) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey(item.children, key, value)
                }
            }
            if (value.includes(item[key])) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    filterChecked(arr, key) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterChecked(item.children)
                }
            }
            return item[key]
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    }
}

export default data
