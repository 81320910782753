<template>
    <zk-admin-table :title="$t('company.roleList')" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="200" :label="$t('transport.operation')" fixed="right">
            <template slot-scope="scope">
                <el-button
                        icon="el-icon-setting"
                        size="mini"
                        type="text"
                        @click="opdendrawer(scope.row)"
                >{{$t('company.permission')}}
                </el-button>
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    {{$t('transport.modification')}}
                </el-button>
                <el-button slot="reference" type="text" size="mini" @click="handleDel(scope.row)" icon="el-icon-delete">{{$t('transport.delete')}}</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
            <el-dialog
                    :title="$t('transport.delete')"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">{{$t('transport.hint')}}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">{{ $t('transport.cancel') }}</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">{{ $t('transport.confirm') }}</el-button>
                </span>
            </el-dialog>
        </template>
        <template v-slot:drawer>
            <el-drawer :visible.sync="drawer" :with-header="false" size="40%" :title="$t('company.roleSetting')">
                <el-tabs class="role-box" type="border-card">
                    <el-tab-pane :label="$t('company.roleMenu')">
                        <div style="text-align: right">
                            <el-button class="filter-item" type="primary" size="mini"
                                       @click="setpermissions">{{$t('company.save')}}
                            </el-button>
                        </div>
                        <el-tree
                                :data="menusList"
                                :show-checkbox="true"
                                node-key="id"
                                ref="tree"
                                highlight-current
                                default-expand-all
                                :default-checked-keys="menuList"
                                :props="defaultProps">
                        </el-tree>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('company.dataPermission')">
                        <div style="text-align: right">
                            <el-button class="filter-item" type="primary" size="mini"
                                       @click="setpermissions">{{$t('company.save')}}
                            </el-button>
                        </div>
                        <el-checkbox-group v-model="pckList">
                            <div v-for="(v,k,i) in permissionList" :key="i">
                                <div class="title">{{$t(`permission.${k}`)}}</div>
                                <div class="pmckbox">{{$t(`permission.action`)}}:
                                    <el-checkbox :label="`${k}.${item}`" v-for="(item,j) in v['action']" :key="j">
                                        {{$t(`permission.${item}`)}}
                                    </el-checkbox>
                                </div>
                                <div class="pmckbox">{{$t(`permission.data`)}}:
                                    <el-checkbox :label="`${k}.${item}`" v-for="(item,j) in v['data']" :key="j">
                                        {{$t(`permission.${item}`)}}
                                    </el-checkbox>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </el-tab-pane>
                </el-tabs>
            </el-drawer>
        </template>
    </zk-admin-table>
</template>
<script>
    import data from "./data"
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'
    import menu from "../../../data/menu"
    import permission from '../../../data/permission'

    export default {
        name: 'company_role',
        path: '/main/company/role',
        components: {
            SearchForm,
            DataForm
        },
        data() {
            return {
                loading: false,
                formData: [],
                form: {},
                delmodel: false,
                dialogVisible: false,
                searchVisible: true,
                table: data.table,
                search: {},
                drawer: false,
                menusList: [],
                menuList: [],
                permissionList: [],
                pckList: [],
                delId: null,
                defaultProps: {
                    children: 'children',
                    // eslint-disable-next-line no-unused-vars
                    label: (data, node) => {
                        return this.$t(data.label)
                    },
                },
            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            opdendrawer(row) {
                this.drawer = true
                this.rowclick(row)
            },
            async setpermissions() {
                let menuIds = []
                var dataList = this.$refs.tree.getCheckedNodes();
                let currentNode = this.$refs.tree.getHalfCheckedNodes()
                dataList.forEach(e => {
                    menuIds.push(e.id)
                })
                currentNode.forEach(e => {
                    menuIds.push(e.id)
                })
                this.form.menuIds = menuIds.join()
                this.form.permission = this.pckList.join()
                const resp = await this.$http.post(`/company/role/`, this.form)
                if (resp.data.status !== 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success(this.$t('company.success'))
                    this.drawer = false
                    this.getData()
                }
            },
            uniq(array) {
                var temp = []; //一个新的临时数组
                for (var i = 0; i < array.length; i++) {
                    if (temp.indexOf(array[i]) == -1) {
                        temp.push(array[i]);
                    }
                }
                return temp;
            },
            async rowclick(row) {
                this.form = row
                let menuList = []
                if (row.menuIds) {
                    menuList = row.menuIds.split(",")
                    menu.menuItems.forEach(e => {
                        // 删掉顶级目录，要不这个tree会都选上
                        if (e.children) {
                            menuList.remove(e.id)
                        }
                    })
                }
                if (this.$refs['tree']) {
                    this.$refs['tree'].setCheckedKeys(menuList);
                } else {
                    setTimeout(() => {
                        this.$refs['tree'].setCheckedKeys(menuList);
                    }, 200)
                }
                if(row.permission){
                    this.pckList = row.permission.split(',')
                }
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/company/role/?page=${this.table.page}&size=${this.table.pageSize}`,{params: this.search})
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async handleDel(row) {
                this.delId = row.id
                this.delmodel = true
            },
            async delSubmit() {
                try {
                    const resp = await this.$http.delete(`/company/role/${this.delId}`)
                    if (resp.status != 204) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.$message.success(this.$t('company.delSuccess'))
                        this.delmodel = false
                        this.getData()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }

            },
            getTree() {
                this.menusList = menu.menuItems
                this.permissionList = permission
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            handleAdd() {
                this.setDefaultValue()
                this.dialogVisible = true
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            this.getTree()
            this.getData()
        }
    }
</script>


<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }

    /deep/ .el-tabs--border-card {
        background: #FFF;
        border: inherit;
        box-shadow: inherit;
    }

    .tree {
        border: 1px solid #ebeef5;
        margin-left: 20px;
        margin-top: 10px;
    }

    .title {
        background-color: aliceblue;
        padding: 10px;
    }

    .pmckbox {
        padding: 10px;
    }

    /deep/ .el-checkbox-group {
        font-size: 14px;
    }
</style>
