<template>
    <zk-record-table :title="$t('menu.temperature-record')" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData" ></el-button>
<!--                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"></el-button>-->
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" :disabled="loading"/>
        </template>
<!--        <el-table-column align="center" prop="id" label="设备ID" width="100">-->
<!--        </el-table-column>-->
        <el-table-column align="center" prop="sn_m" label="AP SN">
        </el-table-column>
        <el-table-column align="center" prop="sn" label="温度计SN" v-if="$store.getters.showSn=='1'">
        </el-table-column>
        <el-table-column align="center" prop="device_code" label="温度计别名" v-if="$store.getters.showSnPrefix=='1'">
        </el-table-column>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
<!--        <el-table-column  align="center" width="120" :label="$t('logi.operation')" fixed="right">-->
<!--            <template slot-scope="scope">-->
<!--                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">{{$t('logi.location')}}</el-button>-->
<!--            </template>-->
<!--        </el-table-column>-->
<!--        <template v-slot:download>-->
<!--            <data-down-load :visible="downloadVisible" :form="search" :total="table.total" @close="downloadVisible=false"></data-down-load>-->
<!--        </template>-->
    </zk-record-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    // import DataDownLoad from './widgets/dataDownLoad'
    import data from './data'
    import ZkRecordTable from "../../../../../common/components/zkRecordTable/index";
    export default {
        name: 'device-temperature-record',
        path: '/main/devices/temperature/beacon/record',
        components: {
            ZkRecordTable,
            SearchForm,
            // DataDownLoad
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                downloadVisible: false,
                form: {},
                search:{},
                multipleSelection: [],
                tableData:[],
                pages:1
            }
        },
        methods: {
            doDownLoad() {
                this.downloadVisible = true
            },
            handleTableChange(e) {
                this.table = e
                var page = 0
                if(this.pages > this.table.page){
                    this.getData()
                }else {

                    if ((this.table.page - 1) % 5 == 0) {
                        this.getData()
                    } else {
                        page = (this.table.page % 5)
                        if (page == 0) {
                            page = 5
                        }
                        this.table.data = this.tableData.slice((page - 1) * this.table.pageSize, page * this.table.pageSize)
                    }
                }
                this.pages = this.table.page
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleUpdate(row){
                console.log(row)
            },
            async getData() {
                this.loading = true
                try {
                    var page = 1;
                    if(this.table.page/5>1){
                        if(this.pages > this.table.page){
                            page = parseInt((this.table.page-1)/5)+1
                        }else
                            page = parseInt(this.table.page/5)+1
                    }
                    const resp = await this.$http.get(`/devices/temperature/beacon/record/?page=${page}&pageSize=${this.table.pageSize*5}`, {params: this.search})
                    if (resp.data.status !== 200) {
                        this.loading = false
                        this.$message.error(resp.data.message)
                    } else {
                        this.tableData = resp.data.data.records
                        var pages = (this.table.page%5)
                        if(pages==0){
                            pages = 5
                        }
                        this.table.data = this.tableData.slice((pages - 1) * this.table.pageSize, pages * this.table.pageSize)
                        if(resp.data.data.records.length   == this.table.pageSize*5)
                            this.table.total = page*this.table.pageSize*5 + 1
                        else
                            if (resp.data.data.records.length)
                                this.table.total = (page-1)*this.table.pageSize*5 + resp.data.data.records.length
                            else
                                if(page ==1)
                                    this.table.total = 0
                                else
                                    this.table.total =  (page-1)*this.table.pageSize*5

                        this.loading = false
                    }
                } catch (e) {
                    this.loading = false
                    this.$message.error(e.message)
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
