<template>
    <div>
        <el-form label-width="auto" label-position="top">
            <el-form-item :label="$t('device.selectTime')">
                <el-date-picker
                        v-model="form.daterange"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="left">
                </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('device.selectDevice')">
                <el-input v-model="form.sn" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button style="width: 100%" type="primary" :plain="true" @click="doSearch">
                    {{$t('device.getTracker') }}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {daterange: [], sn: ''},
                pickerOptions: {
                    shortcuts: [{
                        text: this.$t('device.lastWeek'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('device.lastMonth'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$t('device.lastThreeMonths'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            },
            sing(sn){
                this.form.sn = sn
            }
        }
    }
</script>
