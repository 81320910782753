const data = {
    formdata: [
        {

            "name": "area",
            "type": "input",
            "title": "面积",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {

            "name": "consignType",
            "type": "input",
            "title": "收发货类型",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "shareMode",
            "type": "input",
            "title": "共享模式",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "latitude",
            "type": "input",
            "title": "维度",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "longitude",
            "type": "input",
            "title": "经度",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "radius",
            "type": "number",
            "title": "半径",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "address",
            "type": "input",
            "title": "地址",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "desc",
            "type": "input",
            "title": "所属省份",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "cityId",
            "type": "number",
            "title": "城市id",
            required:false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "districtId",
            "type": "number",
            "title": "区id",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "comment",
            "type": "input",
            "title": "备注",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }

    ],
    table: {
        columns: [
            {title: '面积', field: 'area', width: '150'},
            {title: '区域编号', field: 'areanumber', width: '150'},
            {title: '车辆类型名称', field: 'name', width: '150'},
            {title: '收发货类型', field: 'consignType', width: '150'},
            {title: '类型', field: 'type', width: '150'},
            {title: '所属部门', field: 'departmentId', width: '150'},
            {title: '共享模式', field: 'shareMode', width: '150'},
            {title: '半径', field: 'radius', width: '120'},
            {title: '维度', field: 'latitude', width: '150'},
            {title: '经度', field: 'longitude', width: '150'},
            {title: '地址', field: 'address', width: '150'},
            {title: '所属城市', field: 'city', width: '150'},
            {title: '所属省份', field: 'province', width: '150'},
            {title: '区域', field: 'district', width: '150'},
            {title: '备注', field: 'comment', width: '150'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
