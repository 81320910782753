import Vue from 'vue'
import Vuex from 'vuex'
// import http from '../plugins/http'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        app: {
            version: '',
        },
        username: '',
        routerHistory: [],
        messages: [],
        showSnPrefix:'0',
        showSn:'1',
    },
    mutations: {
        set_version(state, data) {
            state.app.version = data.version
        },
        set_username(state, username) {
            localStorage.setItem('username', username)
            state.username = username
        },
        set_token(state, token) {
            localStorage.setItem('token', token)
            state.token = token
        },
        set_showSn(state, showSn) {
            localStorage.setItem('showSn', showSn)
            state.showSn = showSn
        },
        set_showSnPrefix(state, showSnPrefix) {
            localStorage.setItem('showSnPrefix', showSnPrefix)
            state.showSnPrefix = showSnPrefix
        },
        setRouterHistory(state, data) {
            let exist = false
            state.routerHistory.forEach(item=>{
                if(item.path===data.path){
                    exist = true
                }
            })
            if (!exist) {
                state.routerHistory.unshift(data)
            }
            let max = 5
            if (state.routerHistory.length > max) {
                state.routerHistory = state.routerHistory.slice(0, max)
            }
        },
        delRouterHistory(state, index) {
            state.routerHistory.splice(index, 1)
        },
    },
    getters: {
        username: state => {
            return state.username || localStorage.getItem('username')
        },
        token: state => {
            return state.token || localStorage.getItem('token')
        },
        showSnPrefix: state => {
            return state.showSnPrefix || localStorage.getItem('showSnPrefix')
        },
        showSn: state => {
            return state.showSn || localStorage.getItem('showSn')
        },
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        getVersion: ({commit}) => {
            // http.get('/version').then(resp => {
            //     commit('set_version', resp.data)
            // })
        },
        // eslint-disable-next-line no-unused-vars
        login: ({commit}, data) => {
            // return new Promise((resolve, reject) => {
            //     http.post(`/auth/login`, data)
            //         .then(function (res) {
            //             if (res.data.code == 200) {
            //                 commit('set_username', data.username)
            //                 commit('set_token', res.data.data.token)
            //             }
            //             resolve(res.data)
            //         })
            //         .catch((e) => {
            //             reject(e)
            //         })
            // })
        }
    }
})

export default store
