<template>
    <el-dialog :title="$t('logi.modelLabel')" :visible="visible" width="60%" @close="handleClose">
        <el-tabs v-model="activeName">
            <el-tab-pane label="设备详情" name="tab1">
                <tab1></tab1>
            </el-tab-pane>
            <el-tab-pane label="设备配置" name="tab2">
                <el-form :inline="true" ref="form" :model="form" label-width="150px"
                         style="max-height: 400px;overflow: auto">
                    <el-form-item label="工作状态" prop="workStatus">
                        <el-switch
                                v-model="form.workStatus"
                                active-value="1"
                                inactive-value="0"
                                placeholder=""/>
                    </el-form-item>
                    <el-card class="box-card">
                        <el-divider content-position="left">{{ $t('device.batterySetting.title') }}</el-divider>
                        <el-form-item :label="$t('device.batterySetting.alarm')" prop="batteryAlarm">
                            <el-input v-model.number="form.batteryAlarm">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('device.batterySetting.start')" prop="batteryMin">
                            <el-input v-model.number="form.batteryMin">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('device.batterySetting.end')" prop="batteryMax">
                            <el-input v-model.number="form.batteryMax">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>

                        <el-divider content-position="left">{{ $t('device.basicSetting.title') }}</el-divider>
                        <el-form-item :label="$t('device.basicSetting.lock')" prop="lockReportInterval">
                            <el-input v-model.number="form.lockReportInterval" placeholder="">
                                <template slot="append">{{ $t('device.setting.minute') }}</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('device.basicSetting.getParam')" prop="interval">
                            <el-input v-model.number="form.interval" placeholder="">
                                <template slot="append">{{ $t('device.setting.minute') }}</template>
                            </el-input>
                        </el-form-item>
                        <el-divider content-position="left">{{ $t('device.gpsSetting.title') }}</el-divider>
                        <el-form-item :label="$t('device.gpsSetting.interval')" prop="gpsInterval">
                            <el-input v-model.number="form.gpsInterval" placeholder="">
                                <template slot="append">{{ $t('device.setting.minute') }}</template>
                            </el-input>
                        </el-form-item>
                    </el-card>
                    <el-card class="box-card">
                        <el-divider content-position="left">{{ $t('device.ShopSetting.title') }}</el-divider>
                        <el-form-item :label="$t('device.ShopSetting.shop')" prop="shop">
                            <el-select v-model="form.shopList" multiple filterable>
                                <el-option
                                        clearable="true"
                                        v-for="item in shopOptions"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-card>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import Tab1 from './tab1'
    export default {
        components: {
            Tab1,
        },
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            isNew: {
                default: true
            }
        },
        data() {
            return {
                loading: false,
                activeName: 'tab1',
                shopOptions: [],
                alarm_options: []
            }
        },
        methods: {
            formSubmit(form) {
                this.$emit('update')
            },
            handleClose(){
                this.$emit('close')
            }
        },
    }
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
