import formatter from '@/components/formatter'
const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: ''},
            {title: '设备号', field: 'sn', width: ''},
            {title: '用户名称', field: 'user_name', width: ''},
            {title: '电量', field: 'battery', width: '120',formatter: formatter.batteryFormatter},
            {title: '最后通讯时间', field: 'last_seen', width: ''},
            {title: '记录事件', field: 'events', width: '',formatter: formatter.formEvents},
            {title: '锁状态', field: 'lock_state', width: '100',formatter: formatter.formaLock},
            {title: '微信名称', field: 'wx_name', width: ''},
            {title: '手机号', field: 'wx_phone', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
