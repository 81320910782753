<template>
    <zk-admin-table :title="$t('menu.temperature-lora-alarm')" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <!--                <el-button size="mini" icon="el-icon-upload2" circle></el-button>-->
                <!--                <el-button size="mini" icon="el-icon-download" circle></el-button>-->
                <!--                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>-->
                <!--                <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button>-->
                <!--                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button>-->
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         v-if="v.show!=='0'"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column  align="center" width="180" :label="$t('logi.operation')" fixed="right">
            <template slot-scope="scope">
                <el-button v-if="scope.row.longitude" type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-location-outline">{{$t('logi.location')}}</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
        </template>
    </zk-admin-table>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'
    export default {
        name: 'device-lora-alarm',
        path: '/main/devices/temperature/lora/alarm',
        components: {
            SearchForm,
        },
        data() {
            return {
                loading: false,
                table: data.table,
                dialogVisible: false,
                searchVisible: true,
                form: {},
                multipleSelection: [],
            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            handleTableSelect(val) {
                this.multipleSelection = val;
            },
            async handleUpdate(row) {
                this.form = row
                this.form.version = 'E.0.0'
                this.dialogVisible = true
            },
            async getData() {
                try {
                    const resp = await this.$http.get(`/devices/temperature/lora/alarm/?page=${this.table.page}&size=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
