<template>
    <el-dialog :title="$t('logi.modelLabel')" :visible="visible" width="60%" @close="handleClose">
        <el-tabs v-model="activeName">
            <el-tab-pane label="设备详情" name="tab1">
                <tab1></tab1>
            </el-tab-pane>
            <el-tab-pane label="设备信息" name="tab2">
                <el-form class="form" ref="form" :model="form" label-width="100px" size="small">
                    <el-form-item label="设备名称" prop="device_name"
                                  :rules="{ required: true, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.device_name" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="软件版本" prop="sn"
                                  :rules="{ required: true, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.version" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('device.networkSetting.searchTime')" prop="sounet">
                        <el-input v-model.number="form.s_t" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="除霜时间" prop="chushuang"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-time-select placeholder="起始时间"
                                v-model="form.startTime"
                                :picker-options="{ start: '08:30', step: '00:15', end: '18:30' }">
                        </el-time-select>
                        <el-time-select placeholder="结束时间"
                                v-model="form.endTime"
                                :picker-options="{ start: '00:00', step: '00:30', end: '24:00', minTime: form.startTime }">
                        </el-time-select>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="传感器配置" name="tab4">
                <el-form :inline="true" ref="form" :model="form" label-width="150px"
                         style="max-height: 400px;overflow: auto">
                    <el-divider content-position="left">温度设置</el-divider>
                    <div>
                        <el-form-item label="最低温度" prop="tempMin">
                            <el-input v-model.number="form.temperature_min" placeholder="">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="最高温度" prop="tempMax">
                            <el-input v-model.number="form.temperature_max" placeholder="">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="温度校准" prop="tempCalibration">
                            <el-input v-model.number="form.temperature_adjust" placeholder="">
                                <template slot="append">℃</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="温度记录间隔" prop="tempInterval">
                            <el-input v-model.number="form.temperature_interval" placeholder="">
                                <template slot="append">秒</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <el-divider content-position="left">湿度设置</el-divider>
                    <div>
                        <el-form-item label="湿度范围" prop="humMin">
                            <el-input v-model.number="form.humidity_min" placeholder="">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="湿度范围" prop="humMax">
                            <el-input v-model.number="form.humidity_max" placeholder="">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="湿度校准" prop="humCalibration">
                            <el-input v-model.number="form.humidity_adjust" placeholder="">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="湿度记录间隔" prop="humInterval">
                            <el-input v-model.number="form.humidity_interval" placeholder="">
                                <template slot="append">秒</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <el-divider content-position="left">光感设置</el-divider>
                    <div>
                        <el-form-item label="光感范围" prop="brightMin">
                            <el-input v-model.number="form.bright_min" placeholder="">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="光感范围" prop="brightMax">
                            <el-input v-model.number="form.bright_max" placeholder="">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="光感记录间隔" prop="brightInterval">
                            <el-input v-model.number="form.bright_interval" placeholder="">
                                <template slot="append">秒</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <el-divider content-position="left">{{ $t('device.gpsSetting.title') }}
                        <el-switch v-model="form.gps_switch" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-divider>
                    <el-form-item :label="$t('device.gpsSetting.interval')" prop="gpsInterval">
                        <el-input v-model.number="form.gps_interval" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('device.gpsSetting.logInterval')" prop="logGpsReport">
                        <el-input v-model.number="form.gps_nonet_interval" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="left">{{ $t('device.collisionSetting.title') }}
                        <el-switch v-model="form.collision_switch" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-divider>
                    <el-form-item :label="$t('device.collisionSetting.collisionInterval')"
                                  prop="collisionInterval">
                        <el-input v-model.number="form.collision_interval" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('device.collisionSetting.collisionRange')"
                                  prop="collisionRange">
                        <el-input v-model="form.collision_range" placeholder="">
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="left">启停配置
                        <el-switch v-model="form.start_stop_switch" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-divider>
                    <el-form-item :label="$t('device.tiltSetting.tiltInterval')" prop="tiltInterval">
                        <el-input v-model.number="form.start_stop_interval" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('device.tiltSetting.tiltDetection')" prop="tiltDetection">
                        <el-input v-model.number="form.start_stop_range" placeholder="">
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="left">长时间停车休眠
                        <el-switch v-model="form.sleep_switch" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-divider>
                    <el-form-item label="停车休眠" prop="tiltInterval">
                        <el-input v-model.number="form.sleep_time" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="停车休眠上报间隔" prop="tiltDetection">
                        <el-input v-model.number="form.sleep_interval" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="left">倾斜配置
                        <el-switch v-model="form.tilt_switch" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-divider>
                    <el-form-item label="横向倾斜范围" prop="tiltInterval">
                        <el-input v-model.number="form.tilt_x_range" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="纵向倾斜范围" prop="tiltDetection">
                        <el-input v-model.number="form.tilt_y_range" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('device.tiltSetting.tiltInterval')" prop="tiltInterval">
                        <el-input v-model.number="form.tilt_interval" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('device.tiltSetting.tiltDetection')" prop="tiltDetection">
                        <el-input v-model.number="form.tilt_detection" placeholder="">
                            <template slot="append">秒</template>
                        </el-input>
                    </el-form-item>
                    <el-divider content-position="left">电子围栏</el-divider>
                    <el-form-item :label="$t('device.ShopSetting.shop')" prop="shop">
                        <el-select v-model="form.shopList" multiple filterable>
                            <el-option
                                    clearable="true"
                                    v-for="item in shopOptions"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="运输配置" name="tab3">
                <el-form :inline="true" ref="form" :model="form" label-position="left">
                    <el-form-item label="工作状态" prop="work_status">
                        <el-switch v-model="form.work_status" :active-value="1" :inactive-value="0" placeholder=""/>
                    </el-form-item>
                    <el-divider content-position="left"></el-divider>
                    <el-form-item :label="$t('device.cabotage.title')" prop="period">
                        <el-radio-group v-model="form.period">
                            <el-radio :label="1">{{ $t('device.cabotage.day') }}</el-radio>
                            <el-radio :label="2">{{ $t('device.cabotage.week') }}</el-radio>
                            <el-radio :label="3">{{ $t('device.cabotage.twoWeek') }}</el-radio>
                            <el-radio :label="4">{{ $t('device.cabotage.month') }}</el-radio>
                            <el-radio :label="5">{{ $t('device.cabotage.threeMonth') }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-divider content-position="left"></el-divider>
                    <el-form-item label="司机名称" prop="sn"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.driver" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="车排号" prop="sn" :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.car_no" :disabled="!isNew"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="告警配置" name="tab5">
                <el-form ref="form" :model="form" label-position="left">
                    <el-form-item :label="$t('device.batterySetting.alarm')">
                        <el-input v-model.number="form.battery_min">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="报警类型" prop="sn"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-select v-model="form.alarm_type" multiple placeholder="请选择">
                            <el-option
                                    v-for="item in alarm_options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="邮件地址" prop="sn"
                                  :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.alarm_email" :disabled="!isNew"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import Tab1 from './tab1'

    export default {
        components: {
            Tab1,
        },
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            isNew: {
                default: true
            }
        },
        data() {
            return {
                loading: false,
                activeName: 'tab4',
                shopOptions: [],
                alarm_options: []
            }
        },
        methods: {
            async formSubmit() {
                const result = true //this.$refs['myforms'].validateHandle()
                if (result) {
                    try {
                        let url = `/devices/logiPlus/${this.form.id}`
                        const resp = await this.$http.put(url, this.form)
                        if (resp.data.status != 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('device.success'))
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.$emit('update')
                // this.$emit('close')
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
