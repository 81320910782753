<template>
    <div>
        <el-row style="margin:20px 0">
            <el-col :span="6" style="padding:10px">
                <div class="el-card is-hover-shadow">
                    <div class="el-card__body" style="padding: 0px;">
                        <div class="grid-content grid-con-1">
                            <i class="el-icon-s-custom grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{counts.user||'--'}}</div>
                                <div>{{$t('dashboard.userCount')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="6" style="padding:10px">
                <div class="el-card is-hover-shadow">
                    <div class="el-card__body" style="padding: 0px;">
                        <div class="grid-content grid-con-2">
                            <i class="el-icon-s-shop grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{counts.department||'--'}}</div>
                                <div>{{$t('dashboard.deptCount')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="6" style="padding:10px">
                <div class="el-card is-hover-shadow">
                    <div class="el-card__body" style="padding: 0px;">
                        <div class="grid-content grid-con-3">
                            <i class="el-icon-s-flag grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{dev.devCount||'--'}}</div>
                                <div>{{$t('dashboard.deviceCount')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="6" style="padding:10px">
                <div class="el-card is-hover-shadow">
                    <div class="el-card__body" style="padding: 0px;">
                        <div class="grid-content grid-con-4">
                            <i class="el-icon-s-opportunity grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div class="grid-num">{{dev.alarmCount||'--'}}</div>
                                <div>{{$t('dashboard.alarmCount')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row style="margin:30px 0">
            <el-col :span="16">
                <line-chart/>
            </el-col>
            <el-col :span="8">
                <pie-chart/>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    import lineChart from './charts/lineChart'
    import pieChart from './charts/pieChart'
    export default {
        name: 'dashboard',
        path: '/main/dashboard',
        components: {
            lineChart,
            pieChart
        },
        data() {
            return {
                dev:{
                    devCount:0,
                    alarmCount:0
                },
                counts: {
                }
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get(`/statistics/counts`)
                this.counts = resp.data
            },
            async getDevData() {
                const resp = await this.$http.get(`/devices/devcount`)
                this.dev = resp.data

            }
        },
        mounted() {
            this.getData()
            this.getDevData()
        }
    }
</script>
<style scoped>
    .grid-content {
        display: flex;
        align-items: center;
        height: 130px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 14px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 130px;
        text-align: center;
        line-height: 130px;
        color: #fff;
    }

    .grid-con-3 .grid-con-icon {
        background: rgb(195, 191, 226);
    }

    .grid-con-3 .grid-num {
        color: rgb(195, 191, 226);
    }

    .grid-con-1 .grid-con-icon {
        background: rgb(128, 176, 210);
    }

    .grid-con-1 .grid-num {
        color: rgb(128, 176, 210);
    }

    .grid-con-2 .grid-con-icon {
        background: rgb(141, 210, 198);
    }

    .grid-con-2 .grid-num {
        color: rgb(141, 210, 198);
    }

    .grid-con-4 .grid-con-icon {
        background: rgb(144, 147, 153);
    }

    .grid-con-4 .grid-num {
        color: rgb(144, 147, 153);
    }
</style>
