const data = {
    table: {
        columns: [
            {title: '起点', field: 'containerName', width: ''},
            {title: '终点', field: 'containerCode', width: ''},
            {title: '承运公司', field: 'length', width: ''},
            {title: '距离(km)', field: 'width', width: ''},
            {title: '要求最大运输时长(h)', field: 'width', width: '180'},
            {title: '备注', field: 'width', width: ''},
            {title: '修改人', field: 'width', width: ''},
            {title: '修改时间', field: 'width', width: ''},

        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
