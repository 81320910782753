<template>
    <div>
        {{test}}
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="用户名">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
                <el-button>取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: 'test',
        path: '/main/test',
        data() {
            return {
                test: 'test',
                form: {},
            }
        },
        methods: {
            async onSubmit(){
                const resp = await this.$http.post('/company/user/', this.form)
                console.log(resp)
            }
        },
        mounted() {
            this.$http.get('/company/user/').then(resp => {
                console.log(resp)
            })
        }
    }
</script>
