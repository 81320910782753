<template>
    <div>
        <el-row>
            <el-form :model="dataform" :rules="rules" ref="dataform" label-width="200px" style="padding-bottom:80px"
            >
                <el-row class="title">
                    <span>{{$t('transport.idPhoto')}}</span>
                </el-row>
                <el-row>
                    <el-form-item label="证件照片">
                        <div style="display: flex">
                            <div>
                                <zk-upload-image v-model="dataform.idCardUrl1" :title="$t('transport.idCardUrl1')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.idCardUrl2" :title="$t('transport.idCardUrl2')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.driverCertUrl1" :title="$t('transport.driverCertUrl1')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.driverCertUrl2" :title="$t('transport.driverCertUrl2')"></zk-upload-image>
                            </div>
                            <div style="padding-left: 20px">
                                <zk-upload-image v-model="dataform.qualificationCertificateUrl" :title="$t('transport.congYeUrl')"></zk-upload-image>
                            </div>
                        </div>
                    </el-form-item>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.basicInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.driverName')"  prop="driverName">
                            <el-input v-model="dataform.driverName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="16">
                            <el-form-item :label="$t('transport.anchoredObject')" prop="carrierCode">
                                <el-input v-model="dataform.anchoredObject">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.departmentId')">
                            <treeselect
                                    v-model="dataform.department"
                                    :options="depList"
                                    style="width: 100%;"
                                    :placeholder="$t('transport.depPlaceHolder')"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.phone')" prop="phone">
                            <el-input v-model="dataform.phone">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.gender')">
                            <el-radio-group v-model="dataform.gender">
                                <el-radio label="男">{{$t('transport.man')}}</el-radio>
                                <el-radio label="女">{{$t('transport.woman')}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="8">
                            <el-form-item :label="$t('transport.nationalId')">
                                <el-input v-model="dataform.nationalId">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('transport.classAllowed')">
                                <el-input v-model="dataform.classAllowed">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('transport.issueAuthority')">
                                <el-input v-model="dataform.issueAuthority">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="8">
                            <el-form-item :label="$t('transport.drivingLicense')">
                                <el-date-picker
                                        v-model="dataform.issueDate"
                                        type="date"
                                        style="width: 100%"
                                        :placeholder="$t('transport.depPlaceHolder')">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('transport.endLicenseExpiration')">
                                <el-date-picker
                                        v-model="dataform.licenceExpiryDate"
                                        type="date"
                                        style="width: 100%"
                                        :placeholder="$t('transport.depPlaceHolder')">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('transport.age')">
                                <el-input v-model="dataform.age" type="number">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.drivingAge')">
                            <el-input v-model="dataform.drivingAge" type="number">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.qualificationCertificate')">
                            <el-input v-model="dataform.qualificationCertificate">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.accountInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankAccountName')">
                            <el-input v-model="dataform.bankAccountName" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankAccountNumber')">
                            <el-input v-model="dataform.bankAccountNumber" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankName')">
                            <el-input v-model="dataform.bankName" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.bankBranch')">
                            <el-input v-model="dataform.bankBranch" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <el-row>
            <div class="add">
                <el-button @click="$router.go(-1)">{{ $t('transport.cancel') }}</el-button>
                <el-button type="primary" @click="formSubmit">{{ $t('transport.confirm') }}</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {
        name: 'driver',
        path: ['/main/transport/driver/form', '/main/transport/driver/form/:pk'],
        components: {
            Treeselect
        },
        data() {
            return {
                dataform: {
                },
                dialogVisible: false,
                Options: [],
                depList: [],
                rules: {
                    driverName: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            async formSubmit() {
                var result = 0
                this.$refs['dataform'].validate((valid) => {
                    if (valid) {
                        result = 1
                    }
                })
                if (result) {
                    try {
                        this.dataform.email = "84048133@qq.com"
                        this.dataform.weChat = "222"
                        const resp = await this.$http.post(`/transport/driver/`, this.dataform)
                        if (!resp.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.$message.success(this.$t('transport.success'))
                            this.$router.push({
                                path: `/main/transport/driver`,
                            })
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }

                }
            },
            async getData() {
                try {
                    let pk = this.$route.params.pk
                    if (pk) {
                        const resp = await this.$http.get(`/transport/driver/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.dataform = resp.data.data
                            if (!this.dataform.company) {
                                this.dataform["company"] = {id: ''}
                            }
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            treeChange(arr) {
                return arr.map(item => {
                    if (item.children && item.children.length > 0) {
                        this.treeChange(item.children)
                    } else {
                        delete item.children
                    }
                    return item
                })
            },
            async getDep() {
                try {
                    const resp = await this.$http.get(`/company/department/tree/`)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        const data = this.walk(resp.data.data.children, (data) => ({
                            ...data,
                            label: data.node.departmentName,
                            value: data.node.id,
                        }), 0);
                        this.depList = this.treeChange(data)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            walk(list, callback, deep = 0) {
                return list.map(it => {
                    const result = callback({...it}, deep);
                    if (it.children) {
                        result.children = this.walk(it.children, callback, deep + 1);
                    }
                    return result;
                });
            },
        },
        mounted() {
            this.getDep()
            this.getData()
        },
    }
</script>

<style scoped>
    .add {
        position: fixed;
        bottom: 10px;
        left: 270px;
        right: 27px;
        text-align: center;
        background-color: #fff;
        border-radius: 0 !important;
        border: 1px solid #e6e6e6;
        height: 55px !important;
        line-height: 55px;
        color: #234;
        z-index: 100;
    }

    /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #EBEEF5;
        background-color: aliceblue;
    }

    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 5px;
    }

    .tabeldata {
        border: 1px solid red;
    }

    /deep/ .el-upload--picture-card {
        line-height: inherit;
        display: grid;
        text-align: center;
        align-items: center;
    }
</style>
