<template>
    <zk-admin-table :title="$t('transport.basketsList')" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="160" :label="$t('transport.operation')" fixed="right">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    {{$t('transport.modification')}}
                </el-button>
                <el-button slot="reference" type="text" size="mini" @click="handleDel(scope.row)" icon="el-icon-delete">
                    {{$t('transport.delete')}}
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogVisible=false" @update="getData"></data-form>
            <el-dialog
                    :title="$t('transport.delete')"
                    :visible.sync="delmodel"
                    width="30%"
            >
                <span style="text-align: center;">{{$t('transport.hint')}}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="delmodel = false" size="mini">{{ $t('transport.cancel') }}</el-button>
                  <el-button type="primary" @click="delSubmit" size="mini">{{ $t('transport.confirm') }}</el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>
<script>
    import data from "./data"
    import SearchForm from './widgets/searchForm'
    import DataForm from './widgets/dataForm'

    export default {
        name: 'buckets',
        path: '/main/transport/buckets',
        components: {
            SearchForm,
            DataForm
        },
        data() {
            return {
                search: {},
                delmodel: false,
                loading: false,
                form: {},
                delId: null,
                searchVisible: true,
                dialogVisible: false,
                table: data.table,

            }
        },
        methods: {
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleSearch(e) {
                this.search = e
                this.table.page = 1
                this.getData()
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/transport/container/?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.table.data = resp.data.data.records
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            async handleDel(row) {
                this.delId = row.id
                this.delmodel = true
            },
            async delSubmit() {
                try {
                    const resp = await this.$http.delete(`/transport/container/${this.delId}`)
                    if (resp.status != 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        this.$message.success(this.$t('transport.delSuccess'))
                        this.delmodel = false
                        this.getData()
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }

            },
            handleUpdate(row) {
                var temp = JSON.stringify(row)
                this.form = JSON.parse(temp)
                if (this.form.volume)
                    this.form.volume = this.form.volume / 1000
                if (this.form.innerVolume)
                    this.form.innerVolume = this.form.innerVolume / 1000
                this.dialogVisible = true
            },
            handleAdd() {
                this.form = {}
                this.dialogVisible = true
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }

    .length /deep/ .el-input__inner {
        border: 1px solid white;
    }

    .length {
        width: 100%;
        align-items: center;
    }
</style>
