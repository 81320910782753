<template>
    <v-chart ref="chart1" :options="options" :autoresize="true" style="width: 100%;height: 100%;min-height: 400px"></v-chart>
</template>

<script>
    export default {
        name: "pieChart",
        data() {
            return {
                options: {
                    title: {
                        text: this.$t("dashboard.sourcePieChart"),
                        left: "center"
                    },
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    // legend: {
                    //     orient: "vertical",
                    //     left: "left",
                    //     data: [
                    //         "上海佐相信息科技有限公司",
                    //         "上海新帆实业有限公司",
                    //         "上海够乐信息科技有限公司",
                    //         "XX科技有限公司",
                    //         "YY科技有限公司"
                    //     ]
                    // },
                    series: [
                        {
                            name: "分布",
                            type: "pie",
                            radius: "55%",
                            center: ["50%", "60%"],
                            data: [
                                { value: 0, name: "" },
                                { value: 0, name: "" },
                                { value: 0, name: "" },
                                { value: 0, name: "" },
                                { value: 0, name: "" }
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)"
                                }
                            }
                        }
                    ]
                }
            };
        }
    };
</script>

