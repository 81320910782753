<template>
    <zk-search-form-inline :form="form" @search="doSearch" :disabled="disabled" ref="form">
        <el-form-item :label="$t('temperature.apsn')">
            <el-input v-model.trim="form.sn_m" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('temperature.snS')" v-if="$store.getters.showSn!='0'">
            <el-input v-model.trim="form.sn" clearable></el-input>
        </el-form-item>
        <el-form-item label="温度计别名" v-if="$store.getters.showSnPrefix!='0'">
            <el-input v-model.trim="form.device_code" clearable></el-input>
        </el-form-item>
<!--        <el-form-item :label="$t('temperature.xnum')">-->
<!--            <el-input v-model="form.box_number" clearable></el-input>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('temperature.formatTime')" prop="daterange" :rules="{ type:'array', required: true, message: '不能为空', trigger: ['change', 'blur']}">
            <el-date-picker v-model="form.daterange"
                            style="float: right; padding: 3px 10px"
                            :clearable="false"
                            :picker-options="pickerOptions"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :range-separator="$t('temperature.to')"
                            :start-placeholder="$t('temperature.startDate')"
                            :end-placeholder="$t('temperature.startDate')">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        props: {
            disabled: null
        },
        data() {
            let now = new Date(new Date().setHours(23, 59, 59, 59));
            let start = new Date(new Date().setHours(0, 0, 0, 0));
            now = now.format("yyyy-MM-dd hh:mm:ss");
            start = start.format("yyyy-MM-dd hh:mm:ss");
            return {
                form: {
                    daterange: [start,now],
                },
                queryParams:{taskStart:""},
                pickerOptions: {
                    onPick: (time) => {
                        // //此处的time为选择日期时的结果，time为一个对象，有两个值，一个是maxDate,一个是minDate,详细见下图，
                        // //以下判断是只选择了一个值的时候，也就是默认的开始值。
                        //当第一时间选中才设置禁用
                        if (time.minDate && !time.maxDate) {
                            this.queryParams.taskStart = time.minDate
                        }
                        if (time.maxDate) {
                            this.queryParams.taskStart = ''
                        }
                    },
                    disabledDate: (time) => {
                        let timeOptionRange = this.queryParams.taskStart
                        let secondNum = 60 * 60 * 24 * 1 * 1000
                        if (timeOptionRange) {
                            return time.getTime() > timeOptionRange.getTime() + secondNum || time.getTime() < timeOptionRange.getTime() - secondNum
                        }
                    }
                }
            }
        },
        methods: {
            reset() {
                this.form = {}
            },
            doSearch() {
                if(this.form.daterange.length==0){
                    this.$message.warning("时间必填项")
                    return
                }
                var temp = JSON.stringify(this.form)
                temp = JSON.parse(temp)
                var now  = new Date().format("yyyy-MM-dd hh:mm:ss");
                if(this.form.daterange[1]>now)
                    temp.daterange[1] = now
                this.$emit('search', temp)
            }
        },
        mounted() {
            this.doSearch()
        }
    }
</script>

