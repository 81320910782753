<template>
    <div>
        <zk-search-form-inline :form="form" @search="$emit('search', form)">
                <el-form-item :label="$t('logi.sn')">
                    <el-input v-model="form.sn"></el-input>
                </el-form-item>
                <el-form-item :label="$t('device.lastSeen')">
                    <el-date-picker
                            v-model="form.daterange"
                            style="width: 240px"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="-"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('device.lockState')" prop="lockState">
                    <el-select v-model="form.lockState" clearable style="width: 240px">
                        <el-option v-for="(v, k, i) in lockStateOptions" :key="i" :label="v" :value="k"/>
                    </el-select>
                </el-form-item>
        </zk-search-form-inline>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {},
                lockStateOptions:{
                    0: '钥匙盒状态为关',
                    1: '钥匙盒状态为开',
                    2: '关盒成功',
                    8: '开盒成功，盒子关闭',
                    17: '开盒失败，钥匙盒已打开',
                    9: '开盒成功，盒子打开',
                }
            }
        },
        methods: {
            reset() {

            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

