const data = {
    formdata: [
        {

            "name": "driverName",
            "type": "input",
            "title": "司机姓名",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "phone",
            "type": "input",
            "title": "联系电话",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "weChat",
            "type": "input",
            "title": "微信号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "email",
            "type": "input",
            "title": "邮件",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "nationalId",
            "type": "input",
            "title": "身份证号",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "gender",
            "type": "input",
            "title": "性别",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "registerDate",
            "type": "date",
            "title": "注册日期",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "issueDate",
            "type": "date",
            "title": "发证日期",
            required:false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "licenceExpiryDate",
            "type": "date",
            "title": "驾驶证证有效期日期",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "issueAuthority",
            "type": "date",
            "title": "发证机关",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "classAllowed",
            "type": "date",
            "title": "准驾车型",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "qualificationCertificate",
            "type": "date",
            "title": "从业资格证号",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "company",
            "type": "select",
            "title": "公司",
            required: false,
            placeholder: "请选择",
            value: "",
            disabled: false,
            label:"companyName",
            key: "id",
            options:[
            ]
        },
    ],
    table: {
        columns: [
            {title: '司机姓名', field: 'driverName', width: '120'},
            {title: '性别', field: 'gender', width: '120'},
            {title: '身份证号', field: 'nationalId', width: '150'},
            {title: '年龄', field: 'age', width: '100'},
            {title: '联系电话', field: 'phone', width: '150'},
            {title: '驾龄', field: 'weChat', width: '150'},
            {title: '所属部门', field: 'email', width: '150'},
            {title: '挂靠对象', field: 'gender', width: '150'},
            {title: '行驶证有效开始日期', field: 'registerDate', width: '200'},
            {title: '驾驶证有效结束日期', field: 'issueDate', width: '200'},
            {title: '发证机关', field: 'issueAuthority', width: '200'},
            {title: '从业资格证号', field: 'qualificationCertificate', width: '200'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
