<template>
    <div class="app-container">
        <el-row :gutter="10">
            <el-col :span="17" class="card-box">
                <el-card>
                    <Map :center="center" :trackerList="trackerList" :zoom="zoom" ref="map"></Map>
                </el-card>
            </el-col>
            <el-col :span="7" class="card-box">
                <el-card>
                    <div slot="header"><span>{{ $t('device.devices') }}</span></div>
                    <p></p>
                    <search-form @search="handleSearch" ref="search"/>
                    <div class="">
                        <el-table v-loading="loading" :data="logiPlusList" max-height="700" style="width: 100%">
                            <el-table-column :label="$t('device.devices')" align="center" prop="lastSeen"
                                             height="100px">
                                <template slot-scope="scope">
                                    <p @click="handleRowSelected(scope.row)" style="cursor: pointer">{{ scope.row.sn }}</p>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center;margin: 20px">
                            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                                           :total="total" :page-size="pageSize" @current-change="handleCurrentChange"
                                           @size-change="handleSizeChange"/>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import SearchForm from './widgets/searchForm'
    import Map from '../../../commponents/map'
    export default {
        name: 'logiPlusCat1-track',
        path: '/main/devices/logiPlus/logiPlusCat1/locate',
        components: {
            SearchForm,
            Map
        },
        data() {
            return {
                center: {lng: 109.45744048529967, lat: 36.49771311230842},
                zoom: 5,
                logiPlusList: [],
                data: [],
                total: 0,
                page: 1,
                pageSize: 4,
                trackerList: [],
                loading: false,
            }
        },
        methods: {
            handleCurrentChange(val) {
                this.page = val
                this.getLogiPlusList()
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getLogiPlusList()
            },
            async handleSearch(form) {
                if (form.daterange.length != 2) {
                    this.$message.error("请选择日期范围")
                    return
                }
                if (form.device_name == '') {
                    this.$message.error("请选择设备")
                    return
                }
                const resp = await this.$http.get(`/devices/logiPlusCat1/locate/?page=1&pageSize=100`, {params: form})
                this.trackerList = resp.data.data.records.filter(e => e.longitude != null && e.longitude != null)
                if (this.trackerList.length > 0) {
                    this.center = {lng: this.trackerList[0].longitude, lat: this.trackerList[0].latitude}
                    this.zoom = 10
                    this.$refs.map.showPolyshi(this.trackerList)
                }else {
                    this.$refs.map.showPolyshi([])
                    this.$message.warning("没有轨迹数据")
                }
            },
            handleRowSelected(row) {
                this.$refs.search.sing(row.sn);
            },
            async getLogiPlusList() {
                const resp = await this.$http.get(`/devices/logiPlusCat1/?page=${this.page}&pageSize=${this.pageSize}`)
                this.logiPlusList = resp.data.data.records
                this.total = resp.data.data.total
            }
        },
        mounted() {
            this.getLogiPlusList()
        }
    }
</script>

<style scoped>

</style>
