<template>
    <div>
        <el-row>
            <el-form :model="dataform" :rules="rules" ref="dataform" label-width="100px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.firstName')" prop="firstName">
                            <el-input v-model="dataform.firstName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.login')" prop="login">
                            <el-input v-model="dataform.login"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.passwd')" prop="password">
                            <el-input v-model="dataform.passwordHash" type="password"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sapn="24">
                        <el-col :span="8">
                            <el-form-item :label="$t('company.departmentIds')">
                                <treeselect
                                        v-model="dataform.departmentIds"
                                        :options="depList"
                                        style="width: 100%;"
                                        :placeholder="$t('company.deoption')"
                                        :multiple="true"
                                        :default-expand-level="1"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('company.roleIds')">
                                <el-select v-model="dataform.roleIds" clearable multiple
                                           :placeholder="$t('company.depPlaceHolder')"
                                           style="width: 100%">
                                    <el-option
                                            v-for="item in roleList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('company.phone')">
                                <el-input v-model="dataform.phone" style="width:100%"
                                          :placeholder="$t('company.inPlaceHolder')">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('company.email')">
                            <el-input v-model="dataform.email">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="$t('company.ccEmails')">
                            <el-input v-model="dataform.ccEmails"
                                      type="textarea"
                                      maxlength="200"
                                      show-word-limit
                                      :rows="4"
                                      :placeholder="$t('company.textPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
        </el-row>
        <el-row>
            <div class="add">
                <el-button @click="$router.go(-1)">{{$t('company.cancel')}}</el-button>
                <el-button type="primary" @click="formSubmit">{{ $t('company.confirm')}}</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {
        name: 'user-form',
        path: ['/main/company/user/form', '/main/company/user/form/:pk'],
        components: {
            Treeselect
        },
        data() {
            return {
                mineStatusValue: [],
                defaultProps: {
                    children: 'children',
                    // eslint-disable-next-line no-unused-vars
                    label: (data, node) => {
                        return data.node.departmentName
                    },
                },
                treeId: '',
                treeLabel: '',
                dataform: {},
                Options: [],
                add: true,
                roleList: [],
                tableData: [],
                depList: [],
                rules: {
                    login: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    firstName: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: this.$t('company.inPlaceHolder'), trigger: 'blur'},
                    ],
                    roleId: [
                        {required: true, message: this.$t('company.depPlaceHolder'), trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            treeChange(arr) {
                return arr.map(item => {
                    if (item.children && item.children.length > 0) {
                        this.treeChange(item.children)
                    } else {
                        delete item.children
                    }
                    return item
                })
            },
            walk(list, callback, deep = 0) {
                return list.map(it => {
                    const result = callback({...it}, deep);
                    if (it.children) {
                        result.children = this.walk(it.children, callback, deep + 1);
                    }
                    return result;
                });
            },
            async formSubmit() {
                const valid = await this.$refs['dataform'].validate()
                if (valid) {
                    try {
                        console.log(this.dataform)
                        this.dataform["activated"] = true
                        const resp = await this.$http.post('/company/user/', this.dataform)
                        if (resp.data.status !== 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.$message.success(this.$t('company.success'))
                            this.$router.push({
                                path: `/main/company/user`,
                            })
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }

                }
            },
            async getData() {
                try {
                    let pk = this.$route.params.pk
                    if (pk) {
                        this.add = false
                        const resp = await this.$http.get(`/company/user/${pk}`)
                        if (resp.data.status !== 200) {
                            this.$message.error(resp.data.message)
                        } else {
                            resp.data.data.password = ''
                            this.dataform = resp.data.data
                            delete this.dataform.password_hash
                            // if (this.dataform.longitude) {
                            //     this.makerCenter(this.dataform.longitude, this.dataform.latitude)
                            // }
                            if (this.dataform.roleIds) {
                                let ids = this.dataform.roleIds
                                ids = ids.map(item => {
                                    return +parseInt(item);
                                });
                                this.dataform.roleIds = ids
                            }
                            if (this.dataform.departmentIds) {
                                let ids = this.dataform.departmentIds
                                ids = ids.map(item => {
                                    return +parseInt(item);
                                });
                                this.dataform.departmentIds = ids
                            }
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getRole() {
                try {
                    const resp = await this.$http.get(`/company/role/?page=1&size=100&name=`)
                    if (resp.data.status !== 200) {
                        this.roleList = []
                    } else {
                        this.roleList = resp.data.data.records
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getDep() {
                try {
                    const resp = await this.$http.get(`/company/department/tree/`)
                    if (resp.data.status !== 200) {
                        this.$message.error(resp.data.message)
                    } else {
                        const data = this.walk(resp.data.data.children, (data) => ({
                            ...data,
                            label: data.node.departmentName,
                            value: data.node.id,
                        }), 0);
                        this.depList = this.treeChange(data)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getRole()
            this.getDep()
            this.getData()
        },
    }
</script>

<style scoped>
    /deep/ .BMap_cpyCtrl {
        display: none;
    }

    /deep/ .anchorBL {
        display: none;
    }

    .add {
        position: fixed;
        bottom: 10px;
        left: 270px;
        right: 27px;
        text-align: center;
        background-color: #fff;
        border-radius: 0 !important;
        border: 1px solid #e6e6e6;
        height: 55px !important;
        line-height: 55px;
        color: #234;
        z-index: 100;
    }

    /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #EBEEF5;
        background-color: aliceblue;
    }

    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 5px;
    }

    .tabeldata {
        border: 1px solid red;
    }

    /deep/ .el-upload--picture-card {
        line-height: inherit;
        display: grid;
        text-align: center;
        align-items: center;
    }

    .select-tree {
        height: auto;
        max-height: 200px;
        overflow-y: auto;
        background-color: white;
        padding: 0;
    }

    /deep/ .el-form-item__content {
        line-height: 20px;
    }
</style>
