import formatter from '@/components/formatter'

const data = {
    table: {
        columns: [
            {title: 'AP名称', field: 'device_name', width: ''},
            {title: 'MAC 地址', field: 'mac', width: ''},
            {title: 'AP状态', field: 'online', width: '', formatter: formatter.onlineFormatter},
            {title: '上/下线时间', field: 'create_time', width: '200'},
            {title: '地址', field: 'address', width: ''},
            {title: '版本', field: 'version', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
