<template>
    <div>
        <div slot="header"><span>{{ $t('device.map') }}</span></div>
        <baidu-map class="row-col-8" :center="center" :zoom="zoom"
                   @ready="handler"
                   style="height:82vh"
                   :scroll-wheel-zoom='true'>
            <bm-marker :position="{lng:item.gps_longitude, lat:item.gps_latitude}"
                       v-for="(item,index) in trackerList" :key="index" animation="BMAP_ANIMATION_BOUNCE">
            </bm-marker>
        </baidu-map>
    </div>

</template>

<script>
    export default {
        props: {
            trackerList:{
                type: Array,
            },
            center: {
                default: {}
            },
            zoom:{
                type:Number,
                zoom:15
            }
        },
        data() {
            return {
            }
        },
        methods: {
            handler({BMap, map}) {
                this.map = map
                this.BMap = BMap
            },
        }
    }
</script>
