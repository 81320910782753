const data = {
    formdata: [
        {
            "name": "name",
            "type": "input",
            "title": "姓名",
            required: true,
            placeholder: "请输入",
            value: "",
            span:12,
            disabled: false
        },
        {
            "name": "phone",
            "type": "input",
            "title": "手机号",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "departmentId",
            "type": "input",
            "title": "所属部门",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "email",
            "type": "input",
            "title": "邮箱",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "smsEndTime",
            "type": "number",
            "title": "短信接收时间",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "smsStartTime",
            "type": "number",
            "title": "短信接收时间",
            required: true,
            placeholder: "请输入",
            value: [],
            disabled: false
        },
        {
            "name": "emailEndTime",
            "type": "number",
            "title": "邮件接收时间",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "emailStartTime",
            "type": "number",
            "title": "邮件接收时间",
            required: true,
            placeholder: "请输入",
            value: [],
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
            {title: '手机号', field: 'phone', width: ''},
            {title: '邮箱', field: 'email', width: ''},
            {title: '短信接收时间', field: 'smsStartTime', width: ''},
            {title: '邮件接收时间', field: 'emailStartTime', width: ''},
            {title: '所属部门', field: 'departmentId', width: ''},
            {title: '修改时间', field: 'lastModifiedDate', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
