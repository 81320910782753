<template>
    <el-dialog :title="form.sn" :visible="visible" width="60%" @close="close">
        <el-tabs v-model="activeName">
            <el-tab-pane label="设备信息" name="tab1">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="温度计SN" prop="sn" v-if="$store.getters.showSn!='0'" :rules="{ required: true, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.sn" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="温度计别名"  v-if="$store.getters.showSnPrefix!='0'">
                        <el-input v-model="form.device_code" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="版本号" prop="version"
                                  >
                        <el-input v-model.trim="form.version" :disabled="!isNew"></el-input>
                    </el-form-item>
                    <el-form-item label="保温箱号">
                        <el-input v-model.trim="form.box_number"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="参数配置" name="tab2">
                <el-form ref="form" :model="form" label-width="130px">
                    <el-form-item label="温度计记录间隔" prop="report_interval" :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.report_interval" type="number">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="最低温度" prop="temperature_min" :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
                        <el-input v-model="form.temperature_min" type="number">
                            <template slot="append">℃</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="最高温度" prop="temperature_max" :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.temperature_max" type="number"><template slot="append">℃</template></el-input>
                    </el-form-item>
                    <el-form-item label="温度校准" prop="temperature_adjust" :rules="{ required: false, message: '设备号不能为空', trigger: 'blur'}">
                        <el-input v-model="form.temperature_adjust" type="number"><template slot="append">℃</template></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
        </span>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            isNew: {
                default: false
            }
        },
        data() {
            return {
                loading: false,
                activeName: 'tab1'
            }
        },
        methods: {
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                console.log(this.form.device_id)
                let method = this.form.device_id ? 'put' : 'post'
                const resp = await this.$http[method]('/devices/temperature/beacon/', this.form)
                if (resp.data.status != 200) {
                    this.$message.error(resp.data.message)
                } else {
                    this.$message.success(this.$t('device.success'))
                    this.$emit('update')
                    this.$emit('close')
                }
            },
            close() {
                this.$emit("close")
            },
        },
    };
</script>

<style scoped>
    /deep/ .el-dialog__body {
        padding: 0 20px;
    }
</style>
