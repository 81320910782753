import formatter from '@/components/formatter'
const batteryFormatter = (row, column) => {
    const battery = row[column.property]
    try{
        if(parseInt(battery) == 0){
            return battery + '%'
        }
    } catch (e) {
        console.log(e)
    }
    if(battery == null) return "-"
    if(!battery) return "-"
    return battery + '%'
}

const data = {
    table: {
        columns: [
            {title: '记录时间', field: 'last_seen', width: ''},
            {title: '上传时间', field: 'create_time', width: ''},
            {title: '电量', field: 'battery', width: '',formatter: batteryFormatter},
            {title: '温度', field: 'temperature', width: '', formatter: formatter.temperatureFormatter},
            {title: '数据类型', field: 'logs_type', width: '', formatter: formatter.logsFormatter},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
