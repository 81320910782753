<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" :content="form.sn"/>
        <div class="page-search">
            <el-form :inline="true" :model="form" class="form" size="small">
                <el-form-item>
                    <el-date-picker v-model="form.daterange"
                                    type="datetimerange"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    @change="getData"
                                    :range-separator="$t('device.to')"
                                    :start-placeholder="$t('device.startDate')"
                                    :end-placeholder="$t('device.startDate')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button plain type="primary" icon="el-icon-search" @click="getData">
                        {{$t('temperature.search')}}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <v-chart ref="chart1" :options="options" :autoresize="true" style="width: 100%;height: 100%"></v-chart>
    </div>
</template>

<script>
    export default {
        name: 'temperature-beacon-charts',
        path: '/main/devices/temperature/:type/charts/:sn',
        data() {
            let now = new Date()
            let start = new Date()
            start.setMonth(start.getMonth() - 1);
            return {
                form: {
                    sn: '',
                    daterange: [start, now],
                    page: 1,
                    pageSize: 20000
                },
                options: {
                    // tooltip: {
                    //     trigger: 'axis',
                    //     position: function (pt) {
                    //         return [pt[0], '10%'];
                    //     }
                    // },
                    title: {
                        left: 'center',
                        text: ''
                    },
                    toolbox: {
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: []
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '100%']
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 10
                        },
                        {
                            start: 0,
                            end: 10
                        }
                    ],
                    series: [
                        {
                            name: 'Fake Data',
                            type: 'line',
                            symbol: 'none',
                            sampling: 'lttb',
                            itemStyle: {
                                color: 'rgb(255, 70, 131)'
                            },
                            areaStyle: {
                                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgb(255, 158, 68)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(255, 70, 131)'
                                    }
                                ])
                            },
                            data: []
                        }
                    ]
                }
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get(`/devices/temperature/${this.$route.params.type}/record/`, {params: this.form})
                if (resp.data.data.records) {
                    this.options.xAxis.data = []
                    this.options.series[0].data = []
                    resp.data.data.records.forEach(e => {
                        console.log(e.sn)
                        this.options.xAxis.data.push(e.last_seen)
                        this.options.series[0].data.push(e.temperature)
                    })
                }
            }
        },
        mounted() {
            this.form.sn = this.$route.params.sn
            this.options.title.text = this.form.sn
            this.getData()
        }
    }
</script>

<style scoped>
    .page {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;
    }

    .page-search {
        margin-top: 20px;
        background-color: #f7f7f8;
        border: 1px solid #EBEEF5;
        border-bottom: 0px;
        text-align: center;

    }

    .form {
        padding: 7px;
    }

    /deep/ .el-form-item {
        margin-bottom: 0;
    }
</style>
