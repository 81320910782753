<template>
    <div>
        <el-row>
            <el-form :model="dataform" :rules="rules" ref="dataform" label-width="200px" style="padding-bottom:80px"
            >
                <el-row class="title">
                    <span>{{$t('transport.basicInformation')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.name')" prop="name">
                            <el-input v-model="dataform.name">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.code')" prop="code">
                            <el-input v-model="dataform.code">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.defaultAvailableQuantity')" prop="defaultAvailableQuantity">
                            <el-input v-model="dataform.defaultAvailableQuantity">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.vMaxLoad')" >
                            <el-input v-model="dataform.maxLoad">
                                <template slot="append">{{$t('transport.ton')}}</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.maxVolume')">
                            <el-input v-model="dataform.maxVolume">
                                <template slot="append">m<sup>3</sup></template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.issueAuthority')">
                            <el-input v-model="dataform.issueAuthority">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.minLoad')">
                            <el-input v-model="dataform.minLoad" type="number">
                                <template slot="append">{{$t('transport.ton')}}</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item  :label="$t('transport.minVolume')">
                            <el-input v-model="dataform.minVolume">
                                <template slot="append">m<sup>3</sup></template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.typeGoodsAllowed')">
                            <el-input v-model="dataform.typeGoodsAllowed" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.comment')">
                            <el-input v-model="dataform.comment" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="title">
                    <span>{{$t('transport.costetting')}}</span>
                    <i class="el-icon-warning-outline" style="padding-left: 20px;color: #d2d8d9;"></i>
                    <span style="padding-left: 10px;color: #d2d8d9;font-size: 13px">{{$t('transport.costettingtext')}}</span>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.freightRate')">
                            <el-input v-model="dataform.freightRate" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                                <template slot="append">{{$t('transport.unitary')}}/kg</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('transport.spellpointRate')">
                            <el-input v-model="dataform.spellpointRate" style="width:100%"
                                      :placeholder="$t('transport.inPlaceHolder')">
                                <template slot="append">{{$t('transport.unitary')}}/kg</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <el-row>
            <div class="add">
                <el-button @click="$router.go(-1)">{{ $t('transport.cancel') }}</el-button>
                <el-button type="primary" @click="formSubmit">{{ $t('transport.confirm') }}</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'vehicle-type-form',
        path: ['/main/transport/vehicle-type/form', '/main/transport/vehicle-type/form/:pk'],
        data() {
            return {
                dataform: {
                },
                dialogVisible: false,
                Options: [],
                depList: [],
                rules: {
                    name: [
                        {required: true, message: this.$t('transport.inPlaceHolder'), trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            async formSubmit() {
                var result = 0
                this.$refs['dataform'].validate((valid) => {
                    if (valid) {
                        result = 1
                    }
                })
                if (result) {
                    try {
                        const resp = await this.$http.post(`/transport/vehicleType/`, this.dataform)
                        if (!resp.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.$message.success(this.$t('transport.success'))
                            this.$router.push({
                                path: `/main/transport/vehicle-type`,
                            })
                        }
                    } catch (e) {
                        this.$message.error(e.message)
                    }

                }
            },
            async getData() {
                try {
                    let pk = this.$route.params.pk
                    if (pk) {
                        const resp = await this.$http.get(`/transport/vehicleType/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.message)
                        } else {
                            this.dataform = resp.data.data
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            this.getData()
        },
    }
</script>

<style scoped>
    .add {
        position: fixed;
        bottom: 10px;
        left: 270px;
        right: 27px;
        text-align: center;
        background-color: #fff;
        border-radius: 0 !important;
        border: 1px solid #e6e6e6;
        height: 55px !important;
        line-height: 55px;
        color: #234;
        z-index: 100;
    }

    /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #EBEEF5;
        background-color: aliceblue;
    }

    .title {
        margin-bottom: 10px;
        background-color: aliceblue;
        font-size: 15px;
        padding: 5px;
    }

    .tabeldata {
        border: 1px solid red;
    }

    /deep/ .el-upload--picture-card {
        line-height: inherit;
        display: grid;
        text-align: center;
        align-items: center;
    }
</style>
