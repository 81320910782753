<template>
    <div>
        <div>
            <slot name="title">
                <span>{{title}}</span>
            </slot>
        </div>
        <el-upload action="/api/upload/" list-type="picture-card"
                   :show-file-list="false"
                   :headers="uploadHeader"
                   :on-success="handleAvatarSuccess"
                   :before-upload="beforeAvatarUpload">
            <img v-if="imgUrl" :src="imgUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
    </div>
</template>

<script>
    export default {
        name: 'zk-upload-image',
        props: {
            title: {
                type: String,
                default: '上传图片'
            },
            value: {
                type: String,
            },
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                uploadHeader: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                imgUrl: this.value
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.imgUrl = val
                    }
                }

            },
        },
        methods: {
            handleAvatarSuccess(res, file) {
                if (res.code === 200) {
                    this.imgUrl = URL.createObjectURL(file.raw);
                    console.log(res.data)
                    this.$emit('input', res.data)
                    this.$emit('change', res.data)
                } else {
                    this.$message.error(res.msg)
                }
            },
            beforeAvatarUpload(file) {
                console.log(file.type)
                let fileTypes = ['image/jpeg', 'image/png']
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (fileTypes.indexOf(file.type) < 0) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                return true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .avatar {
        width: 100%;
    }
</style>
