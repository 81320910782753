const data = {
    company: {
        action: ['get', 'put'],
        data: ['all', 'dept', 'self'],
    },
    departments: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    roles: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    users: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    alarm: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'consign-entities': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    goods: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    buckets: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    customer: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    carrier: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    vehicle: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    driver: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'electric-fence': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'vehicle-type': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    shop: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    dis: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'delivery-employee': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'delivery-area': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    order: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    devices: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'device-bluetooth': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    'device-lora': {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    dictType: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    dict: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
    oplog: {
        action: ['get', 'post', 'put', 'delete'],
        data: ['all', 'dept', 'self'],
    },
}

export default data
