<template>
    <div class="pagination" v-if="totalPage>1">
        <ul class="el-pager">
            <el-button type="text" style=" padding: 5px 20px;" :disabled="currentPage<=1?true:false" @click="currentPage<=1?'':turn(currentPage-1)"><i class="el-icon el-icon-arrow-left"></i></el-button>
            <li v-for="(i,index) in showPageBtn" :key="index" class="num" :class="{'active':i==currentPage}" v-if="i>0" @click="turn(i)">
               {{i}}
            </li>
            <li v-else-if="i==='-'" class="el-icon more btn-quicknext el-icon-more" :class="{'icon-d-arrow-left':toPrev}" @click="turn(currentPage-1)" @mouseenter="toPrev=true" @mouseleave="toPrev=false"></li>
            <li v-else-if="i==='+'" class="el-icon more btn-quicknext el-icon-more" :class="{'icon-d-arrow-right':toNext}" @click="turn(currentPage+1)" @mouseenter="toNext=true" @mouseleave="toNext=false"></li>
            <li v-if="currentPage<totalPage" @click="turn(showPageBtn[showPageBtn.length-1])"> 加载更多</li>
            <el-button type="text" style=" padding: 5px 20px;" :disabled="currentPage>=totalPage?true:false"  @click="currentPage>=totalPage?'':turn(currentPage+1)"><i class="el-icon el-icon-arrow-right"></i></el-button>
        </ul>
        <el-select v-if="!small" v-model="limitNum" @change="turn(currentPage)" size="mini" style="width: 100px">
            <el-option v-for="item in limitNums" :value="item+' 条/页'" :key="item">{{item}}条/页</el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name:'MyPagination',
        props: {
            currentPage: {//当前页
                type: Number,
                default: 1,
            },
            limit:{//每页显示条数
                type: Number,
                default: 10,
            },
            totalCount:{//总条数
                type: Number,
                required: true
            },
            small:{
                type:Boolean,
                default: false,
            },
            all:{
                type:Boolean,
                default: false,
            }
        },
        data() {
            return {
                goPage:'',
                limitNum:'10 条/页',
                limitNums:[10,20,30,50,100],
                toPrev:false,
                toNext:false,
            }
        },
        created: function () {
            this.initLimitNums();
        },
        computed: {
            totalPage(){
                return Math.ceil(this.totalCount / this.limit)
            },
            showPageBtn() {
                let pageNum = Number(this.totalPage),
                    index = Number(this.currentPage),
                    arr = [];
                if (pageNum <= 7) {
                    for(let i = 1; i <= pageNum; i++) {
                        arr.push(i)
                    }
                    return arr
                }
                if (index < 5) return [1,2,3,4,5,6,7,'+',pageNum]
                if (index >= pageNum -1) return [1,2,'-', pageNum -5,pageNum -4,pageNum -3, pageNum -2, pageNum -1, pageNum]
                if (index === pageNum -2) return [1,2,'-', pageNum -5,pageNum -4,pageNum-3, pageNum-2, pageNum-1, pageNum]
                return [1,'-', index-2,index-1, index, index + 1,index + 2, '+', pageNum]
            }
        },
        methods: {
            initLimitNums() {
                this.limitNum= this.limit + ' 条/页';
                if (!this.limitNums.includes(this.limit)) {
                    this.limitNums.push(this.limit);
                    this.limitNums.sort(function (a, b) {
                        return a-b;
                    });
                }
            },
            //翻页，显示条数变化
            turn(page) {
                let i = parseInt(Number(page));
                if(i<1){
                    i=1;
                }else if(i>this.totalPage){
                    i=this.totalPage;
                }
                var limitNum = this.limitNum
                if(limitNum){
                    limitNum = limitNum.split(" ")[0]
                    if(limitNum) limitNum = parseInt(limitNum)
                }
                if(limitNum!==this.limit&&limitNum!==''){//每页显示条数改变
                    let pages=Math.ceil(this.totalCount / limitNum);
                    if(page>pages){
                        i=pages;
                    }
                    this.$emit('update:limit', limitNum);
                }
                this.$emit('update:currentPage', i);
                this.$emit('sizechange');
            }
        }
    }
</script>
<style>
    .but{
        color: #C0C4CC;
        background-color: #FFF;
        cursor: not-allowed;
        padding: 5px 20px;
    }

</style>
