export default {
    batteryFormatter(row, column){
        const battery = row[column.property]
        try{
            if(parseInt(battery) == 0){
                return battery + '%'
            }
        } catch (e) {
            console.log(e)
        }
        if (!battery) {
            return '-'
        }
        if (battery==null) {
            return '-'
        }
        if (battery <= 100) return battery + '%'
        let level = "";
        if (battery >= 3100) {
            level = 100;
        } else if (battery >= 2500) {
            level = (battery - 2500) / 6;
        } else {
            level = 1;
        }
        if (Math.round(level) <= 0) {
            return '1%';
        } else {
            return Math.round(level) + '%'
        }
    },
    temperatureFormatter(row, column) {
        const temp = row[column.property]
        try{
            if(parseInt(temp) == 0){
                return temp + '℃'
            }
        } catch (e) {
            console.log(e)
        }
        if (temp==null) {
            return '-'
        }
        if (!temp) {
            return '-'
        }
        return temp + '℃'
    },
    VolumeFormatter(row, column) {
        const temp = row[column.property]
        if (temp)
            return temp / 1000
        return temp
    },
    logsFormatter(row, column) {
        const logs = row[column.property]
        if (logs)
            return "实时"
        return "日志"
    },
    humidityFormatter(row, column) {
        const hum = row[column.property]
        try{
            if(parseInt(hum) == 0){
                return hum + '%'
            }
        } catch (e) {
            console.log(e)
        }
        if (hum==null) {
            return '-'
        }
        if (!hum) {
            return '-'
        }
        return hum + '%'
    },
    dictStatus(row, column) {
        const hum = row[column.property]
        if(hum == "1")
            return "停用"
        return "正常"
    },
    collisionFormatter(row, column) {
        const collision = row[column.property]
        if(collision == "1")
            return "碰撞"
        return "正常"
    },
    startstopFormatter(row, column) {
        const startstop = row[column.property]
        if(startstop == "1")
            return "启动"
        return "停止"
    },
    tiltFormatter(row, column) {
        const collision = row[column.property]
        if(collision == "1")
            return "倾斜"
        return "正常"
    },
    workstatusFormatter(row, column) {
        const collision = row[column.property]
        if(collision == 1)
            return "运输中"
        if(collision == 2)
            return "完成"
        if(collision == 0)
            return "空闲"
        return "-"
    },
    holdOpenFormatter(row, column) {
        const holdopen = row[column.property]
        if(holdopen == "1")
            return "打开"
        return "关闭"
    },
    ShouFaType(row, column) {
        const type = row[column.property]
        if (type == '1') return '发货人'
        if (type == '2') return '收货人'
        return '收发货人'
    },
    locateWayFormatter(row, column) {
        const locateWay = row[column.property]
        if (locateWay == 1) return '卫星定位'
        if (locateWay == 2) return '基站定位'
        return '-'
    },
    defaultStr(row, column) {
        const value = row[column.property]
        return value || "-"
    },
    formatLockState(row) {
        let staus = {
            0: '钥匙盒状态为关',
            1: '钥匙盒状态为开',
            2: '关盒成功',
            8: '开盒成功，盒子关闭',
            17: '开盒失败，钥匙盒已打开',
            9: '开盒成功，盒子打开',
        }
        return staus[row.lock_state]
    },
    formatAlarmType(row, column) {
        const value = row[column.property]
        let alarmTypes = {
            0: '',
            1: '横向倾斜',
            2: '纵向倾斜',
            3: '锁报警',
            4: '碰撞报警',
            5: '启停报警',
            6: '启停报警',
            7: '电量报警',
            8: '开盖报警',
            9: '进围栏报警',
            10: '出围栏报警',
            11: '温度低报警',
            12: '温度高报警',
            13: '温度报警',
            14: '湿度报警',
            15: '光感报警',
        }
        return alarmTypes[value]
    },
    formatlockstate(row, column) {
        const value = row[column.property]
        let state = {
            64 :"设备故障，微动开和微动关同时被按下",
            65:"锁已开启，锁杆未拔出",
            66:"设备故障，微动开和微动关同时被按下",
            69:"锁已开启，锁干已被拔出",
            71:"锁已开启，锁杆已被拔出，电机没转到位",
            32:"设备故障，微动开和微动关同时被按下",
            33:"开锁成功，锁杆未拔出",
            34:"开锁失败，电机没转",
            35:"开锁失败，电机没转到位",
            36:"设备故障，微动开和微动关同时被按下",
            37:"开锁成功，锁杆已拔出",
            38:"开锁失败，电机没转，锁杆已被异常拔出",
            39:"开锁失败，电机没转到位",
            16:"设备故障，微动开和微动关同时被按下",
            18:"关锁失败，锁已关闭",
            20:"设备故障，微动开和微动关同时被按下",
            21:"关锁失败，未检测到锁杆插入",
            22:"关锁失败，未检测到锁杆插入",
            23:"关锁失败，未检测到锁杆插入",
            8:"设备故障，微动开和微动关同时被按下",
            9:"关锁失败，电机没转",
            10:"关锁成功",
            11:"关锁失败，电机没转到位 / 微动关损坏",
            12:"设备故障，微动开和微动关同时被按下",
            13:"关锁失败，锁杆中途被拔出，电机没转",
            14:"关锁失败，锁杆中途被拔出",
            15:"关锁失败，锁杆中途被拔出，电机未转到位",
            0:"设备故障，微动开和微动关同时被按下",
            1:"锁状态为开，锁杆已插入",
            2:"锁状态为关，锁杆已插入",
            3:"锁状态无法判断，锁杆已插入",
            4:"设备故障，微动开和微动关同时被按下",
            5:"锁状态为开，锁杆已被拔出",
            6:"锁杆已被异常拔出",
            7:"锁状态无法判断，未检测到锁杆插入",
        }
        if (value == -1){
            return "异常状态"
        }
        return state[value]
    },
    onlineFormatter(row, column) {
        const value = row[column.property]
        let online = {
            0: '离线',
            1: '在线',
            3: '未激活'
        }
        return online[value]
    },
    formatcharger(row, column) {
        const value = row[column.property]
        let online = {
            0: '断开',
            1: '连接',
        }
        return online[value]
    },
    formatSources(row, column) {
        const value = row[column.property]
        let sources = {
            1: '设备',
            2: '日志',
            3: '手机'
        }
        return sources[value]
    },
    formatType(row, column) {
        const value = row[column.property]
        let dict = {
            1:"NFC开锁",
            2:"远程开锁",
            3:"蓝牙开锁",
            4:"自动关锁",
            5:"异常开锁",
            6:"关锁时失败设备自动开锁",
            7:"常开模式下开锁",
            8:"常开模式自动开锁",
        }
        return dict[value]
    },
    formaLock(row, column) {
        const value = row[column.property]
        let dict = {
            1:"开锁成功",
            2:"关锁成功",
            3:"开锁失败",
            4:"关锁失败",
        }
        return dict[value]
    },
    formEvents(row, column) {
        const value = row[column.property]
        let dict = {
            1:"开始充电",
            2:" 自动关锁",
            3:" 异常开锁",
            4:" 密码开锁",
            5 :"远程开锁",
            6 :"结束充电",
            7 :"定时上报",
            8 :"唤醒",
            9 :"异常关锁",
            10  :"开锁成功",
            11  :"关锁成功",
            12  :"开锁异常",
            13  :"关锁异常",
        }
        return dict[value]
    }
}
