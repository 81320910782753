<template>
    <div>
        <el-form ref="form" :model="form" :label-width="labelwidth" label-position="top">
            <el-row>
                <div v-for="(item, index) in formData" :key="index">
                    <el-col :span="item.span?item.span:24">
                        <el-form-item :label="$t('temperature.'+item.name)"
                                      :prop="item.required?item.name:''"
                                      :rules="item.required?[{ required: true, message: $t('temperature.required'),trigger: 'change'}]:[]">
                            <el-date-picker
                                    v-model="form[item.name]"
                                    v-if="item.type==='date'"
                                    value-format="yyyy-MM-dd"
                                    :placeholder="$t('temperature.depPlaceHolder')">
                            </el-date-picker>
                            <el-radio-group
                                    v-else-if="item.type==='radio'"
                                    v-model="form[item.name]"
                            >
                                <el-radio :label="iteme.label" :key="iteme.label"
                                          v-for="iteme in item.options"></el-radio>
                            </el-radio-group>
                            <el-input
                                    :disabled="item.disabled"
                                    autocomplete="off"
                                    v-model="form[item.name]"
                                    v-else-if="item.type==='input'"
                                    :placeholder="$t('temperature.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                            </el-input>
                            <el-input
                                    :disabled="item.disabled"
                                    v-model="form[item.name]"
                                    autocomplete="off"
                                    type="hidden"
                                    v-else-if="item.type==='hidden'"
                                    :placeholder="$t('temperature.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}</template>
                            </el-input>
                            <el-input
                                    :disabled="item.disabled"
                                    v-model="form[item.name]"
                                    autocomplete="off"
                                    type="number"
                                    v-else-if="item.type==='number'"
                                    :placeholder="$t('temperature.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                            </el-input>
                            <el-input
                                    :disabled="item.disabled"
                                    v-model="form[item.name]"
                                    autocomplete="off"
                                    type="number"
                                    v-else-if="item.type==='number'"
                                    :placeholder="$t('temperature.inPlaceHolder')">
                                <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                            </el-input>
                            <el-input
                                    v-else-if="item.type==='textarea'"
                                    type="textarea"
                                    :disabled="item.disabled"
                                    :maxlength="item.maxlength"
                                    show-word-limit
                                    :rows="item.rows"
                                    v-model="form[item.name]"
                                    :placeholder="$t('temperature.inPlaceHolder')"
                            >
                            </el-input>
                            <el-checkbox
                                    v-else-if="item.type==='checkbox'"
                                    v-model="form[item.name]"
                            >
                                {{ item.text }}
                            </el-checkbox>
                            <el-select
                                    v-else-if="item.type==='select'"
                                    style="width: 100%"
                                    filterable
                                    v-model="form[item.name]"
                                    :placeholder="$t('temperature.depPlaceHolder')"
                            >
                                <el-option
                                        v-for="o in item.options"
                                        :key="o.id"
                                        :label="o[item.label]"
                                        :value="o[item.key]"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                    v-else-if="item.type==='company'"
                                    style="width: 100%"
                                    filterable
                                    v-model="form.company.id"
                                    :placeholder="$t('temperature.depPlaceHolder')"
                            >
                                <el-option
                                        v-for="o in item.options"
                                        :key="o.id"
                                        :label="o[item.label]"
                                        :value="o[item.key]"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                    v-else-if="item.type==='select-remote-multiple'"
                                    v-model="form[item.name]"
                                    collapse-tags
                                    multiple
                                    filterable
                                    style="width:100%"
                            >
                                <el-option
                                        v-for="o in item.options"
                                        :key="o.id"
                                        :label="o[item.label]"
                                        :value="o[item.key]"
                                >
                                </el-option>
                            </el-select>
                            <el-select
                                    v-else-if="item.type==='select-allow-create'"
                                    allow-create
                                    filterable
                                    v-model="form[item.name]"
                            >
                                <el-option
                                        v-for="o in item.options"
                                        :key="o.value"
                                        :label="o.label"
                                        :value="o.value"
                                >
                                </el-option>
                            </el-select>
                            <span v-else>
                            <span></span>
                        </span>
                        </el-form-item>
                    </el-col>
                </div>
            </el-row>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="close">{{$t('temperature.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('temperature.confirm')}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props: {
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {
                default: {}
            },
            formData:{
                type:Array
            }
        },
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            formSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$emit('formSubmit', this.form)
                    }
                });
            },
            close(){
                this.$emit("close")
            },
        },
    };
</script>
<style scoped>

    /deep/ .el-radio-button__inner, .el-radio-group {
        display: inline-block;
        line-height: 0.8;
        vertical-align: middle;
    }
    /deep/ .el-form-item__label {
        font-weight: bold;
    }
</style>
