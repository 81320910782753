const data = {
    formdata: [
        {

            "name": "bizTypeId",
            "type": "input",
            "title": "业务类型id",
            required: true,
            placeholder: "请输入",
            value: "",
            span:12,
            disabled: false
        },
        {
            "name": "customerId",
            "type": "input",
            "title": "客户id",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "departmentId",
            "type": "number",
            "title": "订单所属部门id",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "consignerRegionId",
            "type": "number",
            "title": "发货区域id",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "consigneeRegionId",
            "type": "number",
            "title": "收货区域id",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "requiredShippingTime",
            "type": "datetime",
            "title": "要求起运时间",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "requiredReceiptTime",
            "type": "datetime",
            "title": "要求送到时间",
            required: true,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "cargoId",
            "type": "number",
            "title": "货物id",
            span:12,
            required:false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "routeId",
            "type": "number",
            "title": "线路id",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "shippingType",
            "type": "number",
            "title": "配送方式",
            required: false,
            placeholder: "请输入",
            span:12,
            value: "",
            disabled: false
        },
        {
            "name": "comment",
            "type": "textarea",
            "title": "备注",
            required: false,
            span:24,
            rows:4,
            maxlength:200,
            placeholder: "请输入",
            value: "",
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '业务类型', field: 'bizTypeId', width: '120'},
            {title: '客户', field: 'customerId', width: '120'},
            {title: '订单所属部门', field: 'departmentId', width: '120'},
            {title: '发货区域', field: 'consignerRegionId', width: '120'},
            {title: '收货区域', field: 'consigneeRegionId', width: '150'},
            {title: '要求起运时间', field: 'requiredShippingTime', width: '180'},
            {title: '要求送到时间', field: 'requiredReceiptTime', width: '180'},
            {title: '线路', field: 'routeId', width: ''},
            {title: '货物', field: 'cargoId', width: ''},
            {title: '配送方式', field: 'shippingType', width: '160'},
            {title: '备注', field: 'comment', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
