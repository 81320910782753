<template>
    <zk-search-form-inline :form="form" @search="doSearch">
        <el-form-item :label="$t('temperature.sn')" v-if="showSn!='0'">
            <el-input v-model="form.sn" clearable></el-input>
        </el-form-item>
        <el-form-item label="别名" v-if="showSnPrefix!='0'">
            <el-input v-model="form.device_code" clearable></el-input>
        </el-form-item>
            <el-form-item :label="$t('temperature.category')">
                <el-select v-model="form.keywords" clearable :placeholder="$t('temperature.depPlaceHolder')">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('temperature.lastSeen')">
                <el-date-picker v-model="form.daterange"
                                style="float: right; padding: 3px 10px"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                :range-separator="$t('temperature.to')"
                                :start-placeholder="$t('temperature.startDate')"
                                :end-placeholder="$t('temperature.startDate')">
                </el-date-picker>
            </el-form-item>
    </zk-search-form-inline>
</template>

<script>
    export default {
        data() {
            return {
                showSn: localStorage.getItem("showSn"),
                showSnPrefix: localStorage.getItem("showSnPrefix"),
                form: {},
                options: [],
            }
        },
        methods: {
            reset() {
                this.form = {}
            },
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>
