<template>
    <div>
        <div slot="header"><span>{{ $t('device.map') }}</span></div>
        <baidu-map class="row-col-8" :center="center" :zoom="zoom"
                   @ready="handler"
                   style="height:82vh"
                   :scroll-wheel-zoom='true'>
        </baidu-map>
    </div>
</template>

<script>
    import data from './data'
    export default {
        props: {
            center: {
                default: {}
            },
            zoom:{
                type:Number,
                zoom:15
            },
        },
        data() {
            return {
                locations:data,
                markerList:[]
            }
        },
        methods: {
            handler({BMap, map}) {
                this.map = map
                this.BMap = BMap
            },
            showPolyshi(pointList) {
                var that = this
                var data = []
                var allOverlay = that.map.getOverlays();
                for(var j = 0;j<allOverlay.length;j++) {
                    that.map.removeOverlay(allOverlay[j]);
                }
                this.markerList = []
                var location = new that.BMap.Icon(this.locations, new that.BMap.Size(65, 65),{ offset: new that.BMap.Size(10, 25), // 指定定位位置
                    imageOffset: new that.BMap.Size(15, 0)}) // 设置图);
                for (var c = 0; c < pointList.length; c++) {
                    var tmp = new that.BMap.Point(pointList[c].longitude,pointList[c].latitude);
                    data.push(tmp)
                    var marker = new that.BMap.Marker(tmp,{icon:location});
                    that.map.addOverlay(marker);
                    var temp = "序号：" + (pointList.length - c)
                    var label = new that.BMap.Label(temp, {offset: new that.BMap.Size(50, 5)});
                    label.setStyle({
                        color: 'blue',
                        borderRadius: '5px',
                        borderColor: '#ccc',
                        padding: '5px',
                        fontSize: '13px',
                        height: '10px',
                        lineHeight: '10px',
                        fontFamily: '微软雅黑'
                    });
                    marker.setLabel(label);
                }
                var group = Math.floor(data.length / 11);
                var mode = data.length % 11;
                var driving = new that.BMap.DrivingRoute(that.map, {
                    onSearchComplete: function () {
                        // eslint-disable-next-line no-undef
                        if (driving.getStatus() == BMAP_STATUS_SUCCESS) {
                            var plan = driving.getResults().getPlan(0);
                            var num = plan.getNumRoutes();
                            for (var j = 0; j < num; j++) {
                                var pts = plan.getRoute(j).getPath();    //通过驾车实例，获得一系列点的数组
                                var polyline = new that.BMap.Polyline(pts);
                                that.map.addOverlay(polyline);
                            }
                        }
                    }
                });
                for (var i = 0; i < group; i++) {
                    var waypointss = data.slice(i * 11 + 1, (i + 1) * 11);
                    //注意这里的终点如果是11的倍数的时候，数组可是取不到最后一位的，所以要注意终点-1喔。感谢song141的提醒，怪我太粗心大意了~
                    driving.search(data[i * 11], data[(i + 1) * 11 - 1], {waypoints: waypointss});//waypoints表示途经点
                }
                if (mode != 0) {
                    var waypoints = data.slice(group * 11, data.length - 1);//多出的一段单独进行search
                    driving.search(data[group * 11], data[data.length - 1], {waypoints: waypoints});
                }
            },
        }
    }
</script>
