<template>
    <v-chart ref="chart1" :options="options" :autoresize="true" style="width: 100%;height: 100%;min-height: 400px"></v-chart>
</template>

<script>

    export default {
        name: "lineChart",
        data() {
            return {
                options: {
                    title: {
                        text: this.$t('dashboard.sourceChart')
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['CPU Load', ]
                    },
                    grid: {
                        left: '5%',
                        right: '14%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: 'CPU Load',
                            type: 'line',
                            stack: 'Total',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        },
                    ]
                }
            };
        }
    };
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
