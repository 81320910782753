<template>
    <zk-admin-table title="操作记录" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle
                           @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible"/>
        </template>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="$t('device.'+v.title)"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" :label="$t('device.operation')">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleMap(scope.row)" icon="el-icon-edit"
                           v-if="scope.row.gps_latitude">
                    {{$t('device.location')}}
                </el-button>
            </template>
        </el-table-column>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
            <baidu-map class="map" :center="baiduMap.markerPoint" :zoom="16">
                <bm-marker :position="baiduMap.markerPoint" :dragging="true" @click="infoWindowOpen">
                    <bm-info-window :show="baiduMap.show" @close="infoWindowClose" @open="infoWindowOpen"
                                    style="font-size:14px">
                        设备号：{{baiduMap.device_id}} <br>
                        电量：{{baiduMap.battery}}<br>
                        最后通讯时间：{{baiduMap.last_seen}}<br>
                        地址：
                    </bm-info-window>
                </bm-marker>
            </baidu-map>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
    </zk-admin-table>
</template>
<script>
    import SearchForm from './widgets/searchForm'
    import data from './data'

    export default {
        name: 'keybox-operation',
        path: '/main/devices/keybox/operation',
        components: {
            SearchForm,
        },
        form: {},
        data() {
            return {
                baiduMap: {
                    markerPoint: {lng: 116.4, lat: 39.9},
                    show: true,
                },
                loading: false,
                table: data.table,
                searchVisible: true,
                dialogVisible: false
            }
        },
        methods: {
            handleMap(row) {
                console.log(row)
                this.baiduMap.markerPoint.lng = row.gps_longitude
                this.baiduMap.markerPoint.lat = row.gps_latitude
                this.baiduMap.device_id = row.sn
                this.baiduMap.battery = row.battery
                this.baiduMap.last_seen = row.format_time
                this.baiduMap.show = true

                this.dialogVisible = true
            },
            infoWindowClose() {
                this.baiduMap.show = false
            },
            infoWindowOpen() {
                this.baiduMap.show = true
            },
            handleSearch(e) {
                console.log(e, "====")
                this.form = e
            },
            handleTableChange(e) {
                this.table = e
                this.getData()
            },
            handleClose() {
                this.dialogVisible = false
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/devices/keybox/operation?page=${this.table.page}&pageSize=${this.table.pageSize}`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data.data.rows
                        this.table.total = resp.data.data.total
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.loading = false
            },
            handleCurrentChange(val) {
                this.table.page = val
                this.getData()
            },
            handleSizeChange(val) {
                this.table.pageSize = val
                this.getData()
            },
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-table__empty-text {
        min-height: 400px;
        line-height: 400px;
    }
</style>

