<template>
    <div>
        <el-form ref="ruleForm" :model="form" :label-width="labelwidth">
            <el-row>
            <div v-for="(item, index) in formData" :key="index">
                <el-col :span="item.span?item.span:24">
                    <el-form-item :label="$t('device.'+item.name)"
                                  :prop="item.required?item.name:''"
                                  :rules="item.required?[{ required: true, message: $t('device.required'),trigger: 'change'}]:[]">
                        <treeselect
                            v-model="departmentIds"
                            v-if="item.type==='departmentIds'"
                            :options="deptList"
                            style="width: 100%;"
                            :multiple="true"
                            :default-expand-level="1"
                        >
                        </treeselect>
                        <el-date-picker
                            v-model="form[item.name]"
                                v-if="item.type==='date'"
                                value-format="yyyy-MM-dd"
                                :placeholder="$t('device.depPlaceHolder')">
                        </el-date-picker>
                        <el-radio-group
                                v-else-if="item.type==='radio'"
                                v-model="form[item.name]"
                        >
                            <el-radio :label="iteme.label" :key="iteme.label"
                                             v-for="iteme in item.options"></el-radio>
                        </el-radio-group>
                        <el-input
                                :disabled="item.disabled"
                                autocomplete="off"
                                v-model="form[item.name]"
                                v-else-if="item.type==='input'"
                                :placeholder="$t('device.inPlaceHolder')">
                            <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                        </el-input>
                        <el-input
                                :disabled="item.disabled"
                                v-model="form[item.name]"
                                autocomplete="off"
                                type="hidden"
                                v-else-if="item.type==='hidden'"
                                :placeholder="$t('device.inPlaceHolder')">
                            <template v-if="item.slots" slot="append">{{ item.slots }}</template>
                        </el-input>
                        <el-input
                                :disabled="item.disabled"
                                v-model="form[item.name]"
                                autocomplete="off"
                                type="number"
                                v-else-if="item.type==='number'"
                                :placeholder="$t('device.inPlaceHolder')">
                            <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                        </el-input>
                        <el-input
                                :disabled="item.disabled"
                                v-model="form[item.name]"
                                autocomplete="off"
                                type="number"
                                v-else-if="item.type==='number'"
                                :placeholder="$t('device.inPlaceHolder')">
                            <template v-if="item.slots" slot="append">{{ item.slots }}<sup v-if="item.sup">{{ item.sup }}</sup></template>
                        </el-input>
                        <el-input
                                v-else-if="item.type==='textarea'"
                                type="textarea"
                                :disabled="item.disabled"
                                :maxlength="item.maxlength"
                                show-word-limit
                                :rows="item.rows"
                                v-model="form[item.name]"
                                :placeholder="$t('device.inPlaceHolder')"
                        >
                        </el-input>
                        <el-checkbox
                                v-else-if="item.type==='checkbox'"
                                v-model="form[item.name]"
                        >
                            {{ item.text }}
                        </el-checkbox>
                        <el-autocomplete
                                clearable
                                popper-class="my-autocomplete"
                                v-else-if="item.type==='autocomplete'"
                                v-model="form[item.name]"
                                :fetch-suggestions="((queryString,cb)=>{autocompleteSelect(queryString,cb,item)})"
                                :placeholder="$t('device.depPlaceHolder')"
                                @select="handleSelect(item.name,$event)"
                                style="width:100%"
                        >
                            <template v-if="item.tag == 1" slot-scope="{ item }">
                                <div class="name">{{ item.name }}
                                    <span style="font-size: 90%">({{ item.type }})</span>
                                </div>
                            </template>
                        </el-autocomplete>
                        <el-select
                                v-else-if="item.type==='select'"
                                style="width: 100%"
                                filterable
                                v-model="form[item.name]"
                                :placeholder="$t('device.depPlaceHolder')"
                        >
                            <el-option
                                    v-for="o in item.options"
                                    :key="o.id"
                                    :label="o[item.label]"
                                    :value="o[item.key]"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                                v-else-if="item.type==='company'"
                                style="width: 100%"
                                filterable
                                v-model="form.company.id"
                                :placeholder="$t('device.depPlaceHolder')"
                        >
                            <el-option
                                    v-for="o in item.options"
                                    :key="o.id"
                                    :label="o[item.label]"
                                    :value="o[item.key]"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                                v-else-if="item.type==='select-remote-multiple'"
                                v-model="form[item.name]"
                                collapse-tags
                                multiple
                                filterable
                                remote
                                reserve-keyword
                                :placeholder="$t('device.depPlaceHolder')"
                                @change="handleSelect(item.key,$event)"
                                :remote-method="remoteMethod"
                                style="width:100%"
                        >
                            <el-option
                                    v-for="o in item.option"
                                    :key="o.name"
                                    :label="o.name"
                                    :value="o.name"
                            >
                                <span style="float: left">{{ o.name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ o.type }}</span>
                            </el-option>
                        </el-select>
                        <el-select
                                v-else-if="item.type==='select-allow-create'"
                                allow-create
                                filterable
                                v-model="form[item.name]"
                        >
                            <el-option
                                    v-for="o in item.options"
                                    :key="o.value"
                                    :label="o.label"
                                    :value="o.value"
                            >
                            </el-option>
                        </el-select>
                        <span v-else>
                            <span></span>
                        </span>
                    </el-form-item>
                </el-col>
            </div>
            </el-row>
            <el-form-item style="text-align: right;margin-bottom: inherit;" label-width="0px">
                <el-button @click="close">{{$t('device.cancel')}}</el-button>
                <el-button @click="formSubmit" type="primary"
                           :loading="loading"
                           :disabled="loading">{{$t('device.confirm')}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import Treeselect from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        props: {
            formData: {
                default: []
            },
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {
                default: {}
            },
            formSubmit: {},
            close: {},
            btnBgColor: {
                type: String,
                default: "#9089fa"
            },
            btnColor: {
                type: String,
                default: "#ffffff"
            },

        },
        data() {
            return {
                loading: false,
                deptList: [],
                departmentIds: [],
            }
        },
        watch: {
            departmentIds(){
                this.form['departmentIds'] = this.departmentIds
            }
        },
        methods: {
            validateHandle() {
                let flag = null
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        flag = true
                    } else {
                        flag = false
                    }
                });
                return flag
            },
            autocompleteSelect(queryString, cb, item) {
                var url = item.url
                var key = item.key
                this.$http.get(`${url}${queryString}`)
                    .then(({data}) => {
                        for (var i = 0; i < data.data.length; i++) {
                            data.data[i].value = data.data[i][key]
                        }
                        cb(data.data)
                    });
            },
            remoteMethod(query) {
                var that = this
                var url = that.items.url
                if (query !== '') {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                        this.$http.get(`${url}${query}`)
                            .then(resp => {
                                that.items.option = resp.data.data
                            })
                            .catch(() => {
                            })
                    }, 200);
                } else {
                    this.shopList = [];
                }
            },
            handleSelect(key, val) {
                var type = '';
                if (this.items.option) {
                    this.items.option.forEach((item) => {
                        if (val.indexOf(item.name) > -1) {
                            type = item.type
                        }
                    });
                }
                this.$emit("autocompleteSelect", key, val, type);
            },
            walk(list, callback, deep = 0) {
                return list.map(it => {
                    const result = callback({...it}, deep);
                    if (it.children) {
                        result.children = this.walk(it.children, callback, deep + 1);
                    }
                    return result;
                });
            },
            treeChange(arr) {
                return arr.map(item => {
                    if (item.children && item.children.length > 0) {
                        this.treeChange(item.children)
                    } else {
                        delete item.children
                    }
                    return item
                })
            },
            async getDep() {
                try {
                    const resp = await this.$http.get(`/departments/tree`)
                    if (resp.data.code !== 200) {
                        this.$message.error(resp.data.msg)
                    } else {
                        const data = this.walk(resp.data.data.children, (data) => ({
                            ...data,
                            label: data.node.departmentName,
                            value: data.node.id,
                        }), 0);
                        this.deptList = this.treeChange(data)
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
        },
        mounted() {
            if (this.form.departmentIds) this.departmentIds = this.form.departmentIds
            this.getDep()
        }
    };
</script>
<style scoped>

    /deep/ .el-radio-button__inner, .el-radio-group {
        display: inline-block;
        line-height: 0.8;
        vertical-align: middle;
    }
</style>
