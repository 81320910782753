import formatter from '@/components/formatter'
const data = {
    formdata: [
        {

            "name": "containerName",
            "type": "input",
            "title": "周转筐名称",
            required: true,
            placeholder: "请输入",
            value: "",
            span:12,
            disabled: false
        },
        {
            "name": "containerCode",
            "type": "input",
            "title": "周转筐编码",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "length",
            "type": "number",
            "title": "外长度",
            span:12,
            slots:"mm",
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "width",
            "type": "number",
            "title": "外宽度",
            slots:"mm",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "height",
            "type": "number",
            "title": "外高度",
            slots:"mm",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "innerLength",
            "type": "number",
            "title": "内长度",
            slots:"mm",
            span:12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "innerWidth",
            "type": "number",
            "title": "内宽度",
            slots:"mm",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "innerHeight",
            "type": "number",
            "title": "内高度",
            slots:"mm",
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "volume",
            "type": "number",
            "title": "外体积",
            slots:"m",
            sup:3,
            span:12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "innerVolume",
            "type": "number",
            "title": "内体积",
            required: false,
            slots:"m",
            sup:3,
            span:12,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "netWeight",
            "type": "number",
            "title": "净重",
            span:12,
            slots:"kg",
            required:false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "maxLoad",
            "type": "number",
            "title": "最大载重",
            span:12,
            slots:"kg",
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        }
    ],
    table: {
        columns: [
            {title: '周转筐名称', field: 'containerName', width: '130'},
            {title: '周转筐编码', field: 'containerCode', width: '130'},
            {title: '外长度', field: 'length', width: '130'},
            {title: '外宽度', field: 'width', width: '130'},
            {title: '外高度', field: 'height', width: '130'},
            {title: '内长度', field: 'innerLength', width: '130'},
            {title: '内宽度', field: 'innerWidth', width: '130'},
            {title: '内高度', field: 'innerHeight', width: '130'},
            {title: '外体积', field: 'volume', width: '130',formatter: formatter.VolumeFormatter},
            {title: '内体积', field: 'innerVolume', width: '130',formatter: formatter.VolumeFormatter},
            {title: '净重', field: 'netWeight', width: '130'},
            {title: '最大载重', field: 'maxLoad', width: '130'},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
