<template>
    <baidu-map class="row-col-8" :center="center" :zoom="zoom"
               style="height:500px"
               :scroll-wheel-zoom='true'>
    </baidu-map>
</template>

<script>
    export default {
        data() {
            return {
                center: {lng: 109.45744048529967, lat: 36.49771311230842},
                zoom: 15
            }
        }
    }
</script>